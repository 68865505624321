import Card from "../../components/layout/Card";
import CardTitle from "../../components/layout/CardTitle";

export function SetupAlreadyFinalizedPage(props: SetupAlreadyFinalizedPageProps) {
  return (
    <div className="w-full max-w-[650px] m-auto">
      <Card className="w-full 2xl:p-12">
        <CardTitle className="text-2xl 2xl:text-3xl">OPS!</CardTitle>
        <span className="text-primary font-bold text-lg">
            Parece que você já finalizou o processo de configuração de sua conta.
        </span>
        <p className="text-gray-dark mt-4">
            Caso isso seja um erro, por favor entre em contato com o suporte técnico.
        </p>
      </Card>
    </div>
  );
}

export type SetupAlreadyFinalizedPageProps = {
};
