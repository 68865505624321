import React from "react";
import Sidebar from "./Sidebar";

function SidebarLayout(props: SidebarLayoutProps) {
  return (
    <main className="relative min-h-screen flex">
      <Sidebar onPageChange={props.onPageChange} />
      <div className="flex-1 p-7 pr-20 bg-white-dark 2xl:p-10 2xl:pr-24">
        {props.children}
      </div>
    </main>
  );
}

export default SidebarLayout;

export type SidebarLayoutProps = {
  children: React.ReactNode;
  onPageChange?: (destination: string) => boolean;
};
