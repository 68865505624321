export const TooltipTitle = ({ children, className = "" }) => {
    return (
        <h2
            className={`text-lg font-bold pb-1 mb-3 border-b-gray-dark/20 border-b-2 ${className}`}
        >
            {children}
        </h2>
    );
};

export const TooltipParagraph = ({ children, className = "" }) => {
    return <p className={`mb-4 ${className}`}>{children}</p>;
};

export const TooltipLink = ({ children, href, className = "" }) => {
    return (
        <a
            href={href}
            target="_blank"
            className={`text-green-bright underline italic ${className}`}
        >
            {children}
        </a>
    );
};
