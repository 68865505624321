import Spinner from "./Spinner";

function PageLoadSpinner(props: PageLoadSpinnerProps) {
  return (
    <>
      {props.show && (
        <div
          className={`grid place-items-center h-[600px] z-50 ${props.className}`}
        >
          <div>
            <Spinner />
          </div>
        </div>
      )}
    </>
  );
}

export default PageLoadSpinner;
export type PageLoadSpinnerProps = {
  show: boolean;
  className?: string;
};
