import { forwardRef, useEffect, useState } from "react";

const SoftphoneDockContainer = forwardRef(({ shown, children }, ref) => {
    const [style, setStyle] = useState("");
    const [className, setClassName] = useState({});

    useEffect(() => {
        if (shown) {
            setClassName("");
            setTimeout(() => {
                setClassName("animate-softphoneDockIn");
                setStyle({ opacity: "1", visibility: "visible" });
            }, 10);
        } else {
            setClassName("");
            setTimeout(() => {
                setClassName("animate-softphoneDockOut");
                setStyle({ opacity: "0", visibility: "hidden" });
            }, 10);
        }
    }, [shown]);

    return (
        <div className={`absolute ${className}`} style={{ ...style }} ref={ref}>
            {children}
        </div>
    );
});

export default SoftphoneDockContainer;
