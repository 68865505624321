import { forwardRef } from "react";
import InputGroup from "./InputGroup";
import styles from "./Switch.module.css";

const Switch = forwardRef((props: SwitchProps, ref) => {
  const labelStyles = [styles.switch];
  if (props.disabled) {
    labelStyles.push(styles.disabled);
  }
  return (
    <InputGroup title={props.title || ""} className={props.className || ""}>
      <fieldset className={`inline-flex items-center `}>
        <label className={labelStyles.join(" ")}>
          <input
            type="checkbox"
            onChange={() =>
              props.onChange ? props.onChange(!props.value) : null
            }
            checked={props.value}
            disabled={props.disabled}
          />
          <div className={`bg-gray ${styles.slider}`}></div>
        </label>
        <span
          className={`ml-2 font-bold text-xs 2xl:text-sm ${
            props.disabled ? "text-gray" : "text-primary"
          }`}
        >
          {props.value ? props.titleActive : props.titleInactive}
        </span>
      </fieldset>
    </InputGroup>
  );
});

export default Switch;
export type SwitchProps = {
  titleActive: string;
  titleInactive: string;
  value: boolean;
  title?: string;
  onChange?: (val: boolean) => void;
  className?: string;
  disabled?: boolean;
};
