export const numericMask = () => {
    return {
        filtering: (val) => {
            return val.toString().replace(/\D/g, "").substring(0, 4);
        },
        masking: (val) => {
            return val;
        },
    };
};

export const cpfCnpjMask = () => {
    return {
        filtering: (val) => {
            let v = val.replace(/\D/g, "");
            return v.substring(0, 14);
        },
        masking: (val) => {
            let v = val;
            if (v.length <= 11) {
                v = v.replace(/(\d{3})(\d)/, "$1.$2");
                v = v.replace(/(\d{3})(\d)/, "$1.$2");
                v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
            } else if (v.length < 15) {
                v = v.replace(/^(\d{2})(\d)/, "$1.$2");
                v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
                v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
                v = v.replace(/(\d{4})(\d)/, "$1-$2");
            }
            return v;
        },
    };
};

export const cepMask = () => {
    return {
        filtering: (val) => {
            let v = val.replace(/\D/g, "");
            return v.substring(0, 8);
        },
        masking: (val) => {
            let v = val;
            if (v.length > 5) {
                v = v.replace(/^(\d{5})(\d{0,3}).*/, "$1-$2");
            }
            return v;
        },
    };
};

export const dateMask = () => {
    return {
        filtering: (val, curr) => {
            if (val === "") return val;
            let v = val.replace(/\D/g, "");
            let correct = false;

            if (v.length === 1) {
                correct = /^[0-3]{1}/.test(v);
            } else if (v.length === 2) {
                correct = /^([0-2][0-9]|3[0-1])/.test(v);
            } else if (v.length === 3) {
                correct = /^([0-2][0-9]|3[0-1])(0|1)/.test(v);
            } else if (v.length === 4) {
                correct = /^([0-2][0-9]|3[0-1])(0[0-9]|1[0-2])/.test(v);
            } else if (v.length >= 5) {
                correct = /^([0-2][0-9]|3[0-1])(0[0-9]|1[0-2])[0-9]{0,4}$/.test(
                    v
                );
            }

            return correct
                ? v.substring(0, 8)
                : curr.replace(/\D/g, "").substring(0, 8);
        },
        masking: (val) => {
            let v = val;

            if (v.length < 5) {
                v = v.replace(/^(\d{2})(\d)/, "$1/$2");
            } else {
                v = v.replace(/^(\d{2})(\d{2})(\d{0,4})$/, "$1/$2/$3");
            }

            return v;
        },
    };
};

export const phoneMask = () => {
    return {
        filtering: (val) => {
            let v = val.toString().replace(/\D/g, "");
            return v.substring(0, 14);
        },
        masking: (val) => {
            let r = val.toString().replace(/^0/, "");
            if (r.length > 10) {
                r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
            } else if (r.length > 9) {
                r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
            } else if (r.length > 8) {
                r = r.replace(/^(\d{5})(\d{0,4}).*/, "$1-$2");
            } else if (r.length > 4) {
                r = r.replace(/^(\d{4})(\d{0,4}).*/, "$1-$2");
            }
            return r;
        },
    };
};

export const hourMask = () => {
    return {
        filtering: (val) => {
            let v = val.toString().replace(/\D/g, "");
            if (/^(2[0-3]?|[01]?[0-9]?)([0-5]?[0-9])?$/g.test(v)) return v;
            else return v.substring(0, v.length - 1);
        },
        masking: (val) => {
            let v = val.toString();
            if (v.length < 3) {
                v = v.replace(/^(\d{2})(\d)/, "$1:$2");
            } else {
                v = v.replace(/^(\d{2})(\d{2})$/, "$1:$2");
            }
            return v;
        },
    };
};
