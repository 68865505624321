import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { generateQrCode } from "../../services/user";
import Modal from "../layout/Modal";
import SoftphoneSpinner from "../softphone/SoftphoneSpinner";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../layout/Button";
import { useAuth } from "../../services/firebase/auth";

function QRCodeModal({ shown, selectedExtension, onClose }) {
    const [qrCode, setQrCode] = useState("");
    const [error, setError] = useState(false);

    const init = async () => {
        setError(false);
        const result = await generateQrCode(selectedExtension.id);
        if (result) {
            setQrCode(result);
        } else {
            setError(true);
        }
    };

    const clear = () => {
        setError(false);
        setQrCode("");
    };

    const onCloseHandler = () => {
        clear();
        onClose && onClose();
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <Modal
            shown={shown}
            onClose={onCloseHandler}
            title="Conectar Dispositivo"
            className="min-w-[300px] 2xl:min-w-[400px] max-w-[400px]"
        >
            <p className="text-sm">
                Para conectar um dispositivo móvel, baixe o aplicativo Voki no
                link abaixo e escaneie o QRCode.
            </p>
            {qrCode && !error && (
                <div className="flex justify-center my-10">
                    <QRCode value={qrCode} size={250} />
                </div>
            )}
            {!qrCode && !error && (
                <div className="flex justify-center my-10">
                    <SoftphoneSpinner size={100} />
                </div>
            )}
            {error && (
                <div className="my-10">
                    <div className="flex justify-center mb-4">
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            className="text-gray-dark text-5xl"
                        />
                    </div>
                    <div className="text-sm text-gray-dark font-bold text-center">
                        Ocorreu um erro carregando o QRCode
                    </div>
                </div>
            )}
            <div className="flex justify-center mb-6">
                <a
                    href="http://play.google.com/store/?pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target={"_blank"}
                >
                    <img
                        alt="Disponível no Google Play"
                        src="https://play.google.com/intl/en_us/badges/static/images/badges/pt_badge_web_generic.png"
                        className="w-36"
                    />
                </a>
            </div>
            <div className="flex justify-end">
                <Button color="purple" onClick={onCloseHandler}>
                    fechar
                </Button>
            </div>
        </Modal>
    );
}

export default QRCodeModal;
