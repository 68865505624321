import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "../../tooltip/Tooltip";
import { TooltipId } from "../../lang/tooltips";

function PageTitle(props: PageTitleProps) {
  return (
    <div className="mb-4">
      <h1 className="text-primary font-bold mb-2 text-lg 2xl:text-2xl 2xl:mb-2 grid grid-cols-2">
        {props.children}
      </h1>
      {props.tooltipMessageId && (
        <Tooltip messageId={props.tooltipMessageId}>
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className="text-gray-dark text-md ml-2"
          />
        </Tooltip>
      )}
      <hr className="border-t-gray border-t-2"></hr>
    </div>
  );
}

export default PageTitle;

export type PageTitleProps = {
  children: React.ReactNode;
  tooltipMessageId?: TooltipId;
};
