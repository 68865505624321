import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../services/firebase/auth";

function useLocalPagination(getFunc, numLines) {
    const auth = useAuth();

    // const rawData = useMemo(async () => await getFunc(auth), []);
    const [rawData, setRawData] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [paginatedData, setPaginatedData] = useState([]);

    const [hasLoaded, setHasLoaded] = useState(false);

    const [page, setPage] = useState(1);
    const [numPages, setNumPages] = useState(0);

    const search = async (filters = {}) => {
        let data = rawData;
        if (!data) {
            data = await getFunc(auth);
            setRawData(data);
        }

        let filtered = data;

        for (const filter in filters) {
            const val = filters[filter];
            if (val) {
                filtered = filtered.filter(
                    (v) =>
                        v[filter]
                            .toString()
                            .toLowerCase()
                            .indexOf(val.toString().toLowerCase()) > -1
                );
            }
        }

        setFilteredData(filtered);
        setPaginatedData(filtered.slice(0, numLines));
        setPage(1);
        setNumPages(Math.ceil(filtered.length / numLines));
    };

    const goToPage = (page) => {
        const lowerBound = (page - 1) * numLines;
        const upperBound = page * numLines;
        const newPageData = filteredData.slice(lowerBound, upperBound);
        setPaginatedData(newPageData);
        setPage(page);
    };

    const load = async () => {
        const raw = await getFunc(auth);
        setRawData(raw);
    };

    const reload = async () => {
        await load();
    };

    useEffect(() => {
        search({});
    }, [rawData]);

    useEffect(() => {
        setHasLoaded(true);
    }, [rawData]);

    useEffect(() => {
        load();
    }, []);

    return {
        search,
        goToPage,
        reload,
        data: paginatedData,
        page,
        numPages,
        hasLoaded,
    };
}

export default useLocalPagination;
