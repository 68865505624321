import { useEffect, useState, useCallback } from "react";
import Modal from "../layout/Modal";
import TextInput from "../layout/TextInput";
import IntegerInput from "../layout/IntegerInput";
import Button from "../layout/Button";
import useFormValidation from "../../hooks/formValidation";
import MultiSelect from "../layout/MultiSelect";
import { useAuth } from "../../services/firebase/auth";
import { getExtensions } from "../../model/queue.model";
import Select from "../layout/Select";

function QueueModal({
  queueId,
  data,
  onSave,
  onLoad,
  shown,
  onDelete,
  queues,
  ...props
}) {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [option, setOption] = useState("");
  const [extensions, setExtensions] = useState([]);
  const [strategy, setStrategy] = useState();
  const [participants, setParticipants] = useState([]);

  const formValidation = useFormValidation();
  const auth = useAuth();

  const saveHandler = async () => {
    if (formValidation.validateForm()) {
      const queue = {
        extensions,
        option,
        name,
        strategy,
        participants,
      };

      clear();
      onSave && onSave(queue);
    }
  };

  const onCloseHandler = () => {
    clear();
    props.onClose && props.onClose();
  };

  const clear = () => {
    setName("");
    setOption("");
    setParticipants([]);
  };

  const load = useCallback(async () => {
    const extensions = await getExtensions(auth);
    setExtensions(extensions);
    if (data) {
      setName(data.name);
      setOption(data.option);
      setStrategy(data.strategy);
      setParticipants(
        data.participants.map((v) => ({
          value: v.id,
          label: `${v.name} | ${v.extensionNumber}`,
        }))
      );
    }
    if (queueId) {
      setId(queueId);
    }
  }, [auth, data, queueId]);

  const optionValidation = (v) => {
    let optionAlreadyUsed;

    if(queueId) {
      optionAlreadyUsed = queues.find((q) => q.option.toString() === v && q.id !== queueId);
    } else {
      optionAlreadyUsed = queues.find((q) => q.option.toString() === v)
    }

    if (optionAlreadyUsed) return { error: true, message: "Já utilizado" };
    return { error: false };
  };

  useEffect(() => {
    load();
  }, [shown, load]);

  return (
    <Modal
      title="Criar Fila"
      className="w-[500px]"
      tooltipMessageId="ivr-queues"
      shown={shown}
      onClose={onCloseHandler}
    >
      <div className="grid grid-cols-1 gap-4 mb-6">
        <IntegerInput
          title="Opção digitável"
          value={option}
          setter={setOption}
          formValidation={formValidation}
          validation={optionValidation}
          required
        />
        <TextInput
          title="Nome da fila"
          value={name}
          setter={setName}
          formValidation={formValidation}
          required
        />
        <Select
          title="Estratégia de fila"
          value={strategy}
          setter={setStrategy}
          formValidation={formValidation}
          tooltipMessageId="queue-strategy"
          required
        >
          <option value="rrmemory">Rotacionado</option>
          <option value="linear">Linear</option>
        </Select>
        <MultiSelect
          options={extensions}
          title="Participantes"
          formValidation={formValidation}
          value={participants}
          setter={setParticipants}
          required
        />
        {props.children}
      </div>
      <div className="">
        {onDelete && (
          <Button
            color="red"
            className="float-left"
            onClick={() => onDelete(id)}
            outline
          >
            Deletar
          </Button>
        )}
        <Button color="purple" className="float-right" onClick={saveHandler}>
          Salvar
        </Button>
      </div>
    </Modal>
  );
}

export default QueueModal;
