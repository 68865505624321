import { formatPhoneOrExt } from "../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import { useState } from "react";

function NumberRantingTable({ numbersArray, selectNumber }) {
    const [selected, setSelected] = useState("");

    const selectedNumber = (id) => {
        setSelected(id);
        selectNumber && selectNumber(id);
    };

    return (
        <div className="w-full mt-4">
            <div className="p-4 bg-gray-bluish rounded-t-15 text-sm">
                <span className="text-primary font-bold">Número</span>
            </div>
            <div className="h-[450px] overflow-auto border border-white-dark rounded-b-15">
                {numbersArray &&
                    numbersArray.map((number) => (
                        <div
                            className="p-4  border-b border-white-dark hover:bg-white-mid flex"
                            key={number.id}
                            onClick={() => selectedNumber(number.id)}
                        >
                            <div className="text-primary text-sm font-bold flex-1">
                                {formatPhoneOrExt(
                                    number.areaCode + number.number
                                )}
                            </div>
                            <div>
                                {selected === number.id && (
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        className="text-green"
                                        size="lg"
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                {_.isEmpty(numbersArray) && (
                    <div className="h-full flex justify-center items-center">
                        <div className="text-gray-dark font-bold ">
                            Nenhum número disponível para essa cidade.
                            <br />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default NumberRantingTable;
