import { uniqueId } from "lodash";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import InputGroup from "./InputGroup";

const Select = ({
  setter,
  value,
  className,
  title = "",
  placeholder = "",
  onChange = null,
  disabled = false,
  formValidation = null,
  validation = null,
  tooltipMessageId = "",
  required = false,
  emptyOption = true,
  error = "",
  children,
  ...props
}) => {
  const [id] = useState(uniqueId("select-"));
  const [rawVal, setRawVal] = useState("");

  const [errorStatus, setErrorStatus] = useState("");

  const ref = useRef();

  const onChangeHandler = (e) => {
    setRawVal(e.target.value);
    errorStatus && setErrorStatus("");
    validate() && setter(e.target.value);
    onChange && onChange();
  };

  const validate = useCallback(() => {
    const val = ref.current.value;
    if (required && !val) {
      setErrorStatus("Obrigatório");
      return false;
    }

    if (validation) {
      const validResponse = validation(val);
      if (validResponse.error) {
        setErrorStatus(validResponse.message || "");
        return false;
      } else {
        setErrorStatus("");
        return true;
      }
    }
    return true;
  }, [required, validation]);

  useEffect(() => {
    setRawVal(value);
  }, [value]);

  useEffect(() => {
    if (formValidation) {
      formValidation.registerValidationCallback({ id, validate });
      return () => formValidation.unregisterValidationCallback(id);
    }
  }, [id, validate, formValidation]);

  useEffect(() => {
    setErrorStatus(error);
  }, [error]);

  const borderColor = errorStatus ? "border-red/60" : "border-gray/50";
  const focusBorderColor = errorStatus
    ? "focus:border-red/80"
    : "focus:border-gray";

  return (
    <InputGroup
      title={title}
      inputId={id}
      className={className}
      tooltipMessageId={tooltipMessageId}
      error={errorStatus}
    >
      <select
        {...props}
        id={id}
        className={`block bg-white px-3 2xl:px-4 py-1.5 2xl:py-2 text-xs 2xl:text-sm w-full border-2 rounded-10 outline-none min-w-8  disabled:bg-gray/50 ${borderColor} ${focusBorderColor}`}
        onChange={onChangeHandler}
        value={rawVal}
        disabled={disabled}
        ref={ref}
      >
        {(emptyOption || placeholder) && (
          <option value="">{placeholder || ""}</option>
        )}
        {children}
      </select>
    </InputGroup>
  );
};

export default Select;
