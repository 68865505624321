import { useState } from "react";
import useFormValidation from "../../hooks/formValidation";
import Modal from "../layout/Modal";
import CreatePasswordInput from "../layout/CreatePasswordInput";
import Button from "../layout/Button";
import { changeExtensionPassword } from "../../services/extensions";
import { useAuth } from "../../services/firebase/auth";

function ResetPasswordModal({
    shown,
    extension,
    onClose,
    onSave,
    setIsLoading,
}) {
    const [password, setPassword] = useState("");
    const formValidation = useFormValidation();

    const auth = useAuth();

    const saveHandler = async () => {
        setIsLoading && setIsLoading(true);
        if (formValidation.validateForm()) {
            onSave && onSave();
            const userData = {
                id: extension.id,
                password,
            };

            clear();

            await changeExtensionPassword(auth, userData);
            setIsLoading && setIsLoading(false);
        }
    };

    const onCloseHandler = () => {
        clear();
        onClose && onClose();
    };

    const clear = () => {
        setPassword("");
    };

    return (
        <Modal
            shown={shown}
            onClose={onCloseHandler}
            title="Redefinição de Senha"
            className="min-w-[300px] 2xl:min-w-[400px]"
        >
            <div className="grid grid-cols-1 gap-4 mb-6">
                <CreatePasswordInput
                    title="Nova Senha"
                    value={password}
                    setter={setPassword}
                    formValidation={formValidation}
                    required
                />
            </div>
            <Button
                color="purple"
                onClick={saveHandler}
                className="float-right"
            >
                Salvar
            </Button>
        </Modal>
    );
}

export default ResetPasswordModal;
