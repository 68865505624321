import { getAccountData } from "../model/account.model";
import { getAdminExtension } from "../model/extension.model";
import { SetupWizardAccountMetadata, SetupWizardConfig } from "../model/setupWizard.model";

export async function getWizardInitConfig(companyId: string): Promise<SetupWizardConfig> {
    const admin = await getAdminExtension(companyId);
    const acc = await getAccountData(companyId);

    return {
        account: {
            name: acc.name,
            // usersLimit: acc.usersLimit || 0,
            // recordingLimit: acc.recordingLimit || 0,
            // finalizedSetupWizard: acc.finalizedSetupWizard || false
        },
        extensions: [
            {...admin, tempId: "", id: admin.id || null}
        ],
    }
}

export async function getWizardAccountMetadata(companyId: string): Promise<SetupWizardAccountMetadata> {
    const acc = await getAccountData(companyId);
    return {
        finalizedSetupWizard: acc.finalizedSetupWizard || false,
        recordingLimit: acc.recordingLimit || 0,
        usersLimit: acc.usersLimit || 0

    }
}