import { functions } from "./firebase/firebaseInit";
import { httpsCallable } from "@firebase/functions";
import { firestore } from "./firebase/firebaseInit";
import { collection, getDocs } from "@firebase/firestore";
import {
  saveTextTTS,
  savePredefTTS,
  uploadMessage,
  IvrAudioMessage,
  AudioMessage,
} from "../model/queue.model";

export const getWorkingHours = async (auth: any) => {
  const companyId = auth.user.token.claims.company;
  const collSnap = await getDocs(
    collection(firestore, `companies/${companyId}/working-hours`)
  );

  const data = collSnap.docs.reduce((prev, curr) => {
    //@ts-ignore
    return { ...prev, [curr.id]: curr.data() };
  }, null);

  return data;
};

export const setWorkingHours = async (
  auth: any,
  workingHours: any,
  conf: any
) => {
  const data = {
    companyId: auth.user.token.claims.company,
    days: workingHours,
    playMessage: conf.playMessage,
    redirectNumber: conf.redirectNumber,
    lunchBreak: conf.lunchBreak,
  };

  const updateWorkingHours = httpsCallable(functions, "updateWorkingHours");
  return await updateWorkingHours(data);
};

export async function saveAwayAudio(
  companyId: string,
  audio: AudioMessage,
  reusePreview: boolean
) {
  if (!audio) return;

  switch (audio.type) {
    case "text":
      await saveTextTTS(audio, reusePreview);
      break;
    case "predefined":
      await savePredefTTS(audio, reusePreview);
      break;
    case "file":
      if (audio.hasChanged) {
        await uploadMessage(audio, companyId);
      }
      break;
  }
}
