import { twMerge } from "tailwind-merge";

function SoftphoneBottomDock({ className, children }) {
    const classes = twMerge(
        "flex h-full items-center justify-between rounded-bl-20 px-12 2xl:px-14",
        className
    );
    return <div className={classes}>{children}</div>;
}
export default SoftphoneBottomDock;
