import { functions } from "./firebase/firebaseInit";
import { httpsCallable } from "@firebase/functions";
import { firestore } from "./firebase/firebaseInit";
import { doc, getDoc } from "@firebase/firestore";
import { auth } from "./firebase/firebaseInit";

export const updateCurrentUser = async (data) => {
  const updateUser = httpsCallable(functions, "updateUser");
  const result = await updateUser(data);
  return result.data;
};

export const getUserData = async () => {
  const userId = auth.currentUser.uid;
  const docRef = doc(firestore, `users/${userId}`);
  const docSnap = await getDoc(docRef);
  return { id: docSnap.id, ...docSnap.data() };
};

export const generateQrCode = async (userId) => {
  const generateQRCode = httpsCallable(functions, "generateQRCode");
  const result = await generateQRCode({ uid: userId });
  return result.data || false;
};

export const activateSupportLogin = async () => {
  const fn = httpsCallable(functions, "activateSupportLogin");
  const result = await fn();
  return result.data || false;
};

export const deactivateSupportLogin = async () => {
  const fn = httpsCallable(functions, "deactivateSupportLogin");
  const result = await fn();
  return result.data || false;
};

export const getTokenForSupportLogin = async (
  supportToken,
  userId,
  companyId
) => {
  const fn = httpsCallable(functions, "supportLogin");
  const result = await fn({ token: supportToken, userId, companyId });
  return result.data || false;
};
