import Card from "../layout/Card";

function IvrCard({ footer, onClick, className, children }) {
    return (
        <Card
            onClick={onClick}
            className={`min-h-[300px] 2xl:min-h-[330px] h-full w-full ${className}`}
        >
            <div className="h-full flex justify-center align-middle flex-col">
                <div className="h-full flex justify-center align-middle flex-col">
                    {children}
                </div>
                <div>{footer}</div>
            </div>
        </Card>
    );
}

export default IvrCard;

//            <div className="h-full flex justify-center align-middle flex-col">
