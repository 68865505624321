import light from "../../images/logotipo.png";
import dark from "../../images/logotipo-dark.png";

function Logo({ className, type }) {
    return (
        <div className={`${className}`}>
            <img
                src={type === "dark" ? dark : light}
                className="m-auto w-[120px] 2xl:w-[170px]"
                alt="Voki"
            />
        </div>
    );
}

export default Logo;
