import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

function Avatar({ avatar, className }) {
    return (
        <div className={` rounded-full p-2 ${className}`}>
            <div className="w-full h-full grid items-center justify-items-center">
                <FontAwesomeIcon icon={faUser} className="text-gray-dark h-7" />
            </div>
            {/* <img src={avatar} alt="avatar" /> */}
        </div>
    );
}

export default Avatar;
