import React, { useRef, useState, useEffect } from "react";
import PlayButton from "../layout/PlayButton";
import { useAuth } from "../../services/firebase/auth";
import { AudioMessage, getAudio } from "../../model/queue.model";

export default function UploadedPreview(props: UploadedPreviewProps) {
  const [playState, setPlayState] = useState("inactive");
  const [audioUrl, setAudioUrl] = useState("");

  const ref = useRef<HTMLAudioElement>(null);

  const auth = useAuth();

  const onPlayHandler = async () => {
    if (audioUrl) {
      play();
    } else {
      const result = await load();
      if (result) {
        play();
      }
    }
  };

  const onStopHandler = () => {
    setPlayState("inactive");
    ref.current!.pause();
    ref.current!.currentTime = 0;
  };

  const play = () => {
    setPlayState("playing");
    setTimeout(() => ref.current!.play(), 500);
    ref.current!.addEventListener("ended", () => {
      setPlayState("inactive");
    });
  };

  const load = async () => {
    setPlayState("loading");
    const result = await getAudio(auth, props.message?.id);
    setPlayState("inactive");
    if (result) {
      setAudioUrl(result);
      ref.current!.src = result;
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (props.file) {
      const fileUrl = URL.createObjectURL(props.file);
      setAudioUrl(fileUrl);
    }
  }, [props.file]);

  return (
    <div className={props.className}>
      <PlayButton
        playState={playState}
        onPlay={onPlayHandler}
        onStop={onStopHandler}
      />
      <audio ref={ref} src={audioUrl} />
    </div>
  );
}

export type UploadedPreviewProps = {
  message: AudioMessage;
  file?: File;
  className?: string;
};
