import { functions } from "./firebase/firebaseInit";
import { httpsCallable } from "@firebase/functions";

export const getBalance = async (sbc0800Id, sbcIdentifierId) => {
    try {
        const getBalanceData = httpsCallable(functions, "getBalance");
        const result = await getBalanceData({ sbc0800Id: sbc0800Id, sbcIdentifierId: sbcIdentifierId });

        return JSON.parse(result.data) || [];
    } catch (error) {
        console.log('Error on getBalance:', error);
    }
};
