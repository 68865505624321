import React, { useEffect, useRef, useState } from "react";
import PlayButton from "../layout/PlayButton";
import {
  AudioMessage,
  PredefinedAudioMessage,
  TextAudioMessage,
  generateTempTTS,
} from "../../model/queue.model";

export function BaseTTSPreview(props: BaseTTSPreviewProps) {
  const [playState, setPlayState] = useState("inactive");
  const [audioUrl, setAudioUrl] = useState("");

  const ref = useRef<HTMLAudioElement>(null);

  const onPlayHandler = async () => {
    if (props.validateNeedOfRegen && props.validateNeedOfRegen(audioUrl)) {
      const result = await load();
      if (result) {
        await play();
      }
    } else {
      play();
    }
  };

  const onStopHandler = () => {
    setPlayState("inactive");
    ref.current!.pause();
    ref.current!.currentTime = 0;
  };

  const play = async () => {
    setPlayState("playing");
    if (ref.current!.paused) {
      //> Chrome has a bug on audio playback that throws:
      //> "The play() request was interrupted by a call to pause()"
      //> A timeout seems to solve the issue somehow.
      //> Seems to be a race condition between play() and pause()?
      setTimeout(async () => {
        await ref.current!.play();
      }, 300);
    }
    ref.current!.addEventListener("ended", () => {
      setPlayState("inactive");
    });
  };

  const load = async () => {
    setPlayState("loading");
    const result = props.ttsGenerationFunc
      ? await props.ttsGenerationFunc(props.message)
      : await generateTempTTS(props.message);
    setPlayState("inactive");
    if (result) {
      setAudioUrl(result);

      ref.current!.src = result;
      ref.current!.pause();
      props.onGenerated && props.onGenerated(result);
      return true;
    }
    return false;
  };

  useEffect(() => {
    const audioUrl = props.message?.text || "";
    setAudioUrl(audioUrl);
  }, [props.message?.text]);

  return (
    <>
      <PlayButton
        playState={playState}
        onPlay={onPlayHandler}
        onStop={onStopHandler}
        className={props.className}
      />
      <audio ref={ref} src={audioUrl} />
    </>
  );
}

export type BaseTTSPreviewProps = {
  message: TextAudioMessage | PredefinedAudioMessage;
  validateNeedOfRegen?: (audioUrl: string) => boolean;
  ttsGenerationFunc?: (message: AudioMessage) => Promise<string>;
  onGenerated?: (audioUrl: string) => void;
  companyName?: string;
  className?: string;
};
