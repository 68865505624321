import React, { useEffect, useRef, useState } from "react";
import PlayButton from "../layout/PlayButton";
import {
  PredefinedAudioMessage,
  TextAudioMessage,
  generateTempTTS,
} from "../../model/queue.model";
import { BaseTTSPreview, BaseTTSPreviewProps } from "./BaseTTSPreview";

export function TextTTSPreview(props: BaseTTSPreviewProps) {

  return (
    <BaseTTSPreview {...props} validateNeedOfRegen={(url) => url !== "" && url !== undefined} ttsGenerationFunc={generateTempTTS}/>
  );
}