import { twMerge } from "tailwind-merge";

function CardGrid(props: CardGrid) {
  const defaultClasses =
    "grid gap-4 grid-cols-extensions-sm 2xl:grid-cols-extensions";
  const classes = twMerge(defaultClasses, props.className);

  return <div className={classes}>{props.children}</div>;
}

export default CardGrid;
export type CardGrid = {
  children: React.ReactNode;
  className?: string;
};
