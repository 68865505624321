import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageTitle from "../components/layout/PageTitle";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import ContentWrapper from "../components/layout/ContentWrapper";
import Card from "../components/layout/Card";
import PhoneOrExtInput from "../components/layout/PhoneOrExtInput";
import Button from "../components/layout/Button";
import { useEffect, useState } from "react";
import NumbersTable from "../components/numbers/NumbersTable";
import useTablePagination from "../hooks/tablePagination";
import { getCompanyNumbers } from "../services/numbers";
import NumberRentingModal from "../components/numbers/NumberRentingModal";

function NumbersPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [phoneSearch, setPhoneSearch] = useState("");

    const [showRentNumber, setShowRentNumber] = useState(false);

    const table = useTablePagination(getCompanyNumbers, 15);

    const searchHandler = async () => {
        setIsLoading(true);
        await table.search({ fullPhone: phoneSearch });
        setIsLoading(false);
    };

    const init = async () => {
        setIsLoading(true);
        await table.search();
        setIsLoading(false);
    };

    const loadMoreHandler = async () => {
        table.loadMore();
    };

    // const reload = () => {
    //     setIsLoading(true);
    //     init();
    // };

    useEffect(() => {
        init();
    }, []);

    return (
        <SidebarLayout>
            <PageTitle>Meus Números</PageTitle>
            <PageLoadSpinner show={isLoading} />
            <ContentWrapper show={!isLoading}>
                <Card className="w-full">
                    <div className="flex items-end">
                        <PhoneOrExtInput
                            title="Telefone"
                            value={phoneSearch}
                            setter={setPhoneSearch}
                            className="flex-1"
                        />
                    </div>
                    <div className="mt-4">
                        <Button
                            className="mr-2"
                            outline
                            onClick={() => setShowRentNumber(true)}
                        >
                            Contratar número
                        </Button>
                        <Button color="yellow" outline>
                            Realizar portabilidade
                        </Button>
                        <Button className="float-right" onClick={searchHandler}>
                            Pesquisar
                        </Button>
                    </div>
                </Card>
                <NumbersTable data={table.data} />
                <div className="mt-4 flex justify-center">
                    {table.hasMore && (
                        <Button onClick={loadMoreHandler}>Carregar mais</Button>
                    )}
                </div>
            </ContentWrapper>
            <NumberRentingModal
                shown={showRentNumber}
                onClose={() => setShowRentNumber(false)}
            />
        </SidebarLayout>
    );
}

export default NumbersPage;
