import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import ContentWrapper from "../components/layout/ContentWrapper";
import PageTitle from "../components/layout/PageTitle";
import Card from "../components/layout/Card";
import CardTitle from "../components/layout/CardTitle";
import PageActions from "../components/layout/PageActions";
import Button from "../components/layout/Button";
import { useEffect, useState } from "react";
import Select from "../components/layout/Select";
import { useSoftphone } from "../services/softphone/softphoneContext";

function DevicesPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [inputDevices, setInputDevices] = useState([]);
  const [outputDevices, setOutputDevices] = useState([]);

  const [selectedInputId, setSelectedInputId] = useState();
  const [selectedRingingOutputId, setSelectedRingingOutputId] = useState();
  const [selectedOutputId, setSelectedOutputId] = useState();

  const softphone = useSoftphone();

  const onSaveHandler = () => {
    setIsLoading(true);
    softphone.changeDevices(
      selectedInputId,
      selectedRingingOutputId,
      selectedOutputId
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  };

  const loadDevices = async () => {
    setIsLoading(true);
    const inputs = (await navigator.mediaDevices.enumerateDevices()).filter(
      (v) => v.kind === "audioinput"
    );

    const outputs = (await navigator.mediaDevices.enumerateDevices()).filter(
      (v) => v.kind === "audiooutput"
    );

    setInputDevices(inputs);
    setOutputDevices(outputs);

    const active = softphone.getSelectedDevices();
    setSelectedInputId(active.input || "");
    setSelectedOutputId(active.output || "");
    setSelectedRingingOutputId(active.ringing || "");
    setIsLoading(false);
  };

  useEffect(() => {
    loadDevices();
  }, []);

  return (
    <SidebarLayout>
      <PageLoadSpinner show={isLoading} />
      <ContentWrapper show={!isLoading}>
        <PageTitle>Dispositivos</PageTitle>
        {/* <div className="mb-4 grid gap-4 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-1">
                    <ActionCard
                        icon={faMobileAlt}
                        className="text-primary"
                        onClick={addDeviceHandler}
                    >
                        Adicionar
                        <br />
                        Dispositivo Móvel
                    </ActionCard>
                    <ActionCard
                        icon={faHeadphones}
                        className="text-primary"
                        onClick={addIPPhoneHandler}
                    >
                        Adicionar
                        <br />
                        Telefone IP
                    </ActionCard>
                </div> */}
        <Card className="w-full">
          <CardTitle>Configurações de Som</CardTitle>
          <div className="mb-4">
            <Select
              title="Microfone"
              value={selectedInputId}
              setter={setSelectedInputId}
            >
              {inputDevices.length > 0 &&
                inputDevices.map((o) => (
                  <option key={o.deviceId} value={o.deviceId}>
                    {o.label}
                  </option>
                ))}
            </Select>
          </div>
          <div className="mb-4">
            <Select
              title="Saída de toque de telefone"
              value={selectedRingingOutputId}
              setter={setSelectedRingingOutputId}
            >
              {outputDevices.map((o) => (
                <option key={o.deviceId} value={o.deviceId}>
                  {o.label}
                </option>
              ))}
            </Select>
          </div>
          {outputDevices.length > 0 && (
            <div className="mb-4">
              <Select
                title="Saída de áudio"
                value={selectedOutputId}
                setter={setSelectedOutputId}
              >
                {outputDevices.map((o) => (
                  <option key={o.deviceId} value={o.deviceId}>
                    {o.label}
                  </option>
                ))}
              </Select>
            </div>
          )}
          <CardTitle>Extensão Voki</CardTitle>
          <>
            <p className="text-gray-dark text-sm 2xl:text-base">
              Para evitar quedas no seu ramal e mantê-lo sempre conectado,
              recomendamos que você instale a extensão da Voki no seu navegador.
            </p>
            <p className="pt-1 mb-4">
              <a
                href="https://chrome.google.com/webstore/detail/voki-extension-%F0%9F%9A%80/icoooedghhbfajjgeghgghnefnegmfle?hl=en&authuser=0"
                target="_blank"
                className="cursor-pointer text-green-bright text-sm font-bold no-underline hover:underline"
              >
                Clique aqui para instalar a extensão!
              </a>
            </p>
          </>
        </Card>
        <PageActions className="flex-row-reverse">
          <Button onClick={onSaveHandler}>Salvar</Button>
        </PageActions>
      </ContentWrapper>
    </SidebarLayout>
  );
}

export default DevicesPage;
