import { useState, useEffect, useCallback, useRef } from "react";
import Card from "../layout/Card";
import Avatar from "../layout/Avatar";
import avatar from "../../images/default_avatar/5.png";
import moment from "moment";

function DashboardExtensionCard({ extension, status }) {
    const [timer, setTimer] = useState("");
    const [elapsed, setElapsed] = useState();
    const [timerRunning, setTimerRunning] = useState(false);

    const timerRef = useRef(false);

    let borderColor = "border-gray/50";
    let bgColor = "bg-gray/50";
    let statusText = "Offline";
    let opacity = "opacity-50";
    let textColor = "text-gray-dark";

    useEffect(() => {
        if (extension && extension.date) {
            setTimerRunning(true);
        } else {
            setTimerRunning(false);
        }
    }, [extension]);

    useEffect(() => {
        if (timerRunning) {
            timerRef.current = true;
            tickClock();
        } else {
            timerRef.current = false;
        }
    }, [timerRunning]);

    const tickClock = () => {
        const now = moment();
        // const from = moment(new Date(extension.date));
        const from = moment(+extension.date);
        const diff = now.subtract(from);
        setElapsed(diff.valueOf());
        setTimer(diff.format("mm:ss"));

        if (timerRef.current) {
            setTimeout(() => {
                tickClock();
            }, process.env.REACT_APP_TIMERS_POLLING_RATE);
        }
    };

    if (extension.state) {
        switch (extension.state) {
            case "oncall":
                borderColor = "border-green";
                bgColor = "bg-green";
                statusText = "Em Atendimento";
                opacity = "opacity-100";
                textColor = "text-green";
                break;
            case "receiving":
                borderColor = "border-yellow";
                bgColor = "bg-yellow";
                statusText = "Recebendo";
                opacity = "opacity-100";
                textColor = "text-yellow";
                break;
            case "calling":
                borderColor = "border-yellow";
                bgColor = "bg-yellow";
                statusText = "Ligando";
                opacity = "opacity-100";
                textColor = "text-yellow";
                break;
            case "online":
                opacity = "opacity-100";
                statusText = "Disponível";
                break;
        }
    }

    return (
        <Card
            className={`border-[3px] transition-all duration-500 2xl:border-4 ${borderColor} ${opacity}`}
        >
            <div className="flex">
                <div className="mr-4">
                    <Avatar
                        avatar={avatar}
                        className={`transition-all duration-500 w-10 h-10 ${bgColor}`}
                    />
                </div>
                <div className="flex justify-between w-full">
                    <div className="text-primary font-bold text-sm 2xl:text-base">
                        {extension.name}
                    </div>
                    <div className="text-gray-dark font-bold text-sm 2xl:text-base">
                        {extension.extension}
                    </div>
                </div>
            </div>
            <div className="text-gray-dark font-medium mt-4 text-sm 2xl:text-base">
                {statusText}
            </div>
            <div className="flex justify-between">
                {extension && extension.destination && (
                    <div className="text-gray-dark font-medium">
                        {extension.destination}
                    </div>
                )}
                {extension && extension.date && (
                    <div className={`text-gray-dark font-bold ${textColor}`}>
                        {timer}
                    </div>
                )}
            </div>
        </Card>
    );
}

export default DashboardExtensionCard;
