import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../services/firebase/auth";

function SidebarItem({ title, icon, linkTo, onClick, adminOnly, children }) {
    const auth = useAuth();

    const currentPage = useLocation().pathname;

    const selected = currentPage === linkTo || null;

    const textColor = selected ? "text-green-bright" : "text-white-dark";
    const bgColor = selected ? "bg-primary-bright" : "";

    return (
        <>
            {(adminOnly && auth.user.token.claims.admin) || !adminOnly ? (
                <div onClick={() => onClick && onClick(linkTo)}>
                    <div
                        className={`px-2 py-2 mb-1 rounded-l-10 text-sm relative 2xl:px-4 2xl:py-4 2xl:mb-2 ${textColor} ${bgColor} ${
                            !selected && "cursor-pointer"
                        }`}
                    >
                        <div
                            className={
                                selected &&
                                "absolute top-0 right-0 h-4 w-4 transform -translate-y-full bg-primary-bright before:w-4 before:h-4 before:absolute before:top-0 before:bg-primary before:inline-block before:rounded-br-20"
                            }
                        ></div>
                        <div className="flex items-center">
                            {icon && (
                                <div className="inline-block pr-4">
                                    <FontAwesomeIcon
                                        icon={icon}
                                        className="2xl:text-xl"
                                    />
                                </div>
                            )}
                            <div className="inline-block text-xs 2xl:text-sm">
                                {title}
                            </div>
                        </div>
                        <div
                            className={
                                selected &&
                                "absolute bottom-0 right-0 h-4 w-4 translate-y-full bg-primary-bright before:w-4 before:h-4 before:absolute before:top-0 before:bg-primary before:inline-block before:rounded-tr-20"
                            }
                        ></div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default SidebarItem;
