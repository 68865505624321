import { useSoftphone } from "../../services/softphone/softphoneContext";

function SoftphoneDisplay() {
    const softphone = useSoftphone();

    const onSubmit = (e) => {
        e.preventDefault();
        softphone.initiateCall();
    };

    return (
        <div className="w-full bg-black mb-4 p-4 2xl:p-5 rounded-15 select-none">
            <form onSubmit={onSubmit}>
                <div className="text-gray h-4 text-sm 2xl:text-base">
                    {softphone.statusMessage}
                </div>
                <input
                    id="phone-display"
                    type="text"
                    value={softphone.maskedDialedNumber}
                    onChange={(e) => softphone.setDialedNumber(e.target.value)}
                    onFocus={() => softphone.setDisplayIsFocused(true)}
                    onBlur={() => softphone.setDisplayIsFocused(false)}
                    className="bg-black text-white text-sm 2xl:text-2xl w-full outline-none border-b-2 border-black focus:border-gray mt-2 transition-colors"
                    disabled={softphone.callState !== "inactive"}
                />
            </form>
        </div>
    );
}

export default SoftphoneDisplay;
