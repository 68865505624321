import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageTitle from "../components/layout/PageTitle";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import ContentWrapper from "../components/layout/ContentWrapper";
import TextInput from "../components/layout/TextInput";
import Select from "../components/layout/Select";
import DateRangeInput from "../components/layout/DateRangeInput";
import PhoneOrExtInput from "../components/layout/PhoneOrExtInput";
import Card from "../components/layout/Card";
import Button from "../components/layout/Button";
import ReportTable from "../components/report/ReportTable";
import CreateUserContactModal from "../components/userContacts/CreateUserContactModal";
import TablePaginator from "../components/TableLayout/TablePaginator";
import DownloadToast from "../components/toast/DownloadToast";
import PageActions from "../components/layout/PageActions";

import { getReportData } from "../services/report";
import { getExtensions } from "../services/extensions";
import { useAuth } from "../services/firebase/auth";
import { useSoftphone } from "../services/softphone/softphoneContext";
import { useContacts } from "../services/UserContactsContext";
import { createUserContact } from "../services/userContacts";
import { downloadRecording } from "../services/callRecording";
import { getQueues } from "../model/queue.model";

import { formatISODateTime } from "../utils/formatting";

import { useQueryPagination } from "../hooks/queryPagination";

function ReportPage() {
  const [isLoading, setIsLoading] = useState(true);

  const [nameSearch, setNameSearch] = useState("");
  const [extensionSearch, setExtensionSearch] = useState("");
  const [phoneSearch, setPhoneSearch] = useState("");
  const [dateRangeSearch, setDateRangeSearch] = useState("");
  const [directionSearch, setDirectionSearch] = useState("");
  const [queuesSearch, setQueuesSearch] = useState("");

  const [showCreateContact, setShowCreateContact] = useState(false);
  const [contactData, setContactData] = useState({});

  const [extensions, setExtensions] = useState([]);
  const [queues, setQueues] = useState([]);
  const toastId = useRef(null);

  const queryPagination = useQueryPagination(getReportData, 15);
  const auth = useAuth();
  const softphone = useSoftphone();
  const contacts = useContacts();

  const search = async () => {
    const filters = {
      name: nameSearch,
      phone: phoneSearch,
      extension: extensionSearch,
      date: dateRangeSearch,
      direction: directionSearch,
      queueId: queuesSearch
    };

    await queryPagination.search(filters);
  };

  const changePage = async (page) => {
    setIsLoading(true);
    await queryPagination.goToPage(page);
    setIsLoading(false);
  };

  const searchHandler = async () => {
    setIsLoading(true);
    await search();
    setIsLoading(false);
  };

  const exportHandler = async () => {
    setIsLoading(true);
    const filters = {
      name: nameSearch,
      phone: phoneSearch,
      extension: extensionSearch,
      date: dateRangeSearch,
    };
    const headers = [
      "Direção",
      "Nome",
      "Telefone",
      "Atendente",
      "Fila",
      "Data",
      "Duração",
      "Status",
    ];
    const data = [];

    const { data: results } = await getReportData(filters);

    for (const result of results) {
      const { direction, contactName, phone, extension, date, queueId } = result;
      const { duration, status } = result;

      const hasExtension = extensions.find((v) => v.id === extension);

      data.push([
        direction === "outbound" ? "Chamada efetuada" : "Chamada recebida",
        contactName,
        phone,
        hasExtension ? hasExtension.name : "Desconhecido",
        queues.find(_queue => _queue === queueId)?.name || '',
        formatISODateTime(date),
        duration,
        status === "answered" ? "Atendida" : "Perdida",
      ]);
    }

    const csvData = [headers, ...data];
    const csvContent = csvData.map((row) => row.join(";")).join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const csvURL = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = csvURL;
    const today = formatISODateTime(new Date());
    link.download = `chamadas_${today}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsLoading(false);
  };

  const addContactHandler = (item) => {
    setContactData(item);
    setShowCreateContact(true);
  };

  const initiateCall = (number, name) => {
    softphone.initiateCall(number, name);
  };

  const saveContactHandler = async (contact) => {
    setIsLoading(true);
    await createUserContact(auth, contact);
    contacts.reload();
    reload();
  };

  const downloadHandler = async (callId, date) => {
    // const dismiss = () => {
    //   toast.dismiss(toastId.current);
    // };
    // toastId.current = toast(
    //   <DownloadToast callId={callId} date={date} dismiss={dismiss} />,
    //   {
    //     autoClose: 0,
    //   }
    // );

    await downloadRecording(auth, callId, date);
  };

  const init = async () => {
    const res = await getExtensions(auth);
    const queuesData = await getQueues(auth);

    setQueues(queuesData);
    setExtensions(res);
    setIsLoading(false);
  };

  const reload = async () => {
    setIsLoading(true);
    await search();
    setShowCreateContact(false);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <SidebarLayout>
      <PageLoadSpinner show={isLoading} />
      <ContentWrapper show={!isLoading}>
        <PageTitle>Relatório de Chamadas</PageTitle>
        <Card className="w-full">
          <div className="flex flex-row items-end justify-start md:justify-between">
            <Select
              title="Atendente"
              setter={setExtensionSearch}
              value={extensionSearch}
              className="mr-2 min-w-max"
            >
              {extensions &&
                extensions.map((o) => (
                  <option key={o.id} value={o.id}>
                    {o.name}
                  </option>
                ))}
            </Select>
            <DateRangeInput
              title="Data"
              value={dateRangeSearch}
              setter={setDateRangeSearch}
              className="mr-2"
            />
            <Select
              title="Fila"
              setter={setQueuesSearch}
              value={queuesSearch}
              className="flex-1"
            >
              {queues &&
                queues.map((_queue) => (
                  <option key={_queue.id} value={_queue.id}>
                    {_queue.name}
                  </option>
                ))}
            </Select>
            <Button className="ml-2" onClick={searchHandler}>Pesquisar</Button>
          </div>
          <div className="flex">
            <TextInput
              title="Nome"
              value={nameSearch}
              setter={setNameSearch}
              className="mr-2 flex-1"
            />
            <PhoneOrExtInput
              title="Telefone"
              value={phoneSearch}
              setter={setPhoneSearch}
              className="mr-2 flex-1"
            />
            <Select
              title="Sentido"
              setter={setDirectionSearch}
              value={directionSearch}
              className="mr-2 min-w-max"
            >
              <option value={'inbound'}>Recebida</option>
              <option value={'outbound'}>Efetuada</option>
            </Select>
          </div>
        </Card>
        <ReportTable
          data={queryPagination.data}
          extensions={extensions}
          hasLoaded={queryPagination.hasSearched}
          addContact={addContactHandler}
          initiateCall={initiateCall}
          downloadRecording={downloadHandler}
          queues={queues}
          contacts={contacts}
        />
        <TablePaginator
          page={queryPagination.page}
          numPages={queryPagination.numPages}
          goToPage={changePage}
        />
        <CreateUserContactModal
          shown={showCreateContact}
          initValues={contactData}
          onClose={() => setShowCreateContact(false)}
          onSave={saveContactHandler}
        />
        <PageActions className="flex-row-reverse">
          {queryPagination.data?.length > 0 && (
            <Button onClick={exportHandler}>Exportar</Button>
          )}
        </PageActions>
      </ContentWrapper>
    </SidebarLayout>
  );
}

export default ReportPage;
