function Button({
  children,
  onClick,
  outline = false,
  color = "",
  className = "",
  disabled = false
}) {
  let borderStyle = "";
  let backgroundStyle = "";

  switch (color) {
    case "primary":
      if (outline) {
        borderStyle = "border-2 border-primary text-primary";
      } else {
        backgroundStyle = "text-white bg-primary";
      }
      break;
    case "red":
      if (outline) {
        borderStyle = "border-2 border-red text-red";
      } else {
        backgroundStyle = "text-white bg-red";
      }
      break;
    case "yellow":
      if (outline) {
        borderStyle = "border-2 border-yellow text-yellow";
      } else {
        backgroundStyle = "text-white bg-yellow";
      }
      break;
    case "green":
      if (outline) {
        borderStyle = "border-2 border-green text-green";
      } else {
        backgroundStyle = "text-white bg-green";
      }
      break;
    case "gray":
      if (outline) {
        borderStyle = "border-2 border-gray-dark text-gray-dark";
      } else {
        backgroundStyle = "text-white bg-gray-dark";
      }
      break;
    default:
      if (outline) {
        borderStyle = "border-2 border-purple text-purple";
      } else {
        backgroundStyle = "text-white bg-purple";
      }
      break;
  }

  if(disabled) backgroundStyle = "text-white bg-gray";

  return (
    <button
      className={`box-border py-2 px-6 2xl:h-10 font-bold text-xs 2xl:text-sm rounded-10 outline-none ${backgroundStyle} ${borderStyle} ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export default Button;
