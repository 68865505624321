import InputGroup from "./InputGroup";
import HoursSelect from "./HoursSelect";
import { useCallback, useEffect, useState } from "react";
import _ from "lodash";

function HourRangeInput({
    title,
    value,
    setter,
    formValidation,
    disabled,
    required,
    className,
}) {
    //TODO: testar se colocar vcalor aqui dentro aparece no input group
    const [id] = _.uniqueId("hourrange-");
    const [error, setError] = useState(false);
    const [hourRange, setHourRange] = useState({ from: "", to: "" });

    const validate = useCallback(() => {
        if (disabled) {
            setError(false);
            return true;
        }

        if (required) {
            if (!hourRange || !hourRange.from || !hourRange.to) {
                setError("Obrigatório");
                return false;
            }
        }

        if (hourRange.from > hourRange.to) {
            setError("Hora inicial deve ser menor que final");
            return false;
        }

        setError(false);
        return true;
    }, [setError, required, hourRange, disabled]);

    const setFromHandler = useCallback(
        (val) => {
            setHourRange((range) => ({ ...range, from: val }));
            setter({ ...value, from: val });
        },
        [value, setter]
    );

    const setToHandler = useCallback(
        (val) => {
            setHourRange((range) => ({ ...range, to: val }));
            setter({ ...value, to: val });
        },
        [value, setter]
    );

    const onChangeHandler = () => {
        if (error) {
            setError(false);
        }
    };

    useEffect(() => {
        setHourRange(value);
    }, [value]);

    useEffect(() => {
        if (formValidation) {
            formValidation.registerValidationCallback({ id, validate });
            return () => formValidation.unregisterValidationCallback(id);
        }
    }, [formValidation, id, validate, hourRange]);

    const textColor = error ? "text-red" : "text-gray-dark";

    return (
        <div className={`${className}`}>
            <InputGroup title={title} error={error} className="w-full">
                <div className="flex items-center justify-between">
                    <HoursSelect
                        value={value.from}
                        setter={setFromHandler}
                        onChange={onChangeHandler}
                        disabled={disabled}
                        error={error ? true : false}
                        className="w-full"
                    />
                    <span className={`mx-4 ${textColor}`}>até</span>
                    <HoursSelect
                        value={value.to}
                        setter={setToHandler}
                        disabled={disabled}
                        onChange={onChangeHandler}
                        error={error ? true : false}
                        className="w-full"
                    />
                </div>
            </InputGroup>
        </div>
    );
}

export default HourRangeInput;
