import React from "react";
import { twMerge } from "tailwind-merge";

function ContentWrapper(props: ContentWrapperProps) {
  const defaultClasses = "content-wrapper";
  const classes = twMerge(defaultClasses, props.className);

  return (
    <>
      {
        <div
          className={classes}
          style={{ display: props.show ? "block" : "none" }}
        >
          {props.children}
        </div>
      }
    </>
  );
}

export default ContentWrapper;

export type ContentWrapperProps = {
  children: React.ReactNode;
  show: boolean;
  className?: string;
};
