import { useCallback, useEffect, useRef, useState } from "react";
import InputGroup from "./InputGroup";
import { uniqueId } from "lodash";

function FileUpload({
  title,
  fileName,
  setter,
  required = false,
  formValidation,
  className = "",
  onChange = null
}) {
  const [id] = useState(uniqueId("input-"));
  const [errorState, setErrorState] = useState("");
  const [file, setFile] = useState("");

  const uploadRef = useRef();

  const onChangeHandler = () => {
    setFile(uploadRef.current.files[0]);
    setter(uploadRef.current.files[0]);
    setErrorState(false);
    onChange && onChange();
  };

  const onClick = () => {
    uploadRef.current.click();
  };

  const validate = useCallback(() => {
    if (required && !file && !fileName) {
      setErrorState("Obrigatório");
      return false;
    }
    return true;
  }, [required, file, fileName]);

  useEffect(() => {
    if (formValidation) {
      formValidation.registerValidationCallback({ id, validate });
      return () => formValidation.unregisterValidationCallback(id);
    }
  }, [id, validate, formValidation]);

  const placeholder = (
    <span className="text-gray">Nenhum arquivo selecionado...</span>
  );

  const borderColor = errorState ? "border-red/60" : "border-gray/50";
  const focusBorderColor = errorState
    ? "focus:border-red/80"
    : "focus:border-gray";

  return (
    <div className="inline-block">
      <input
        type="file"
        name="file"
        onChange={onChangeHandler}
        ref={uploadRef}
        className="hidden"
      />
      <InputGroup
        title={title}
        className={className}
        inputId={id}
        error={errorState}
      >
        <div
          className={`flex bg-white w-full border-2 text-sm text-primary rounded-10 outline-none cursor-pointer ${borderColor} ${focusBorderColor}`}
          onClick={onClick}
        >
          <div className="px-4 py-2 w-full max-w-[650px]">
            {!fileName && placeholder}
            {fileName && fileName}
          </div>
          <div className="bg-gray/50 text-sm font-semibold px-4 py-2 rounded-r-[6px]">
            Selecionar
          </div>
        </div>
      </InputGroup>
    </div>
  );
}

export default FileUpload;
