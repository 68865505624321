import { useState, useEffect, useCallback, useRef } from "react";
import { uniqueId } from "lodash";
import InputGroup from "./InputGroup";

function TextArea({
  title,
  value,
  setter,
  required = false,
  error = false,
  charLimit = 350,
  validation = null,
  formValidation,
  onChange = null,
  className,
}) {
  const [id] = useState(uniqueId("input-"));
  const [errorState, setErrorState] = useState(false);
  const [rawVal, setRawVal] = useState("");
  const [charCount, setCharCount] = useState(0);

  const ref = useRef();

  const validate = useCallback(() => {
    const val = ref.current.value;
    if (required && !val) {
      setErrorState("Obrigatório");
      return false;
    }

    if (!validateLength(val)) return false;

    if (validation) {
      const validResponse = validation(val);
      if (validResponse.error) {
        setErrorState(validResponse.message || true);
        return false;
      } else {
        setErrorState(false);
        return true;
      }
    }
    return true;
  }, [validation, required]);

  const validateLength = (val) => {
    if (charLimit) {
      if (val.length > charLimit) {
        setErrorState(`${val.length}/${charLimit}`);
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const onBlur = () => {
    validate() && setter(rawVal);
  };

  const onChangeHandler = (e) => {
    setRawVal(e.target.value);
    setCharCount(e.target.value.length);
    if (errorState) setErrorState(false);

    validateLength(e.target.value);

    onChange && onChange(e.target.value);
  };

  useEffect(() => {
    if (formValidation) {
      formValidation.registerValidationCallback({ id, validate });
      return () => formValidation.unregisterValidationCallback(id);
    }
  }, [id, validate, formValidation]);

  useEffect(() => {
    if (value) {
      setRawVal(value);
      setCharCount(value?.length || 0);
      validateLength(value);
    }
  }, [value]);

  useEffect(() => {
    setErrorState(error);
  }, [error]);

  const borderColor = errorState ? "border-red/60" : "border-gray/50";
  const focusBorderColor = errorState
    ? "focus:border-red/80"
    : "focus:border-gray";

  return (
    <InputGroup
      title={title}
      className={className}
      inputId={id}
      error={errorState}
      info={`${charCount}/${charLimit}`}
    >
      <textarea
        className={`block bg-white w-full h-full px-4 py-2 border-2 text-sm text-primary rounded-10 outline-none ${borderColor} ${focusBorderColor}`}
        onChange={onChangeHandler}
        onBlur={onBlur}
        value={rawVal}
        ref={ref}
      />
    </InputGroup>
  );
}

export default TextArea;
