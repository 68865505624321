import React, { useEffect, useRef, useState } from "react";
import PlayButton from "../layout/PlayButton";
import {
  PredefinedAudioMessage,
  TextAudioMessage,
  generateTempTTS,
} from "../../model/queue.model";
import { BaseTTSPreview, BaseTTSPreviewProps } from "./BaseTTSPreview";
import { WizardQueue, previewTTS } from "../../model/setupWizard.model";

export function WizardTTSPreview(props: WizardTTSPreviewProps) {
  const [hasChanged, setHasChanged] = useState(true);

  useEffect(() => {
    setHasChanged(true);
  }, [JSON.stringify(props.queues)]);

  const validateRegen = () => {
    return hasChanged;
  }

  const onGeneration = () => {
    setHasChanged(false);
  }

  return (
    <BaseTTSPreview
      {...props}
      validateNeedOfRegen={validateRegen}
      onGenerated={onGeneration}
      ttsGenerationFunc={(message) => previewTTS(message, props.queues)}
    />
  );
}

export type WizardTTSPreviewProps = BaseTTSPreviewProps & {
  queues: WizardQueue[];
};
