import Modal from "../layout/Modal";
import TextInput from "../layout/TextInput";
import Button from "../layout/Button";
import Switch from "../layout/Switch";
import InputGroup from "../layout/InputGroup";
import { useEffect, useState } from "react";
import { createExtension } from "../../services/extensions";
import { useAuth } from "../../services/firebase/auth";
import EmailInput from "../layout/EmailInput";
import ExtensionInput from "../layout/ExtensionInput";
import useFormValidation from "../../hooks/formValidation";
import CreatePasswordInput from "../layout/CreatePasswordInput";
import { validateExtension } from "../../utils/validation";

function CreateExtensionModal({
  shown = false,
  onClose,
  onSave,
  setIsLoading,
  recordingLimit,
  recordingNumber,
  extensions,
}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [extensionNumber, setExtensionNumber] = useState("");
  const [password, setPassword] = useState("");
  const [recording, setRecording] = useState(false);
  const [downloadRecording, setDownloadRecording] = useState(false);

  const [error, setError] = useState("");

  const auth = useAuth();
  const formValidation = useFormValidation();

  const saveHandler = async () => {
    if (formValidation.validateForm()) {
      formValidation.saveForm();
      const extensionData = {
        name,
        email,
        extensionNumber: Number(extensionNumber),
        password,
        recording,
        downloadRecording
      };
      setIsLoading && setIsLoading(true);

      const { data: result } = await createExtension(auth, extensionData);
      if (result && result.error) {
        setIsLoading && setIsLoading(false);
        if (result.data.includes("email")) {
          setError("E-mail já cadastrado.");
        } else {
          setError("Ocorreu um erro. Tente novamente.");
        }
      } else {
        onSave && onSave();
        clear();
        setIsLoading && setIsLoading(false);
      }
    }
  };

  const onCloseHandler = () => {
    clear();
    onClose && onClose();
  };

  const clear = () => {
    setName("");
    setEmail("");
    setExtensionNumber("");
    setPassword("");
    setError("");
  };

  const extensionValidation = (v) => {
    const result = validateExtension(v);
    if (result && result.error) return result;

    const extensionAlreadyUsed = extensions.find(
      (e) => e.extensionNumber === v
    );
    if (extensionAlreadyUsed) return { error: true, message: "Já utilizado" };
    return { error: false };
  };

  return (
    <Modal
      shown={shown}
      onClose={onCloseHandler}
      tooltipMessageId="extension-config"
      title="Criar Novo Ramal"
      className="min-w-[300px] 2xl:min-w-[400px]"
    >
      <div className="grid grid-cols-1 gap-4 mb-6">
        <TextInput
          value={name}
          setter={setName}
          title="Nome do atendente"
          formValidation={formValidation}
          required
        />
        <EmailInput
          value={email}
          setter={setEmail}
          title="E-mail"
          formValidation={formValidation}
          required
        />
        <ExtensionInput
          value={extensionNumber}
          setter={setExtensionNumber}
          title="Número do ramal"
          formValidation={formValidation}
          validation={extensionValidation}
          required
        />
        <CreatePasswordInput
          value={password}
          setter={setPassword}
          title="Senha"
          formValidation={formValidation}
          required
        />
        <div className="flex justify-between">
          <InputGroup title="Gravação de ligação">
            <Switch
              titleActive="Ativo"
              titleInactive="Inativo"
              className="col-span-1"
              formValidation={formValidation}
              value={recording}
              disabled={Number(recordingNumber) === 0}
              onChange={setRecording}
            />
          </InputGroup>
          <div>
            <span className="block text-gray-dark text-sm font-bold">
              Disponíveis
            </span>
            <span className="block text-gray-dark font-bold text-right leading-5">
              {recording ? recordingNumber - 1 : recordingNumber}/
              {recordingLimit}
            </span>
          </div>
        </div>
        <InputGroup title="Download Gravação">
          <Switch
            titleActive="Ativo"
            titleInactive="Inativo"
            className="col-span-1"
            formValidation={formValidation}
            value={downloadRecording}
            onChange={setDownloadRecording}
          />
        </InputGroup>
      </div>
      <div className="flex justify-between">
        <div>{error && <span className="text-red  text-sm">{error}</span>}</div>
        <Button color="purple" onClick={saveHandler}>
          Salvar
        </Button>
      </div>
    </Modal>
  );
}

export default CreateExtensionModal;
