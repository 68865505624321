import React, { Dispatch, SetStateAction, useState } from "react";
import Select from "../layout/Select";
import TextArea from "../layout/TextArea";
import FileUpload from "../layout/FileUpload";
import UploadedPreview from "./UploadedPreview";
import { AudioMessage, FileAudioMessage } from "../../model/queue.model";
import { Setter } from "../../utils/types";

export function FileMessageSelection(props: FileMessageSelectionProps) {
  const [file, setFile] = useState<File>();

  const fileName =
    props.message && props.message.type === "file"
      ? props.message.filename
      : "";

  const required = props.message && props.message.type === "file" ? props.message.hasChanged : true

  const set = (file: File) => {
    setFile(file);
    const formData = new FormData();
    formData.append("File", file);

    props.setMessage((v) => ({
      type: "file",
      id: v!.id,
      filename: file.name,
      formData,
      hasChanged: true,
      useOptions: v!.useOptions,
    }));
  };

  return (
    <div className="flex items-end">
      <FileUpload
        title="Arquivo"
        fileName={fileName}
        setter={set}
        formValidation={props.formValidation}
        //@ts-ignore
        onChange={props.onChange}
        required={required}
      />
      <UploadedPreview
        className="ml-4 mt-2"
        message={props.message}
        file={file}
      />
    </div>
  );
}

export type FileMessageSelectionProps = {
  message: AudioMessage;
  setMessage: Setter<AudioMessage>;
  formValidation: any;
  onChange?: () => void;
};
