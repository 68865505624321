import { useEffect, useState } from "react";
import Modal from "../layout/Modal";
import Button from "../layout/Button";
import useFormValidation from "../../hooks/formValidation";
import TextInput from "../layout/TextInput";
import EmailInput from "../layout/EmailInput";
import PhoneOrExtInput from "../layout/PhoneOrExtInput";
import _ from "lodash";

function CreateUserContactModal({ initValues, shown, onClose, onSave }) {
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [sector, setSector] = useState("");

    const saveHandler = async () => {
        if (formValidation.validateForm()) {
            onSave &&
                onSave({
                    id,
                    name,
                    phone: Number(phone),
                    email,
                    company,
                    sector,
                });
            clear();
        }
    };

    const onCloseHandler = () => {
        clear();
        onClose && onClose();
    };

    const clear = () => {
        setName("");
        setPhone("");
        setEmail("");
        setCompany("");
        setSector("");
    };

    useEffect(() => {
        if (!_.isEmpty(initValues)) {
            setId(initValues.id);
            setName(initValues.name);
            setPhone(initValues.phone);
            setEmail(initValues.email);
            setCompany(initValues.company);
            setSector(initValues.sector);
        }
    }, [initValues]);

    const formValidation = useFormValidation();

    return (
        <Modal
            shown={shown}
            onClose={onCloseHandler}
            title="Criar novo Contato"
            className="min-w-[400px]"
        >
            <div className="grid gap-3 2xl:gap-4 mb-4">
                <TextInput
                    title="Nome"
                    value={name}
                    setter={setName}
                    formValidation={formValidation}
                    required
                />
                <PhoneOrExtInput
                    title="Telefone"
                    value={phone}
                    setter={setPhone}
                    formValidation={formValidation}
                    required
                />
                <EmailInput
                    title="Email"
                    value={email}
                    setter={setEmail}
                    formValidation={formValidation}
                />
                <TextInput
                    title="Empresa"
                    value={company}
                    setter={setCompany}
                    formValidation={formValidation}
                />
                <TextInput
                    title="Setor"
                    value={sector}
                    setter={setSector}
                    formValidation={formValidation}
                />
            </div>
            <div className="flex justify-end">
                <Button color="purple" onClick={saveHandler}>
                    Salvar
                </Button>
            </div>
        </Modal>
    );
}

export default CreateUserContactModal;
