import TextInput from "./TextInput";
import { validateHour } from "../../utils/validation";
import { hourMask } from "../../utils/masks";
import { useState } from "react";

function HourInput({ ...props }) {
  const setter = (v) => {
    props.setter(v.substr(0, 2) + ":" + v.substr(2, 2));
  };

  let value = props.value;
  if (typeof value !== "boolean") {
    value = props.value.replace(":", "");
  }

  return (
    <TextInput
      {...props}
      validation={validateHour}
      mask={hourMask}
      placeholder={props.placeholder || "--:--"}
      value={value}
      setter={setter}
    />
  );
}

export default HourInput;
