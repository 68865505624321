import { useState } from "react";
import { useAuth } from "../services/firebase/auth";

import TextInput from "../components/layout/TextInput";
import Button from "../components/layout/Button";
import Card from "../components/layout/Card";
import CardTitle from "../components/layout/CardTitle";
import Logo from "../components/layout/Logo";
import { useHistory } from "react-router";

function RegistrationPage() {
    const auth = useAuth();
    const history = useHistory();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");

    const registerHandler = async () => {
        if (email && password) {
            await auth.registerUser(name, email, password, companyName, 100);
            await auth.loginUser(email, password);
            history.push("/");
        }
    };

    return (
        <div className="bg-white-dark min-h-screen flex flex-col justify-center items-center">
            <Logo type="dark" className="mb-8" />
            <Card className="w-[400px]">
                <CardTitle>Pessoal</CardTitle>
                <div className="grid">
                    <TextInput title="Nome" value={name} setter={setName} />
                    <TextInput title="E-mail" value={email} setter={setEmail} />
                    <TextInput
                        title="Senha"
                        value={password}
                        setter={setPassword}
                    />
                </div>
                <CardTitle>Empresa</CardTitle>
                <div className="grid">
                    <TextInput
                        title="Nome"
                        value={companyName}
                        setter={setCompanyName}
                    />
                </div>
                <div>
                    <Button onClick={registerHandler}>Registrar</Button>
                </div>
            </Card>
        </div>
    );
}

export default RegistrationPage;
