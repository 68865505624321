import TableRow from "./TableRow";
import TableHeaderRow from "./TableHeaderRow";
import _ from "lodash";

function TableLayout({ data, headers, emptyMessage, rowTransform }) {
    const numCols = headers.length || 0;

    return (
        <>
            <table className="w-full mt-4 drop-shadow">
                <thead>
                    <TableHeaderRow headerData={headers} />
                </thead>
                <tbody>
                    {!_.isEmpty(data) &&
                        data.map((item, i) => {
                            const rowData = rowTransform
                                ? rowTransform(item)
                                : item;
                            const isLast = data.length === i + 1;
                            return (
                                <TableRow
                                    key={i}
                                    rowData={rowData}
                                    isLast={isLast}
                                ></TableRow>
                            );
                        })}
                    {_.isEmpty(data) && (
                        <tr>
                            <td
                                colSpan={numCols}
                                className="bg-white rounded-b-15"
                            >
                                <div className="grid place-items-center align-middle h-80">
                                    <span className="text-gray-dark font-bold text-sm 2xl:text-lg">
                                        {emptyMessage
                                            ? emptyMessage
                                            : "Ops! Nenhum dado encontrado"}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
}

export default TableLayout;
