import Card from "./Card";
import ModalHeader from "./ModalHeader";

function Modal(props: ModalProps) {
  return (
    <>
      {props.shown && (
        <div className="fixed flex justify-center items-center top-0 left-0 w-full h-screen z-50">
          <div
            className="absolute w-full h-full"
            style={{
              backgroundColor: "rgba(80,78,100,0.5)",
              backdropFilter: "blur(2px)",
            }}
            onClick={props.onClose}
          ></div>
          <Card className={`z-20 ${props.className}`}>
            <ModalHeader
              tooltipMessageId={props.tooltipMessageId}
              onClose={props.onClose}
            >
              {props.title}
            </ModalHeader>
            {props.children}
          </Card>
        </div>
      )}
    </>
  );
}

export default Modal;
export type ModalProps = {
  shown: boolean;
  title: string;
  children: React.ReactNode;
  tooltipMessageId?: string;
  onClose?: () => void;
  className?: string;
};
