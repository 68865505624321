import { useEffect, useState } from "react";
import { useAuth } from "../../services/firebase/auth";
import { downloadRecording } from "../../services/callRecording";
import SoftphoneSpinner from "../softphone/SoftphoneSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";

function DownloadToast({ callId, date, dismiss }) {
    const [state, setState] = useState("loading");
    const [errorMessage, setErrorMessage] = useState("");

    const auth = useAuth();

    const init = async () => {
        setState("loading");
        const result = await downloadRecording(auth, callId, date);
        if (result.error) {
            setState("error");
            setErrorMessage(result.error);
        } else {
            setState("done");
        }
    };

    useEffect(() => {
        if (state !== "loading") {
            setTimeout(dismiss, 5000);
        }
    }, [state]);

    useEffect(() => {
        init();
    }, []);

    return (
        <>
            {state === "loading" && (
                <div className="flex items-center">
                    <SoftphoneSpinner size={50} />
                    <div className="font-medium ml-2">Buscando arquivo...</div>
                </div>
            )}
            {state === "error" && (
                <div className="flex items-center">
                    <FontAwesomeIcon
                        icon={faTimes}
                        size="lg"
                        className="text-red ml-2"
                    />
                    <div className="font-medium ml-4">
                        Erro ao buscar arquivo
                    </div>
                </div>
            )}
            {state === "done" && (
                <div className="flex items-center">
                    <FontAwesomeIcon
                        icon={faCheck}
                        size="lg"
                        className="text-green ml-2"
                    />
                    <div className="font-medium ml-4">Arquivo baixado</div>
                </div>
            )}
        </>
    );
}

export default DownloadToast;
