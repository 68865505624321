import React, { Dispatch, SetStateAction, useEffect } from "react";
import Select from "../layout/Select";
import { AudioMessage, Queue } from "../../model/queue.model";

export function QueueSelection(props: QueueSelectionProps) {
  const value =
    props.message && props.message.type === "queue" ? props.message.queueId : "";

  const set = (queue: string) => {
    props.setMessage((v) => ({
      type: "queue",
      id: v!.id,
      queueId: queue,
      useOptions: false,
    }));
  };

  return (
    <div className="flex items-start">
        <Select
          title="Fila de atendimento"
          className="min-w-[250px]"
          //@ts-ignore
          formValidation={props.formValidation}
          value={value}
          setter={set}
          required
        >
          {props.queues &&
            props.queues.map((q, i) => (
            <option value={q.id} key={i}>
              {q.name}
                </option>
            ))}
        </Select>
    </div>
  );
}

export type QueueSelectionProps = {
  message: AudioMessage;
  setMessage: Dispatch<SetStateAction<AudioMessage>>;
  formValidation: any;
  onChange?: () => void;
  queues: Queue[];
};