import { firestore } from "../services/firebase/firebaseInit";
import {
  doc,
  getDoc,
} from "@firebase/firestore";

export async function getAccountData(companyId: string): Promise<Account> {
  const docRef = doc(firestore, `companies/${companyId}`);
  const docSnap = await getDoc(docRef);

  const account = docSnap.data()! as Account;
  if(!account) throw new Error("Account not found");

  return {
    ...account,
    id: docSnap.id,
  };
}

export type Account = {
  id: string;
  address: string;
  addressNumber: string;
  addressReference: string;
  city: string;
  cpfCnpj: string;
  email: string;
  name: string;
  neighborhood: string;
  phone: string;
  state: string;
  zipcode: string;
  disabled?: boolean;
  usersLimit?: number;
  recordingLimit?: number;
  finalizedSetupWizard?: boolean;
};
