import Button from "../layout/Button";

export function SetupWizardControls(props: SetupWizardControlsProps) {
  return (
    <div className="mb-8 flex justify-between">
      {props.back ? (
        <Button className="" outline onClick={props.back}>
          Voltar
        </Button>
      ) : (
        <div />
      )}
      {props.next ? (
        <Button className="" onClick={props.next} disabled={props.nextDisabled || false}>
          {props.nextText || "Continuar"}
        </Button>
      ) : (
        <div />
      )}
    </div>
  );
}

export type SetupWizardControlsProps = {
  next?: () => void;
  back?: () => void;
  nextDisabled?: boolean;
  nextText?: string;
};
