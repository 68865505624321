import JSSip from "jssip";
import io from "socket.io-client";

export default class SipService {
    connection = null;
    socket;
    handlers;
    session;
    notification;

    connect(companyId, extension) {
        if (this.connection && this.connection.isRegistered()) return;

        var socket = new JSSip.WebSocketInterface(
            `wss://${process.env.REACT_APP_ASTERISK_URL}:8089/ws`
        );

        var configuration = {
            sockets: [socket],
            uri: `sip:${companyId}-${extension}@${process.env.REACT_APP_ASTERISK_URL}`,
            password: extension,
        };

        if (Notification.permission !== "granted") {
            Notification.requestPermission();
        }

        this.connection = new JSSip.UA(configuration);
        this.connection.start();

        this.connection.on("connected", () => {
            console.log("ws connected");
        });

        this.connection.on("disconnected", () => {
            console.log("ws disconnected");
            this.handlers.onWSDisconnected && this.handlers.onWSDisconnected();
        });

        this.connection.on("newRTCSession", (e) => {
            console.log("new RTC Session");

            if (e.originator === "remote") {
                this.onRecieved(e);
            } else {
                e.session.connection.onaddstream = (e) => {
                    var audio = document.getElementById("remote-audio");
                    audio.srcObject = e.stream;
                    audio
                        .setSinkId(
                            localStorage.getItem("outputDeviceId") || "default"
                        )
                        .then(() => {
                            audio.play();
                        });
                };
            }
        });

        this.connection.on("registered", () => {
            console.log("registered");
            this.handlers.onRegistrationSuccessful &&
                this.handlers.onRegistrationSuccessful();
        });
        this.connection.on("unregistered", () => {
            console.log("unregistered");
            this.disconnect();
            this.handlers.onUnregistration && this.handlers.onUnregistration();
        });

        this.connection.on("registrationFailed", (e) => {
            console.log("registration failed");
            this.handlers.onRegistrationFailed &&
                this.handlers.onRegistrationFailed();
        });

        this.socket = this.startSocket(companyId);

        this.socket.on("peerchange", (e) => {
            const { peer, state } = e;
            if (peer.extensionNumber.toString() !== extension) return;

            this.handlers.onMonitorStateChange &&
                this.handlers.onMonitorStateChange(state);
        });
    }

    startSocket = (companyId) => {
        const socket = io(process.env.REACT_APP_WS, {
            path: "/ws",
            query: {
                companyId,
            },
            secure: false,
        });

        return socket;
    };

    disconnect() {
        this.connection.unregister();
        this.connection.terminateSessions();
        this.connection.stop();
        this.clearNotifications();
    }

    onRecieved(e) {
        this.session = e.session;

        const callerName = e.session.remote_identity.display_name;
        const callerNumber = e.session.remote_identity.uri.user;

        this.notification = new Notification("Recebendo ligação", {
            body: `${callerName ? callerName + " - " : ""}${callerNumber}`,
        });

        setTimeout(this.clearNotifications, 10000);

        this.session.on("failed", () => {
            this.handlers.onReceivedFailed && this.handlers.onReceivedFailed();
        });

        this.handlers.onCallReceived &&
            this.handlers.onCallReceived(this.session);
    }

    pickup() {
        this.clearNotifications();

        if (this.session) {
            const callOptions = {
                mediaConstraints: {
                    audio: {
                        deviceId:
                            localStorage.getItem("inputDeviceId") || "default",
                    },
                    video: false,
                    pcConfig: {
                        iceServers: [
                            { urls: ["stun:stun.l.google.com:19302"] },
                        ],
                    },
                },
            };

            this.session.answer(callOptions);
            this.session.connection.onaddstream = (e) => {
                var audio = document.getElementById("remote-audio");
                audio.srcObject = e.stream;
                audio
                    .setSinkId(
                        localStorage.getItem("outputDeviceId") || "default"
                    )
                    .then(() => {
                        audio.play();
                    });
            };
            this.session.on("confirmed", (e) => {
                this.handlers.onPickedUp && this.handlers.onPickedUp();
            });
            this.session.on("ended", () => {
                this.session = null;
                this.handlers.onCallEnded && this.handlers.onCallEnded();
            });
            this.session.on("failed", () => {
                this.clearNotifications();
                this.session = null;
            });
        }
    }

    hangup() {
        this.clearNotifications();

        if (this.session) {
            this.session.terminate();
        }
        this.handlers.onHangedUp && this.handlers.onHangedUp();
    }

    renegotiate() {
        if (this.session) {
            var options = {
                mediaConstraints: {
                    audio: {
                        deviceId:
                            localStorage.getItem("inputDeviceId") || "default",
                    },
                    video: false,
                },
                pcConfig: {
                    iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }],
                },
            };

            this.session.renegotiate(options);

            var audio = document.getElementById("remote-audio");
            audio.pause();
            audio
                .setSinkId(localStorage.getItem("outputDeviceId") || "default")
                .then(() => {
                    audio.play();
                });
        }
    }

    call(number) {
        var options = {
            mediaConstraints: {
                audio: {
                    deviceId:
                        localStorage.getItem("inputDeviceId") || "default",
                },
                video: false,
            },
            pcConfig: {
                iceServers: [{ urls: ["stun:stun.l.google.com:19302"] }],
            },
        };

        this.session = this.connection.call(
            `sip:${number}@192.168.140.129`,
            options
        );

        this.session.on("icecandidate", function (event) {
            event.ready();
        });

        this.session.on("ended", () => {
            console.log("call ended");
            this.handlers.onHangup && this.handlers.onHangup();
        });

        this.session.on("confirmed", (e) => {
            this.handlers.onAccepted && this.handlers.onAccepted();
        });

        this.session.on("failed", (e) => {
            this.session = null;
            if (e.cause === "Rejected") {
                this.handlers.onRejected && this.handlers.onRejected();
            }
        });

        this.session.on("progress", () => {
            this.handlers.onCalling && this.handlers.onCalling();
            console.log("calling...");
        });
    }

    sendDTMF(value) {
        if (this.session) {
            this.session.sendDTMF(value);
        }
    }

    toggleMute() {
        if (this.session) {
            if (this.session.isMuted().audio) {
                this.session.unmute();
            } else {
                this.session.mute();
            }
        }
    }

    toggleHold() {
        if (this.session) {
            if (!this.session.isOnHold().local) {
                console.log("holding");
                this.session.hold();
            } else {
                console.log("unholding");
                this.session.unhold();
            }
        }
    }

    clearNotifications() {
        console.log("Clearing notification");
        this.notification && this.notification.close();
        this.notification = null;
    }

    registerHandlers(handlers) {
        this.handlers = handlers;
    }
}
