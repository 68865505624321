import { useHistory } from "react-router-dom";
import Button from "../../components/layout/Button";
import Card from "../../components/layout/Card";
import CardTitle from "../../components/layout/CardTitle";

export function SetupSuccess(props: SetupSuccessProps) {
  const history = useHistory();

  const onClick = () => {
    history.push("/");
  }

  return (
    <div className="w-full max-w-[650px] m-auto">
      <Card className="w-full 2xl:p-12">
        <CardTitle className="text-2xl 2xl:text-3xl">Parabéns!</CardTitle>
        <span className="text-gray-dark font-bold">
          Sua conta foi configurada com sucesso.
        </span>
        <p className="mt-2">
          <span className="text-gray-dark font-bold">
            Agora você já poderá utilizar sua conta Voki clicando abaixo:
          </span>
        </p>
        <div className="mt-6 flex justify-center">
          <Button onClick={onClick}>Explorar a Voki</Button>
        </div>
      </Card>
    </div>
  );
}

export type SetupSuccessProps = {};
