import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faUsersSlash } from "@fortawesome/free-solid-svg-icons";
import Card from "../layout/Card";
import { useAuth } from "../../services/firebase/auth";

function CreateExtensionCard({ numExtensions, limitExtensions, onClick }) {
    return (
        <>
            {(!limitExtensions || limitExtensions > numExtensions) && (
                <Card
                    className="hover:bg-white/70 transition delay-75 cursor-pointer"
                    onClick={onClick}
                >
                    <div className="h-full flex flex-col">
                        <div className="flex-1 flex justify-center items-center flex-col">
                            <FontAwesomeIcon
                                icon={faPlusCircle}
                                className="text-gray-dark block mx-auto mb-4 text-4xl 2xl:text-5xl select-none"
                            />
                            <div className="text-gray-dark font-bold text-sm 2xl:text-base text-center select-none">
                                Adicionar Ramal
                            </div>
                        </div>
                        {limitExtensions && (
                            <div className="text-gray-dark font-bold text-md h-6 text-center text-md 2xl:text-lg select-none">
                                {numExtensions}/{limitExtensions}
                            </div>
                        )}
                    </div>
                </Card>
            )}
            {limitExtensions <= numExtensions && (
                <Card className="hover:bg-white/70 transition delay-75 cursor-pointer">
                    <div className="h-full flex flex-col">
                        <div className="flex-1 flex justify-center items-center flex-col">
                            <FontAwesomeIcon
                                icon={faUsersSlash}
                                className="text-gray-dark block mx-auto mb-4 text-3xl 2xl:text-4xl select-none"
                            />
                            <div className="text-gray-dark font-bold text-sm 2xl:text-base text-center select-none">
                                Limite de ramais alcançado
                            </div>
                        </div>
                        <div className="text-gray-dark font-bold text-md h-6 text-center text-md 2xl:text-lg select-none">
                            {numExtensions}/{limitExtensions}
                        </div>
                    </div>
                </Card>
            )}
        </>
    );
}

export default CreateExtensionCard;
