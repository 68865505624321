import Modal from "../layout/Modal";
import Button from "../layout/Button";
import { useAuth } from "../../services/firebase/auth";
import { deleteEvent } from "../../services/events";

function DeleteEventModal({ shown, event, onClose, onConfirm, setIsLoading }) {
    const auth = useAuth();

    const confirmHandler = async () => {
        setIsLoading(true);
        await deleteEvent(auth, event.id, event.date);
        onConfirm && onConfirm();
        setIsLoading(false);
    };

    return (
        <Modal
            shown={shown}
            onClose={onClose}
            title="Deletar Evento"
            className="min-w-[400px]"
        >
            <div className="my-6 text-primary">
                Você tem certeza que deseja deletar este evento?
            </div>
            <div className="flex justify-between">
                <Button color="primary" onClick={onClose} outline>
                    Cancelar
                </Button>
                <Button color="red" onClick={confirmHandler}>
                    Confirmar
                </Button>
            </div>
        </Modal>
    );
}

export default DeleteEventModal;
