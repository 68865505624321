import { twMerge } from "tailwind-merge";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import SoftphoneTransferOption from "./SoftphoneTransferOption";

export default function SoftphoneTransferItem({
  extension,
  isSelected,
  select,
  accepted,
  cancelled,
}) {
  const unselectedClass =
    "text-sm rounded-15 py-2 px-4 hover:bg-gray-bluish cursor-pointer text-gray-dark font-bold transition-all";
  const selectedClass = twMerge(
    unselectedClass,
    "bg-green text-white hover:bg-green/80 cursor-auto pr-2"
  );

  return (
    <div
      key={extension.extensionId}
      className={isSelected ? selectedClass : unselectedClass}
      onClick={() => select(extension.extensionId)}
    >
      {isSelected && (
        <div className="flex justify-between items-center">
          <span className="flex-1 text-ellipsis">{extension.name}</span>
          <div className="flex">
            <SoftphoneTransferOption
              icon={faCheck}
              color="green"
              onClick={() => accepted(extension.extensionId)}
            />
            <SoftphoneTransferOption
              className="ml-2"
              icon={faTimes}
              color="red"
              onClick={() => cancelled(extension.extensionId)}
            />
          </div>
        </div>
      )}
      {!isSelected && (
        <div className="flex">
          <span className="flex-1 truncate mr-2">{extension.name}</span>
          <span className="">{extension.phone}</span>
        </div>
      )}
    </div>
  );
}
