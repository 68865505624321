function Spinner({size = "120px"}) {
    return (
        <div style={{width: size, height:size}}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <defs>
                <mask id="ldio-sml4vok0jyq-mask">
                    <circle
                        cx="50"
                        cy="50"
                        r="20"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeDasharray="94.24777960769379 31.41592653589793"
                        strokeWidth="9"
                    >
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            values="0 50 50;360 50 50"
                            times="0;1"
                            dur="0.9345794392523364s"
                            repeatCount="indefinite"
                        ></animateTransform>
                    </circle>
                </mask>
            </defs>
            <g mask="url(#ldio-sml4vok0jyq-mask)">
                <rect x="20.5" y="0" width="12.6" height="100" fill="#f30c36">
                    <animate
                        attributeName="fill"
                        values="#f30c36;#f3be35;#60d11b;#356af3;#2d486a"
                        times="0;0.25;0.5;0.75;1"
                        dur="0.9345794392523364s"
                        repeatCount="indefinite"
                        begin="-0.8s"
                    ></animate>
                </rect>
                <rect x="32.1" y="0" width="12.6" height="100" fill="#f3be35">
                    <animate
                        attributeName="fill"
                        values="#f30c36;#f3be35;#60d11b;#356af3;#2d486a"
                        times="0;0.25;0.5;0.75;1"
                        dur="0.9345794392523364s"
                        repeatCount="indefinite"
                        begin="-0.6s"
                    ></animate>
                </rect>
                <rect x="43.7" y="0" width="12.6" height="100" fill="#60d11b">
                    <animate
                        attributeName="fill"
                        values="#f30c36;#f3be35;#60d11b;#356af3;#2d486a"
                        times="0;0.25;0.5;0.75;1"
                        dur="0.9345794392523364s"
                        repeatCount="indefinite"
                        begin="-0.4s"
                    ></animate>
                </rect>
                <rect x="55.3" y="0" width="12.6" height="100" fill="#356af3">
                    <animate
                        attributeName="fill"
                        values="#f30c36;#f3be35;#60d11b;#356af3;#2d486a"
                        times="0;0.25;0.5;0.75;1"
                        dur="0.9345794392523364s"
                        repeatCount="indefinite"
                        begin="-0.2s"
                    ></animate>
                </rect>
                <rect x="66.9" y="0" width="12.6" height="100" fill="#2d486a">
                    <animate
                        attributeName="fill"
                        values="#f30c36;#f3be35;#60d11b;#356af3;#2d486a"
                        times="0;0.25;0.5;0.75;1"
                        dur="0.9345794392523364s"
                        repeatCount="indefinite"
                        begin="0s"
                    ></animate>
                </rect>
            </g>
        </svg>
        </div>
    );
}

export default Spinner;
