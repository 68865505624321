import { useSoftphone } from "../../services/softphone/softphoneContext";

function SoftphoneKey({ onClick, children }) {
    const softphone = useSoftphone();

    const clickHandle = (e) => {
        e.preventDefault();
        softphone.focusDisplay();
        onClick && onClick();
    };

    return (
        <div
            onPointerDown={clickHandle}
            className="p-4 flex justify-center items-center drop-shadow-keys rounded-full bg-white-mid w-12 h-12 2xl:w-16 2xl:h-16 hover:bg-white-dark select-none"
        >
            <span className="text-primary text-sm 2xl:text-xl font-bold">
                {children}
            </span>
        </div>
    );
}

export default SoftphoneKey;
