import { TextTTSPreview } from "../../components/audioSelection/TextTTSPreview";
import { WizardTTSPreview } from "../../components/audioSelection/WizardTTSPreview";
import Card from "../../components/layout/Card";
import CardTitle from "../../components/layout/CardTitle";
import { SetupWizardControls } from "../../components/setupWizard/SetupWizardControls";
import { PredefinedAudioMessage } from "../../model/queue.model";
import { SetupWizardConfig } from "../../model/setupWizard.model";

export function SetupConfirmationPage(props: SetupConfirmationPageProps) {
  return (
    <>
      <SetupWizardControls
        next={props.next}
        back={props.back}
        nextText="Finalizar"
      />
      <Card className="w-full 2xl:p-12 p-10">
        <CardTitle>Pronto!</CardTitle>
        <p className="text-gray-dark">
          Falta só você ouvir as suas mensagens de áudio e finalizar sua
          configuração.
        </p>
        <div className="mt-4 pl-4">
          <span className="font-bold text-lg text-gray-dark mr-2">Sua mensagem de boas-vindas: </span>
          <WizardTTSPreview
            companyName={props.value.account?.name}
            message={props.value.messages?.greeting as PredefinedAudioMessage}
            queues={props.value.queues!}
          />
        </div>
        <div className="mt-4 pl-4">
          <span className="font-bold text-lg text-gray-dark mr-2">Sua mensagem de erro de digitação: </span>
          <WizardTTSPreview
            companyName={props.value.account?.name}
            message={props.value.messages?.error as PredefinedAudioMessage}
            queues={props.value.queues!}
          />
        </div>
        <p className="text-gray-dark mt-8">
          Caso deseje fazer alterações, você pode voltar a passos anteriores
          livremente. Se tudo estiver correto, você poderá então finalizar a configuração e prosseguir para
          utilizar a Voki.
        </p>
      </Card>
    </>
  );
}

export type SetupConfirmationPageProps = {
  next: () => void;
  back: () => void;
  value: SetupWizardConfig;
};
