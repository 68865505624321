import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import ProtectedRoute from "./components/utils/ProtectedRoute";
import AdminRoute from "./components/utils/AdminRoute";
import { SoftphoneProvider } from "./services/softphone/softphoneContext";
import "./index.css";

import RegistrationPage from "./pages/Registration.page";
import LoginPage from "./pages/Login.page.js";
import DashboardPage from "./pages/Dashboard.page";
import UserContactsPage from "./pages/UserContacts.page";
import CallHistoryPage from "./pages/CallHistory.page";
import ReportPage from "./pages/Report.page";
import CompanyPage from "./pages/Company.page";
import BillingPage from "./pages/Billing.page";
import DevicesPage from "./pages/Devices.page";
import ExtensionsPage from "./pages/Extensions.page";
import IvrPage from "./pages/Ivr.page";
import NumbersPage from "./pages/Numbers.page";
import WorkingHoursPage from "./pages/WorkingHours.page";
import ProfilePage from "./pages/Profile.page";
import HelpPage from "./pages/Help.page";

import Softphone from "./components/softphone/Softphone";
import { UserContactsProvider } from "./services/UserContactsContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PublicRoute } from "./components/utils/PublicRoute";
import { SetupWizardPage } from "./pages/SetupWizard.page";
import { SetupWizardProvider } from "./services/SetupWizardProvider";

function App() {
  return (
    <>
      <UserContactsProvider>
        <SoftphoneProvider>
          <BrowserRouter>
            <SetupWizardProvider>
              <Switch>
                <ProtectedRoute exact path="/" softphone>
                  <DashboardPage />
                </ProtectedRoute>
                <ProtectedRoute exact path="/contacts" softphone>
                  <UserContactsPage />
                </ProtectedRoute>
                <ProtectedRoute path="/callHistory" softphone>
                  <CallHistoryPage />
                </ProtectedRoute>
                <AdminRoute path="/report" softphone>
                  <ReportPage />
                </AdminRoute>
                <AdminRoute path="/company" softphone>
                  <CompanyPage />
                </AdminRoute>
                <AdminRoute path="/billing" softphone>
                  <BillingPage />
                </AdminRoute>
                <ProtectedRoute path="/devices" softphone>
                  <DevicesPage />
                </ProtectedRoute>
                <ProtectedRoute path="/help" softphone>
                  <HelpPage />
                </ProtectedRoute>
                <AdminRoute path="/setup-wizard">
                  <SetupWizardPage />
                </AdminRoute>
                <AdminRoute path="/extensions" softphone>
                  <ExtensionsPage />
                </AdminRoute>
                <AdminRoute path="/ivr" softphone>
                  <IvrPage />
                </AdminRoute>
                <AdminRoute path="/numbers" softphone>
                  <NumbersPage />
                </AdminRoute>
                <AdminRoute path="/working-hours" softphone>
                  <WorkingHoursPage />
                </AdminRoute>
                <ProtectedRoute path="/profile" softphone>
                  <ProfilePage />
                </ProtectedRoute>
                <PublicRoute path="/registration">
                  <RegistrationPage />
                </PublicRoute>
                <PublicRoute path="/login">
                  <LoginPage />
                </PublicRoute>
                <PublicRoute>
                  <h1>404</h1>
                </PublicRoute>
              </Switch>
              <Softphone />
            </SetupWizardProvider>
          </BrowserRouter>
          <ToastContainer position="bottom-right" hideProgressBar={true} />
        </SoftphoneProvider>
      </UserContactsProvider>
    </>
  );
}

export default App;
