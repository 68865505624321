import SoftphoneOnCallDock from "./SoftphoneOnCallDock";
import SoftphoneRingingDock from "./SoftphoneRingingDock";
import SoftphoneInactiveDock from "./SoftphoneInactiveDock";
import { useEffect, useRef, useState } from "react";
import { useSoftphone } from "../../services/softphone/softphoneContext";

function SoftphoneDock({ className, children }) {
    const [styles, setStyles] = useState({});

    const ringingRef = useRef();
    const inactiveRef = useRef();
    const onCallRef = useRef();

    const softphone = useSoftphone();

    const width = "w-[15px] 2xl:w-[25px]";

    useEffect(() => {
        const styles = {
            "on-call": {
                style: {
                    height: onCallRef.current.offsetHeight + "px",
                },
                className: [width, "bg-green", "text-white"],
            },
            ringing: {
                style: {
                    height: ringingRef.current.offsetHeight + "px",
                },
                className: [width, "bg-yellow", "text-white"],
            },
            receiving: {
                style: {
                    height: ringingRef.current.offsetHeight + "px",
                },
                className: [width, "bg-yellow", "text-white"],
            },
            inactive: {
                style: {
                    height: inactiveRef.current.offsetHeight + "px",
                    paddingLeft: "20px",
                },
                className: [
                    "w-[20px] 2xl:w-[35px]",
                    "bg-white",
                    "text-primary",
                ],
            },
        };
        setStyles(styles);
    }, []);

    const defaultStyle = {
        boxSizing: "initial",
    };

    return (
        <div
            className={`p-3 py-3 2xl:py-4 drop-shadow-softphone text-white rounded-l-20 transition-softphoneDock duration-300 delay-150 ease-out ${styles[
                softphone.dockState
            ]?.className?.reduce((prev, curr) => prev + " " + curr)}`}
            style={{ ...defaultStyle, ...styles[softphone.dockState]?.style }}
        >
            <SoftphoneInactiveDock
                shown={softphone.dockState === "inactive"}
                ref={inactiveRef}
            />
            <SoftphoneRingingDock
                shown={
                    softphone.dockState === "ringing" ||
                    softphone.dockState === "receiving"
                }
                ref={ringingRef}
            />
            <SoftphoneOnCallDock
                shown={softphone.dockState === "on-call"}
                ref={onCallRef}
            />
        </div>
    );
}

export default SoftphoneDock;
