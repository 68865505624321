import { Link, useHistory } from "react-router-dom";
import {
  faHome,
  faAddressBook,
  faHistory,
  faChartPie,
  faBuilding,
  faCoins,
  faDesktop,
  faCog,
  faGripHorizontal,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import avatar from "../../images/default_avatar/5.png";
import Logo from "../layout/Logo";
import SidebarItem from "./SidebarItem";
import SidebarGroup from "./SidebarGroup";
import { useAuth } from "../../services/firebase/auth";
import SidebarProfile from "./SidebarProfile";
import { useEffect, useState } from "react";
import style from "./Sidebar.module.css";

function Sidebar({ onPageChange }) {
  const [username, setUsername] = useState("");
  const [extensionNumber, setExtensionNumber] = useState("");
  const auth = useAuth();

  const history = useHistory();

  const onClickHandler = (path) => {
    if (onPageChange) {
      onPageChange(path) && history.push(path);
    } else {
      history.push(path);
    }
  };

  useEffect(() => {
    setUsername(auth.user.displayName);
    setExtensionNumber(auth.user.token.claims.extensionNumber);
  }, [auth.user]);

  return (
    <div className={`bg-white-dark w-sm-sidebar 2xl:w-2xl-sidebar z-50`}>
      <div className="bg-primary h-full w-sm-sidebar pl-2 py-6 rounded-br-20 rounded-tr-20 flex flex-col fixed 2xl:w-2xl-sidebar 2xl:pl-4 2xl-py-6">
        <Link to="/" className="flex-0">
          <Logo className="m-auto mb-6 pr-4 2xl:pr-10 2xl:mb-8" />
        </Link>
        <SidebarProfile
          avatar={avatar}
          username={username}
          extension={extensionNumber}
          className="flex-1"
        ></SidebarProfile>
        <nav className={`"mt-4 h-full flex-0 overflow-y-auto ${style.scroll}`}>
          <SidebarItem
            icon={faGripHorizontal}
            title="Dashboard"
            linkTo="/"
            onClick={onClickHandler}
          />
          <SidebarItem
            icon={faAddressBook}
            title="Agenda do ramal"
            linkTo="/contacts"
            onClick={onClickHandler}
          />
          <SidebarItem
            icon={faHistory}
            title="Histórico de chamadas"
            linkTo="/callHistory"
            onClick={onClickHandler}
          />
          <SidebarItem
            icon={faChartPie}
            title="Relatório"
            linkTo="/report"
            onClick={onClickHandler}
            adminOnly
          />
          <SidebarItem
            icon={faBuilding}
            title="Minha empresa"
            linkTo="/company"
            onClick={onClickHandler}
            adminOnly
          />
          {/* <SidebarItem
              icon={faCoins}
              title="Financeiro"
              linkTo="/billing"
              onClick={onClickHandler}
              adminOnly
          /> */}
          <SidebarItem
            icon={faDesktop}
            title="Dispositivos"
            linkTo="/devices"
            onClick={onClickHandler}
          />
          <SidebarGroup icon={faCog} title="Configurações" adminOnly>
            <SidebarItem
              title="Ramais"
              linkTo="/extensions"
              onClick={onClickHandler}
              adminOnly
            />
            <SidebarItem
              title="Atendimento Automático"
              linkTo="/ivr"
              onClick={onClickHandler}
              adminOnly
            />
            {/* <SidebarItem
                title="Meus Números"
                linkTo="/numbers"
                onClick={onClickHandler}
                adminOnly
            /> */}
            <SidebarItem
              title="Horários de Atendimento"
              linkTo="/working-hours"
              onClick={onClickHandler}
              adminOnly
            />
          </SidebarGroup>
          <SidebarItem
            icon={faQuestionCircle}
            title="Ajuda"
            linkTo="/help"
            onClick={onClickHandler}
            adminOnly
          />
        </nav>
      </div>
    </div>
  );
}

export default Sidebar;
