import Card from "../../components/layout/Card";
import CardTitle from "../../components/layout/CardTitle";

export function SetupError(props: SetupErrorProps) {
  return (
    <div className="w-full max-w-[650px] m-auto">
      <Card className="w-full 2xl:p-12">
        <CardTitle className="text-2xl 2xl:text-3xl">OPS!</CardTitle>
        <span className="text-primary font-bold text-lg">
            Ocorreu um erro durante o seu processo de configuração.
        </span>
        <p className="text-gray-dark mt-4">
            Por favor entre em contato com o nosso atendimento.
        </p>
      </Card>
    </div>
  );
}

export type SetupErrorProps = {
};