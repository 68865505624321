import { useRef } from "react";

export class EventEmitter {
  events: { [id: string]: Event } = {};

  on(evtId: string, func: Listener): () => void {
    if (!this.events[evtId]) this.events[evtId] = [];
    this.events[evtId] = [...this.events[evtId], func];
    return () => this.unsubscribe(evtId, this.events[evtId].length - 1 || 0);
  }

  emit(evtId: string): Promise<unknown>[] {
    const listeners = this.events[evtId];
    if (Array.isArray(listeners) && listeners.length > 0) {
      const results = listeners.map((e) => e());
      return results;
    }

    return [];
  }

  emitSync(evtId: string): Promise<unknown[]> {
    const listeners = this.events[evtId];
    if (Array.isArray(listeners) && listeners.length > 0) {
      const promises = listeners.map(async (e) => await e());
      return Promise.all(promises);
    }

    return new Promise(() => []);
  }

  unsubscribe(evtId: string, index: number) {
    if (!this.events[evtId]) this.events[evtId] = [];
    if (index >= 0) {
      console.log("unsubscribing");
      this.events[evtId].splice(index, 1);
    }
  }

  print() {
    console.log(this.events);
  }
}

export function useEvent(): EventHook {
  const emitter = useRef(new EventEmitter());

  const on = (func: Listener) => {
    return emitter.current.on("event", func);
  };

  const emit = () => {
    return emitter.current.emit("event");
  };

  const emitSync = () => {
    return emitter.current.emitSync("event");
  };

  const print = () => {
    emitter.current.print();
  };

  return {
    on,
    emit,
    emitSync,
    print,
  };
}

export type Event = Listener[];

export type Listener = () => Promise<unknown>;

export type EventHook = {
  on: (func: Listener) => void;
  emit: () => Promise<unknown>[];
  emitSync: () => Promise<unknown[]>;
  print: () => void;
};
