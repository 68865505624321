import QuestionMarkTooltip from "./QuestionMarkTooltip";

function InputGroup(props: InputGroupProps) {
  const labelColor = props.error ? "text-red/60" : "text-gray-dark";

  return (
    <div className={`inline-block ${props.className}`}>
      <div className="flex justify-between">
        {props.title && (
          <div className="block">
            <label
              htmlFor={props.inputId}
              className={`cursor-pointer font-bold text-xs 2xl:text-sm ${labelColor}`}
            >
              {props.title}
            </label>
            {props.tooltipMessageId && (
              <QuestionMarkTooltip
                className="text-sm"
                messageId={props.tooltipMessageId}
              />
            )}
          </div>
        )}
        {props.error && <span className="text-xs text-red">{props.error}</span>}
        {!props.error && props.info && (
          <span className="text-xs text-gray-dark">{props.info}</span>
        )}
      </div>
      {props.children}
    </div>
  );
}

export default InputGroup;
export type InputGroupProps = {
  title: string;
  children: React.ReactNode;
  inputId?: string;
  error?: string;
  info?: string;
  tooltipMessageId?: string;
  className?: string;
};
