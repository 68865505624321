import { useEffect, useRef, useState } from "react";
import { useContacts } from "../../services/UserContactsContext";
import { useSoftphone } from "../../services/softphone/softphoneContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import SoftphoneTransferItem from "./SoftphoneTransferItem";
import { useAuth } from "../../services/firebase/auth";
import TextInput from "../layout/TextInput";

export default function SoftphoneTransferSelection() {
  const userContacts = useContacts();
  const [extensions, setExtensions] = useState([]);
  const [filteredExtensions, setFilteredExtensions] = useState([]);
  const [selected, setSelected] = useState("");
  const [filter, setFilter] = useState("");
  const softphone = useSoftphone();

  const inputRef = useRef();

  const auth = useAuth();

  const onClick = (extensionId) => {
    if (extensionId !== selected) {
      setSelected(extensionId);
    }
  };

  const cancel = () => {
    setSelected(null);
  };

  const transfer = (extensionId) => {
    const extension = extensions.find((v) => v.extensionId === extensionId);
    if (extension) {
      setSelected(null);
      softphone.transferTo(extension.phone);
      softphone.setTransferShown(false);
    }
  };

  useEffect(() => {
    const filtered =
      extensions.filter(
        (v) =>
          v.name.toLowerCase().includes(filter.toLocaleLowerCase()) ||
          v.phone.toString().includes(filter)
      ) || [];

    setFilteredExtensions(filtered);
  }, [filter, extensions]);

  useEffect(() => {
    let ext = userContacts.contacts.filter((v) => v.extensionId) || [];
    ext = ext.filter(
      (v) =>
        v.phone.toString() !== auth.user.token.claims.extensionNumber.toString()
    );
    setExtensions(ext);
  }, [userContacts, auth.user]);

  useEffect(() => {
    setFilter("");
    if (softphone.transferShown) {
      const el = document.getElementById("transfer-search");
      if (el) el.focus();
    }
  }, [softphone.transferShown]);

  return (
    <div>
      <div className="text-sm text-gray-dark font-bold pb-2">
        <span>Transferir para:</span>
        <span
          className="text-gray-dark cursor-pointer float-right text-base"
          onClick={() => softphone.setTransferShown(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
      <div className="mb-2 relative">
        <TextInput
          value={filter}
          onChange={setFilter}
          setter={setFilter}
          id="transfer-search"
        />
        <div className="absolute right-3 top-1 text-gray-dark">
          <FontAwesomeIcon icon={faSearch} size="sm" />
        </div>
      </div>
      <div className="flex flex-col bg-white border-gray-bluish border-2 rounded-15 overflow-auto h-[280px] 2xl:h-[360px] p-[2px]">
        {filteredExtensions &&
          filteredExtensions.map((v, i) => (
            <SoftphoneTransferItem
              key={i}
              extension={v}
              select={onClick}
              accepted={transfer}
              cancelled={cancel}
              isSelected={selected === v.extensionId}
            />
          ))}
      </div>
    </div>
  );
}
