import { httpsCallable } from "firebase/functions";
import { functions } from "../services/firebase/firebaseInit";
import { Extension } from "./extension.model";
import {
  AudioMessage,
  AudioMessagesMetadata,
  IvrType,
  Participant,
  Queue,
} from "./queue.model";

// export async function saveSetupWizardConfig(config: SetupWizardConfig) {
//   const fn = httpsCallable(functions, "saveSetupWizardConfig");
//   const result = await fn(config);
//   return result;
// }

export async function saveSetupWizardConfig(
  config: SetupWizardConfig,
  companyId: string
) {
  const fn = httpsCallable(functions, "saveSetupWizardConfig");
  const result = await fn(config);
  const fnRegen = httpsCallable(functions, "regenCompanyAstConfig");
  const resultRegen = await fnRegen({ companyId });
  return result;
}

export const previewTTS = async (
  audio: AudioMessage,
  queues: WizardQueue[]
): Promise<string> => {
  if (audio?.type !== "predefined" && audio?.type !== "text")
    throw new Error("Cannot generate temp TTS for an audio file");

  const tempTTS = httpsCallable(functions, "wizardTempTTS");
  const result = await tempTTS({
    type: audio?.type,
    generateOptions: audio?.useOptions,
    text: audio?.text,
    queues,
  });
  if (result) {
    return result.data as string;
  } else {
    throw new Error("");
  }
};

export type SetupWizardAccountMetadata = {
  usersLimit: number;
  recordingLimit: number;
  finalizedSetupWizard: boolean;
};

export type SetupWizardPayload = {
  account: WizardAccount;
  extensions: WizardExtension[];
  queues: WizardQueue[];
  ivrData: WizardIvrData;
  messages: Partial<AudioMessagesMetadata>;
  terms: WizardTerms
};

export type SetupWizardConfig = Partial<SetupWizardPayload>;

export type WizardAccount = {
  name: string;
  fantasyName?: string | undefined;
};

export type WizardIvrData = {
  type: IvrType;
  singleQueueId: string | null;
  singleQueueTempId: string | null;
};

export type WizardExtension = Omit<Extension, "id"> & {
  id: string | null;
  tempId: string | null;
};

export type WizardQueue = Omit<Omit<Queue, "participants">, "id"> & {
  id: string | null;
  participants: WizardParticipant[];
  tempId: string | null;
};

export type WizardParticipant = Omit<Participant, "id"> & {
  id: string | null;
  tempId: string | null;
  label: string;
  value: string;
};

export type WizardTerms = {
  termsAceppted: boolean | undefined;
  termsAcepptedDate: Date | undefined;
}
