import React, { Dispatch, SetStateAction, useEffect } from "react";
import Select from "../layout/Select";
import { TextTTSPreview } from "./TextTTSPreview";
import { AudioMessage } from "../../model/queue.model";

export function PredefinedMessageSelection(
  props: PredefinedMessageSelectionProps
) {
  const value =
    props.message && props.message.type === "predefined"
      ? props.message.predefined
      : 0;

  const set = (id: string) => {
    props.setMessage((v) => ({
      type: "predefined",
      id: v!.id,
      predefined: parseInt(id),
      text: props.options[parseInt(id)],
      useOptions: v!.useOptions,
    }));
  };

  return (
    <div className="flex items-end">
      <Select
        title="Mensagem"
        value={value}
        setter={set}
        //@ts-ignore
        onChange={() => props.onChange}
        className="w-full max-w-[600px]"
        formValidation={props.formValidation}
        required
      >
        {props.options &&
          props.options.map((v, i) => (
            <option key={i} value={i}>
              {v}
            </option>
          ))}
      </Select>
      {props.message?.type === "predefined" && (
        <TextTTSPreview className="ml-4" message={props.message} />
      )}
    </div>
  );
}

export type PredefinedMessageSelectionProps = {
  options: string[];
  message: AudioMessage;
  setMessage: Dispatch<SetStateAction<AudioMessage>>;
  formValidation: any;
  onChange?: () => void;
};
