import { createContext, useContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithCustomToken,
} from "firebase/auth";
import { auth, functions } from "./firebaseInit";
import { httpsCallable } from "@firebase/functions";

const authContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const registerUser = async (
    name,
    email,
    password,
    companyName,
    extensionNumber
  ) => {
    const registerUser = httpsCallable(functions, "registerUser");
    await registerUser({
      email,
      password,
      name,
      companyName,
      extensionNumber,
    });
  };

  const loginUser = async (email, password) => {
    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await userCredentials.user.getIdTokenResult();
      setUser({ ...userCredentials.user, token });
      return userCredentials.user;
    } catch (e) {
      if (
        e.code === "auth/user-not-found" ||
        e.code === "auth/wrong-password"
      ) {
        return { error: true, code: 1, message: "Dados incorretos." };
      } else {
        return {
          error: true,
          code: 0,
          message: "Ocorreu um erro, tente novamente.",
        };
      }
    }
  };

  const tokenLogin = async (loginToken) => {
    const userCredentials = await signInWithCustomToken(auth, loginToken);
    const token = await userCredentials.user.getIdTokenResult();
    setUser({ ...userCredentials.user, token });
    return userCredentials.user;
  };

  const logoutUser = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch ({ code }) {
      console.log("occoreu erro ao deslogar");
      console.log(code);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdTokenResult().then((token) => {
          setUser({ ...user, token });
          setIsAuthenticating(false);
        });
      } else {
        setUser(undefined);
        setIsAuthenticating(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const element = document.getElementById("tiflux--chat");
    if(!element) return;

    const addParamSrc = function getUrlParameter(param, content) {
      const src = document.getElementsByClassName("chat__form")[0].firstChild.getAttribute('src');
      let sPageURL = src,
        sURLVariables = sPageURL.split("&"),
        sParameterName,
        i;

      let newSrc = '';
      for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split("=");
        if (sParameterName[0] === param) {
          const paramContent = content;
          sParameterName[1] = encodeURIComponent(JSON.stringify(paramContent));
          sURLVariables[i] = sParameterName.join("=");
        } 
      }

      newSrc = sURLVariables.join("&");
      return newSrc;
    };

    if(!user?.token?.claims.admin) {
      element.style.visibility = "hidden";
    } else {
      element.style.visibility = "visible";
  
      let extra_params;
      if (user) extra_params = { vokiId: user?.token?.claims?.company }
      const newSrc = addParamSrc('extra_params', extra_params);
      document.getElementsByClassName("chat__form")[0].firstChild.setAttribute('src', newSrc);
    }
  }, [user])

  const contextValues = {
    user,
    registerUser,
    loginUser,
    tokenLogin,
    logoutUser,
  };

  return (
    <authContext.Provider value={contextValues}>
      {!isAuthenticating && children}
    </authContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(authContext);
};
