import QueueModal from "./QueueModal";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EditQueueModal({ queue, onSave, onDelete, ...props }) {
    const onSaveHandler = async (q) => {
        const data = {
            ...queue,
            name: q.name,
            option: q.option,
            strategy: q.strategy,
            participants: q.participants.map((v) => {
                const el = q.extensions.find((a) => v.value === a.value);
                return {
                    id: v.value,
                    name: el.name,
                    extensionNumber: el.extensionNumber,
                };
            }),
        };
        onSave && onSave(data);
    };

    const onDeleteHandler = (id) => {
        onDelete && onDelete(id);
    };

    return (
        <QueueModal
            queueId={queue.id}
            data={queue}
            onSave={onSaveHandler}
            onDelete={onDeleteHandler}
            {...props}
        >
            {queue.participants && queue.participants.length === 0 && (
                <div className="border-gray border-2 py-4 px-4 rounded-10 text-sm text-gray-dark font-medium flex justify-between items-center">
                    <div>
                        <FontAwesomeIcon
                            icon={faExclamationCircle}
                            className="text-gray-dark text-[40px] mr-4"
                        />
                    </div>
                    <div>
                        Filas sem participantes não irão funcionar corretamente.
                        Por favor adicione um participante ou remova esta fila.
                    </div>
                </div>
            )}
        </QueueModal>
    );
}

export default EditQueueModal;
