import Card from "../../components/layout/Card";
import CardTitle from "../../components/layout/CardTitle";
import { SetupWizardControls } from "../../components/setupWizard/SetupWizardControls";

export function SetupStartPage(props: SetupStartPageProps) {
  return (
    <>
    <SetupWizardControls next={props.next} />
    <Card className="w-full 2xl:p-12 p-10">
      <CardTitle>Início</CardTitle>
      <p className="text-gray-dark">
        Aqui você poderá realizar rapidamente todo o processo básico de cadastro
        dos dados de sua empresa.
      </p>
    </Card>
    </>
  );
}

export type SetupStartPageProps = {
  next: () => void;
  back: () => void;
};
