import TableLayout from "../TableLayout/TableLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { formatISODate } from "../../utils/formatting";

function EventsTable({ data, onDeleteEvent, onEditEvent }) {
    const headers = [
        "Nome",
        "Data",
        "Período",
        "Status",
        { title: "", className: "w-20" },
    ];

    const rowTransform = (row) => {
        return [
            <strong>{row.name}</strong>,
            formatISODate(row.date),
            row.hourRange !== "*"
                ? row.hourRange[0] + " - " + row.hourRange[1]
                : "dia inteiro",
            row.status ? "Ativo" : "Inativo",
            <div className="flex">
                <div
                    className="cursor-pointer text-gray-dark mr-4 2xl:mr-6 text-lg"
                    onClick={() => onEditEvent && onEditEvent(row)}
                >
                    <FontAwesomeIcon
                        icon={faPencilAlt}
                        className="text-lg 2xl:text-2xl"
                    />
                </div>
                <div
                    className="cursor-pointer text-red text-lg"
                    onClick={() => onDeleteEvent && onDeleteEvent(row)}
                >
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-lg 2xl:text-2xl"
                    />
                </div>
            </div>,
        ];
    };

    return (
        <TableLayout
            data={data}
            headers={headers}
            rowTransform={rowTransform}
        />
    );
}

export default EventsTable;
