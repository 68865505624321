import { twMerge } from "tailwind-merge";
import Modal from "../layout/Modal";
import {
  SetupWizardConfig,
  WizardParticipant,
  WizardQueue,
} from "../../model/setupWizard.model";
import { Setter } from "../../utils/types";
import { useEffect, useState } from "react";
import TextInput from "../layout/TextInput";
import useFormValidation from "../../hooks/formValidation";
import Button from "../layout/Button";
import { QueueStrategy } from "../../model/queue.model";
import IntegerInput from "../layout/IntegerInput";
import Select from "../layout/Select";
import MultiSelect from "../layout/MultiSelect";
import { uniqueId } from "lodash";

export default function SetupWizardQueueModal(
  props: SetupWizardQueueModalProps
) {
  const defaultClasses = "min-w-[300px] 2xl:min-w-[400px]";
  const classes = twMerge(defaultClasses, props.className);

  const [name, setName] = useState("");
  const [option, setOption] = useState<string>("");
  const [strategy, setStrategy] = useState<QueueStrategy | null>(null);
  const [extensionsOptions, setExtensionsOptions] = useState<
    WizardParticipant[]
  >([]);
  const [participants, setParticipants] = useState<WizardParticipant[]>([]);
  const [error, setError] = useState("");

  const formValidation = useFormValidation();

  const isEdit = props.queueIndex !== null;

  const saveHandler = () => {
    if (formValidation.validateForm()) {
      const newQueue: WizardQueue = {
        id: null,
        tempId: uniqueId("temp-"),
        name,
        option: parseInt(option!),
        strategy: strategy || "rrmemory",
        participants,
      };
      if (!isEdit) {
        props.setter((v) => ({
          ...v,
          queues: [...(v.queues || []), newQueue],
        }));
      } else {
        props.setter((v) => {
          const newQueues = v?.queues || [];
          newQueues[props.queueIndex || 0] = newQueue;
          return { ...v, queues: newQueues };
        });
      }
      props.onSave && props.onSave();
    }
  };

  const fill = (queue: WizardQueue | null) => {
    setName(queue?.name || "");
    setOption(queue?.option.toString() || "");
    setStrategy(queue?.strategy || "rrmemory");
    setParticipants(
      queue?.participants.map((v) => ({
        ...v,
      })) || []
    );
    const exts = props.value.extensions || [];
    const options = exts.map((v, i) => ({
      label: v.name + " | " + v.extensionNumber,
      value: v.id! || v.tempId!,
      extensionNumber: v.extensionNumber,
      name: v.name,
      id: v.id || null,
      tempId: v.tempId,
      index: i,
    }));
    setExtensionsOptions(options);
    setError("");
  };

  const optionValidation = (option: string) => {
    if (props.value?.queues?.length) {
      const { queues } = props.value;
      const queueAlreadyUsed = queues.find(
        (e) => e.option === parseInt(option)
      );

      if (queueAlreadyUsed) return { error: true, message: "Já utilizado" };
    }

    return { error: false };
  };

  useEffect(() => {
    if (props.queueIndex !== null) {
      const queue = props.value.queues![props.queueIndex];
      fill(queue);
    } else {
      fill(null);
    }
  }, [props.shown]);

  return (
    <Modal
      shown={props.shown}
      onClose={props.onClose}
      title="Editar Fila"
      className={classes}
    >
      <div className="grid grid-cols-1 gap-4 mb-6">
        <IntegerInput
          title="Opção digitável"
          value={option}
          setter={setOption}
          formValidation={formValidation}
          validation={optionValidation}
          required
        />
        <TextInput
          title="Nome da fila"
          value={name}
          setter={setName}
          formValidation={formValidation}
          required
        />
        <Select
          title="Estratégia de fila"
          value={strategy}
          setter={setStrategy}
          //@ts-ignore
          formValidation={formValidation}
          tooltipMessageId="queue-strategy"
          required
        >
          <option value="rrmemory">Rotacionado</option>
          <option value="linear">Linear</option>
        </Select>
        <MultiSelect
          options={extensionsOptions}
          title="Participantes"
          formValidation={formValidation}
          value={participants}
          setter={setParticipants}
          required
        />
      </div>
      <div className="">
        {props.onDelete && (
          <Button
            color="red"
            className="float-left"
            onClick={() => props.onDelete(props.queueIndex)}
            outline
          >
            Deletar
          </Button>
        )}
        <div>{error && <span className="text-red  text-sm">{error}</span>}</div>
        <Button color="purple" className="float-right" onClick={saveHandler}>
          Salvar
        </Button>
      </div>
    </Modal>
  );
}

export type SetupWizardQueueModalProps = {
  shown: boolean;
  onClose: () => void;
  value: SetupWizardConfig;
  setter: Setter<SetupWizardConfig>;
  queueIndex: number | null;
  onSave?: () => void;
  onDelete: (index: number | null) => void;
  className?: string;
};
