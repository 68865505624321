import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageTitle from "../components/layout/PageTitle";

function BillingPage() {
  return (
    <SidebarLayout>
      <PageTitle>Financeiro</PageTitle>
    </SidebarLayout>
  );
}

export default BillingPage;
