import React, { Dispatch, SetStateAction, useEffect } from "react";
import Select from "../layout/Select";
import TextArea from "../layout/TextArea";
import { TextTTSPreview } from "./TextTTSPreview";
import { AudioMessage } from "../../model/queue.model";

export function TextMessageSelection(props: TextMessageSelectionProps) {
  const value =
    props.message && props.message.type === "text" ? props.message.text : "";

  const set = (text: string) => {
    props.setMessage((v) => ({
      type: "text",
      id: v!.id,
      text,
      useOptions: v!.useOptions,
    }));
  };

  return (
    <div className="flex items-start">
      <TextArea
        title="Texto da mensagem"
        value={value}
        setter={set}
        //@ts-ignore
        onChange={() => props.onChange}
        charLimit={350}
        className="w-full max-w-[550px]"
        formValidation={props.formValidation}
        required
      />
      {props.message?.type === "text" && (
        <TextTTSPreview className="ml-4 mt-6" message={props.message} />
      )}
    </div>
  );
}

export type TextMessageSelectionProps = {
  message: AudioMessage;
  setMessage: Dispatch<SetStateAction<AudioMessage>>;
  formValidation: any;
  onChange?: () => void;
};
