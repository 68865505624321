function FadeInOut(props: FadeInOutProps) {
  return (
    <>
      {props.options &&
        props.options.map((option, i) => {
          const isActive = props.selector && props.selector(option, i) === i;
          return (
            <div
              key={i}
              className={` ${
                isActive ? "animate-fadeIn" : "animate-fadeOut opacity-0 hidden"
              } ${props.className || ""}`}
            >
              {option}
            </div>
          );
        })}
    </>
  );
}

export default FadeInOut;
export type FadeInOutProps = {
  selector: (option: React.ReactNode, index: number) => number;
  options: React.ReactNode[];
  className?: string;
};
