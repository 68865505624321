import TableLayout from "../TableLayout/TableLayout";
import { formatISODateTime } from "../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUserPlus,
    faPhone,
    faLocationArrow,
    faVoicemail,
} from "@fortawesome/free-solid-svg-icons";
import { formatPhoneOrExt, test } from "../../utils/formatting";

function CallHistoryTable({
    data,
    addContact,
    initiateCall,
    downloadRecording,
    extenData,
    contacts
}) {
    const headers = [
        { title: "", className: "w-10" },
        "Nome",
        "Telefone",
        "Data",
        "Duração",
        { title: "Status", className: "w-32" },
        { title: "", className: "w-32" },
    ];

    const statusMissed = (
        <div className="py-1.5 px-3 2xl:px-6 2xl:py-2 border-red border-2 text-red font-bold rounded-15 text-center">
            Perdido
        </div>
    );
    const statusAnswered = (
        <div className="py-1.5 px-3 2xl:px-6 2xl:py-2 border-green border-2 text-green font-bold rounded-15 text-center">
            Atendido
        </div>
    );

    const downloadPermission = extenData.downloadRecording

    const inbound = (item) => {
        return (
            <FontAwesomeIcon
                icon={faLocationArrow}
                rotation={180}
                className={`${
                    item.status === "missed" ? "text-red" : "text-green"
                } text-lg 2xl:text-2xl`}
                title="Chamada recebida"
            />
        );
    };
    const outbound = (item) => {
        return (
            <FontAwesomeIcon
                icon={faLocationArrow}
                className={`${
                    item.status === "missed" ? "text-red" : "text-green"
                } text-lg 2xl:text-2xl`}
                title="Chamada efetuada"
            />
        );
    };

    const contactsData = contacts.getAll();

    const rowTrasform = (item) => {
        let phone =
            item.phone.substr(0, 2) == "55" ? item.phone.substr(2) : item.phone;

        phone = formatPhoneOrExt(phone);

        return [
            item.direction === "outbound" ? outbound(item) : inbound(item),
            <span className="font-bold">
                {item.contactName || contactsData?.find(_contact => _contact.phone === Number(item.phone))?.name || "Desconhecido"}
            </span>,
            phone,
            formatISODateTime(item.date),
            item.duration,
            item.status === "missed" ? statusMissed : statusAnswered,
            <div className="flex pr-4">
                <div className="mr-8 flex-1">
                    <div>
                    {!item.contactName ? (
                        <div
                            className="cursor-pointer text-primary text-lg"
                            onClick={
                                addContact &&
                                (() =>
                                    addContact &&
                                    addContact({
                                        phone: item.phone,
                                    }))
                            }
                        >
                            <FontAwesomeIcon
                                icon={faUserPlus}
                                className="text-lg 2xl:text-2xl"
                            />
                        </div>
                    ) : <></>}
                    </div>
                </div>
                <div>
                   {(item.recorded && downloadPermission) ? (
                        <div
                            className="cursor-pointer text-gray-dark mr-8 text-lg"
                            onClick={() =>
                                downloadRecording(item.callId, item.date)
                            }
                        >
                            <FontAwesomeIcon
                                icon={faVoicemail}
                                className="text-lg 2xl:text-2xl"
                            />
                        </div>
                    ) : <></>}
                </div>
                <div>
                    <div
                        className="cursor-pointer text-green text-lg flex-1"
                        onClick={() =>
                            initiateCall(item.phone, item.contactName)
                        }
                    >
                        <FontAwesomeIcon
                            icon={faPhone}
                            className="text-lg 2xl:text-2xl"
                        />
                    </div>
                </div>
            </div>,
        ];
    };

    return (
        <TableLayout data={data} downloadPermission={downloadPermission} rowTransform={rowTrasform} headers={headers} />
    );
}

export default CallHistoryTable;
