import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import QuestionMarkTooltip from "./QuestionMarkTooltip";

function ModalHeader({ onClose, tooltipMessageId, children }) {
    return (
        <div className="mb-4">
            <div className="flex justify-between items-center">
                <div>
                    <h2 className="text-primary inline font-bold text-base 2xl:text-lg">
                        {children}
                    </h2>
                    {tooltipMessageId && (
                        <QuestionMarkTooltip messageId={tooltipMessageId} />
                    )}
                </div>
                <span className="text-primary cursor-pointer" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </span>
            </div>
            <hr className="border-t-gray/30 border-t-2 clear-both mt-3"></hr>
        </div>
    );
}

export default ModalHeader;
