import { useCallback, useEffect, useState } from "react";
import Switch from "../layout/Switch";
import HoursSelect from "../layout/HoursSelect";
import _ from "lodash";
import HourInput from "../layout/HourInput";

function HoursInputGroup({ children, value, setValue, formValidation }) {
    const [id] = useState(_.uniqueId("group-"));
    const [isActive, setIsActive] = useState(value.from || value.to);

    const [from, setFrom] = useState(value.from);
    const [to, setTo] = useState(value.to);

    const [error, setError] = useState(false);

    const switchHandler = (val) => {
        setIsActive(val);
        if (!val) {
            setValue({ from: "", to: "" });
            setError(false);
        }
    };

    const fromTimeHandler = (val) => {
        setValue({ ...value, from: val });
        setError(false);
        setFrom(val);
    };

    const toTimeHandler = (val) => {
        setValue({ ...value, to: val });
        setError(false);
        setTo(val);
    };

    const validate = useCallback(() => {
        if ((isActive && (!from || !to)) || from > to) {
            setError(true);
            return false;
        }
        setError(false);
        return true;
    }, [isActive, from, to]);

    useEffect(() => {
        if (value) {
            setIsActive(value.from ? true : false);
            setFrom(value.from);
            setTo(value.to);
        }
    }, [value]);

    useEffect(() => {
        if (formValidation) {
            formValidation.registerValidationCallback({ id, validate });
            return () => formValidation.unregisterValidationCallback(id);
        }
    }, [id, validate, formValidation]);

    return (
        <div className="mb-4 text-primary font-medium grid grid-cols-4 items-center">
            <div className="col-span-1 text-xs 2xl:text-base">{children}</div>
            <div className="mt-2 2xl:mt-1">
                <Switch
                    titleActive="Aberto"
                    titleInactive="Fechado"
                    className="col-span-1"
                    value={isActive}
                    onChange={switchHandler}
                />
            </div>
            <div className="col-span-2 flex items-center">
                <HourInput
                    className="w-full"
                    disabled={!isActive}
                    value={from}
                    setter={fromTimeHandler}
                    error={error}
                />
                <span className="mx-4 text-xs 2xl:text-base">até</span>
                <HourInput
                    className="w-full"
                    disabled={!isActive}
                    value={to}
                    setter={toTimeHandler}
                    error={error}
                />
            </div>
        </div>
    );
}

export default HoursInputGroup;
