import TableLayout from "../TableLayout/TableLayout";
import { formatPhoneOrExt } from "../../utils/formatting";

function NumbersTable({ data }) {
    const headers = [
        { title: "", className: "w-24" },
        "Telefone",
        { title: "Status", className: "w-32" },
        { title: "", className: "w-24" },
    ];

    const getStatus = (status) => {
        switch (status) {
            case "main":
                return (
                    <div className="px-6 py-2 border-green border-2 text-green font-bold rounded-15 text-center">
                        Principal
                    </div>
                );
            default:
                return <></>;
        }
    };

    const rowTrasform = (item) => {
        return [
            "",
            <span className="font-bold">
                {formatPhoneOrExt(item.fullPhone)}
            </span>,
            getStatus(item.status),
            "",
        ];
    };

    return (
        <TableLayout data={data} rowTransform={rowTrasform} headers={headers} />
    );
}

export default NumbersTable;
