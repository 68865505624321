import { useState, useEffect, useCallback } from "react";
import { useAuth } from "../../services/firebase/auth";
import { getQueues, saveQueues } from "../../model/queue.model";
import PageTitle from "../layout/PageTitle";
import CreateQueueModal from "./CreateQueueModal";
import EditQueueModal from "./EditQueueModal";
import IvrCardsGrid from "./IvrCardsGrid";

function QueuesConfig({ ivrHook }) {
  const { queues, setQueues } = ivrHook;
  const { setHasUnsavedChanges, saveEvent } = ivrHook;
  const [showCreateQueue, setShowCreateQueue] = useState(false);
  const [editQueue, setEditQueue] = useState(false);

  const auth = useAuth();

  const onSave = useCallback(async () => {
    // await saveQueues(auth, queues);
    await load();
  }, [auth, queues]);

  const load = useCallback(async () => {
    ivrHook.setIsLoading(false);
    setHasUnsavedChanges(false);
  }, [auth]);

  const onCreateHandler = (data) => {
    setQueues((q) => [...q, data]);
    setShowCreateQueue(false);
    setHasUnsavedChanges(true);
  };

  const onUpdateHandler = (data) => {
    setQueues((q) => {
      const newQueues = [
        ...q.filter((v) => {
          if (v.id) {
            return v.id !== data.id;
          } else if (v.tempId) {
            return v.tempId !== data.tempId;
          } else {
            return "";
          }
        }),
        data,
      ];
      return newQueues.sort((prev, curr) => {
        return prev.option - curr.option;
      });
    });
    setHasUnsavedChanges(true);
    setEditQueue(false);
  };

  const onDeleteHandler = (id) => {
    setQueues((q) => {
      return q
        .filter((v) => v.id !== id)
        .sort((prev, curr) => {
          return prev.option - curr.option;
        })
        .map((v, i) => ({ ...v, option: i + 1 }));
    });

    setHasUnsavedChanges(true);
    setEditQueue(false);
  };

  useEffect(() => {
    if (saveEvent) {
      const unsubscribe = saveEvent.on(onSave);
      return () => unsubscribe();
    }
  }, [saveEvent, queues]);

  useEffect(() => {
    setQueues(ivrHook.queues);
  }, [ivrHook.queues]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <>
      <PageTitle tooltipMessageId="ivr-queues">Suas Filas</PageTitle>
      <IvrCardsGrid
        queues={queues}
        onCreate={() => setShowCreateQueue(true)}
        onEdit={(id) => {
          setEditQueue(
            queues && queues.filter((v) => v.id === id || v.tempId === id)[0]
          );
        }}
      />
      <CreateQueueModal
        shown={showCreateQueue}
        onClose={() => setShowCreateQueue(false)}
        onCreate={onCreateHandler}
        queues={queues}
      />
      <EditQueueModal
        shown={editQueue}
        queue={editQueue}
        onClose={() => setEditQueue(false)}
        onSave={onUpdateHandler}
        onDelete={onDeleteHandler}
        queues={queues}
      />
    </>
  );
}

export default QueuesConfig;
