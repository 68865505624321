import {
  faExchangeAlt,
  faLevelUpAlt,
  faPause,
  faPlay,
  faExclamationCircle,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef } from "react";
import { useSoftphone } from "../../services/softphone/softphoneContext";
import FadeInOut from "../layout/FadeInOut";
import SoftphoneBottomDock from "./SoftphoneBottomDock";
import SoftphoneDisplay from "./SoftphoneDisplay";
import SoftphoneKeypad from "./SoftphoneKeypad";
import SoftphoneSpinner from "./SoftphoneSpinner";
import SoftphoneTransferSelection from "./SoftphoneTransferSelection";

function SoftphoneContainer({ setKeypadWidth }) {
  const widthRef = useRef();

  const softphone = useSoftphone();

  const onClick = (e) => {
    if (softphone.callState === "inactive") {
      e.preventDefault();
      softphone.focusDisplay();
    }
  };

  useEffect(() => {
    setKeypadWidth(widthRef.current.offsetWidth);
  }, [setKeypadWidth]);

  useEffect(() => {
    const obs = new ResizeObserver(() =>
      setKeypadWidth(widthRef.current ? widthRef.current.offsetWidth : 1000)
    ).observe(widthRef.current);
    return () => obs && obs.disconnect();
  }, [setKeypadWidth]);

  return (
    <div className="drop-shadow-softphone w-[250px] 2xl:w-[300px]">
      <div
        className=" top-0 left-full p-6 2xl:p-8 bg-white-mid  rounded-tl-20"
        ref={widthRef}
        onPointerDown={onClick}
      >
        {softphone.isLoading && (
          <div className="px-8 py-44">
            <SoftphoneSpinner size="100px" />
          </div>
        )}
        {softphone.isError && (
          <div className="px-8 py-32">
            <div className="flex justify-center mb-4">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                className="text-gray-dark text-7xl"
              />
            </div>
            <div className="font-bold text-gray-dark text-lg">
              {softphone.isError}
            </div>
          </div>
        )}
        {softphone.isLoaded && (
          <>
            <SoftphoneDisplay />
            <FadeInOut
              className={"block"}
              selector={() => {
                return softphone.transferShown ? 0 : 1;
              }}
              options={[<SoftphoneTransferSelection />, <SoftphoneKeypad />]}
            />
          </>
        )}
      </div>
      <div className="bg-white h-10 2xl:h-12 rounded-bl-20 drop-shadow-soft relative">
        <FadeInOut
          className="w-full h-full"
          selector={() => {
            switch (softphone.callState) {
              case "inactive":
                return 0;
              case "ringing":
              case "receiving":
                return 1;
              case "on-call":
                return 2;
              default:
                return 0;
            }
          }}
          options={[
            <SoftphoneBottomDock className="bg-white text-gray/80 justify-between">
              <FontAwesomeIcon
                icon={faLevelUpAlt}
                className="text-xl 2xl:text-2xl cursor-pointer text-gray-dark"
                onClick={softphone.pickupFromGroup}
              />
              <a
                href="https://manual.voki.net.br/docs/chamadas/softphone"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="text-xl 2xl:text-2xl cursor-pointer"
                />
              </a>
            </SoftphoneBottomDock>,
            <SoftphoneBottomDock className="bg-yellow text-white justify-end">
              <a
                href="https://manual.voki.net.br/docs/chamadas/softphone"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="text-xl 2xl:text-2xl cursor-pointer"
                />
              </a>
            </SoftphoneBottomDock>,

            <SoftphoneBottomDock className="bg-green text-white justify-between gap-4">
              <FontAwesomeIcon
                icon={faExchangeAlt}
                className="text-lg 2xl:text-2xl cursor-pointer"
                onClick={() => softphone.toggleTransferShown()}
              />
              <div className="relative flex justify-center items-center">
                <FadeInOut
                  selector={() => (softphone.onHold ? 1 : 0)}
                  options={[
                    <FontAwesomeIcon
                      icon={faPause}
                      className="text-lg 2xl:text-2xl cursor-pointer"
                      onClick={softphone.toggleHold}
                    />,
                    <FontAwesomeIcon
                      icon={faPlay}
                      className="text-lg 2xl:text-2xl cursor-pointer"
                      onClick={softphone.toggleHold}
                    />,
                  ]}
                />
              </div>
              <a
                href="https://manual.voki.net.br/docs/chamadas/softphone"
                target="_blank"
              >
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  className="text-xl 2xl:text-2xl cursor-pointer"
                />
              </a>
            </SoftphoneBottomDock>,
          ]}
        />
      </div>
    </div>
  );
}

export default SoftphoneContainer;
