import { Redirect } from "react-router-dom";
import { Route } from "./Route";
import { useAuth } from "../../services/firebase/auth";

export default function ProtectedRoute(props) {
    const auth = useAuth();

    return (
        <Route
            render={() =>
                auth.user ? props.children : <Redirect to="/login" />
            }
            softphone={props.softphone}
        />
    );
}
