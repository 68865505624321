import { useState, useEffect, useCallback } from "react";
import SidebarLayout from "../components/sidebar/SidebarLayout";
import ContentWrapper from "../components/layout/ContentWrapper";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import PageTitle from "../components/layout/PageTitle";
import Button from "../components/layout/Button";
import ConfirmModal from "../components/ivrs/ConfirmModal";
import { useHistory } from "react-router-dom";
// import MessagesConfig from "../components/ivrs/MessagesConfig";
import QueuesConfig from "../components/ivrs/QueuesConfig";
import { useEvent } from "../utils/eventEmitter";
//@ts-ignore
import Card from "../components/layout/Card";
import Select from "../components/layout/Select";
import useIvrPageHook from "../hooks/IvrPageHook";
import MessagesConfig from "../components/ivrs/MessagesConfig";

function IvrPage() {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [navigateTo, setNavigateTo] = useState("");

  const onPageChangeEvent = useEvent();

  const history = useHistory();
  const ivr = useIvrPageHook();

  const onPageChange = (destination: string) => {
    if (ivr.hasUnsavedChanges) {
      setShowConfirmModal(true);
      setNavigateTo(destination);
      return false;
    } else {
      return true;
    }
  };

  const onConfirm = () => {
    history.push(navigateTo);
    setNavigateTo("");
  };

  useEffect(() => {
    ivr.load();
  }, []);

  return (
    <SidebarLayout onPageChange={onPageChange}>
      <PageLoadSpinner show={ivr.isLoading} />
      <ContentWrapper show={!ivr.isLoading}>
        <PageTitle tooltipMessageId="ivr-messages">
          Atendimento Automático
        </PageTitle>
        <Card className="mb-4 w-full flex">
          <div className="mr-8 pr-8 border-r-2 border-white-dark">
            <Select
              title="Tipo de Atendimento Automático"
              value={ivr.ivrType}
              setter={ivr.setIvrType}
              //@ts-ignore
              formValidation={ivr.formValidation}
              className="w-[300px]"
              required
            >
              <option value="multi-queue">Apresentar opções digitáveis</option>
              <option value="single-queue">Direcionar para uma fila</option>
            </Select>
          </div>
          <div className="flex-1">
            {ivr.ivrType === "multi-queue" && <MessagesConfig ivrHook={ivr} />}
            {ivr.ivrType === "single-queue" && (
              <div>
                <Select
                  title="Fila de atendimento"
                  className="min-w-[250px]"
                  //@ts-ignore
                  formValidation={ivr.formValidation}
                  value={ivr.selectedSingleQueueName}
                  setter={ivr.setSelectedSingleQueueName}
                  tooltipMessageId="ivr-single-queue"
                  required
                >
                  {ivr.queues &&
                    ivr.queues.map((q, i) => (
                      <option value={q.name} key={i}>
                        {q.name}
                      </option>
                    ))}
                </Select>
              </div>
            )}
          </div>
        </Card>
        <QueuesConfig ivrHook={ivr} />
        <div className="mt-4 flex justify-end items-center">
          <div className="relative">
            {ivr.hasUnsavedChanges && (
              <>
                <div className="rounded-full w-4 h-4 bg-red absolute -top-1 -right-1"></div>
                <div className="rounded-full w-4 h-4 animate-ping bg-red absolute -top-1 -right-1"></div>
              </>
            )}
            <Button onClick={ivr.onSave} className="w-32 ml-8">
              Salvar
            </Button>
          </div>
        </div>
      </ContentWrapper>
      <ConfirmModal
        shown={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={onConfirm}
      />
    </SidebarLayout>
  );
}

export default IvrPage;
