import { useEffect, useRef } from "react";
import { EventEmitter } from "../utils/eventEmitter";

function useFormValidation() {
    const validationCb = useRef([]);
    const saveCb = useRef([]);
    const emitter = useRef(new EventEmitter());

    const registerValidationCallback = (cb) => {
        validationCb.current = [...validationCb.current, cb];
    };

    const registerSaveCallback = (cb) => {
        saveCb.current = [...saveCb.current, cb];
    };

    const validateForm = () => {
        return validationCb.current
            .map((cb) => cb.validate())
            .filter((v) => v === false).length > 0
            ? false
            : true;
    };

    const saveForm = async () => {
        emitter.current.emit("submit");

        if (saveCb.current.length > 0) {
            const promises = [];
            saveCb.current.map((cb) => promises.push(cb.save()));
            return await Promise.all(promises);
        }
    };

    const unregisterValidationCallback = (id) => {
        validationCb.current = validationCb.current.filter(
            (cb) => cb.id !== id
        );
    };

    const unregisterSaveCallback = (id) => {
        saveCb.current = saveCb.current.filter((cb) => cb.id !== id);
    };

    const on = (evt, func) => {
        emitter.current.on(evt, func);
    };

    return {
        registerValidationCallback,
        unregisterValidationCallback,
        registerSaveCallback,
        unregisterSaveCallback,
        saveForm,
        validateForm,
        on,
    };
}

export default useFormValidation;
