import AddQueueCard from "./AddQueueCard";
import QueueCard from "./QueueCard";
import { useEffect, useState } from "react";

function IvrCardsGrid({ queues, onCreate, onEdit }) {
    return (
        <div className="grid grid-cols-10 gap-4 2xl:grid-cols-ivrCard grid-cols-ivrCard-sm">
            {queues &&
                queues
                    .sort((a, b) => Number(a.option) - Number(b.option))
                    .map((queue, i) => {
                        return (
                            <QueueCard
                                queue={queue}
                                onEdit={onEdit}
                                key={i}
                                className="w-full h-full hover:ring-expand hover:drop-shadow-far transition-filter ring-white duration-200"
                            />
                        );
                    })}
            <AddQueueCard onCreate={onCreate} />
        </div>
    );
}

export default IvrCardsGrid;
