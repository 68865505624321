import Card from "../layout/Card";
import Button from "../layout/Button";
import TextInput from "../layout/TextInput";
import PageTitle from "../layout/PageTitle";
import { useCallback, useEffect, useState } from "react";
import Select from "../layout/Select";
import DateRangeInput from "../layout/DateRangeInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarPlus } from "@fortawesome/free-solid-svg-icons";
import AddEventModal from "../working-hours/AddEventModal";
import useFormValidation from "../../hooks/formValidation";
import EventsTable from "./EventsTable";
import useTablePagination from "../../hooks/tablePagination";
import { getEvents } from "../../services/events";
import DeleteEventModal from "./DeleteEventModal";
import EditEventModal from "./EditEventModal";
import _ from "lodash";

function EventsConfig({ setIsLoading }) {
    const [nameSearch, setNameSearch] = useState("");
    const [dateRangeSearch, setDateRangeSearch] = useState({
        from: "",
        to: "",
    });
    const [statusSearch, setStatusSearch] = useState("");

    const [showAddEvent, setShowAddEvent] = useState(false);

    const [deleteEventObj, setDeleteEventObj] = useState("");
    const [editEventObj, setEditEventObj] = useState("");

    const formValidation = useFormValidation();
    const table = useTablePagination(getEvents, 10);

    const searchHandler = async () => {
        if (formValidation.validateForm()) {
            setIsLoading(true);
            await table.search({
                name: nameSearch,
                dateRange: dateRangeSearch,
                status: statusSearch,
            });
            setIsLoading(false);
        }
    };

    const load = useCallback(async () => {
        await table.search();
        setIsLoading(false);
    }, [table, setIsLoading]);

    const reload = useCallback(async () => {
        setIsLoading(true);
        setShowAddEvent(false);
        setDeleteEventObj("");
        setEditEventObj({});

        await load();
        setIsLoading(false);
    }, [setIsLoading, load]);

    const onDeleteEventHandler = (event) => {
        setDeleteEventObj(event);
    };

    useEffect(() => {
        if (!table.hasLoaded) {
            load();
        }
    }, [table.hasLoaded, load]);

    return (
        <>
            <PageTitle>Feriados e Eventos</PageTitle>
            <Card className="w-full">
                <div className="flex items-end">
                    <TextInput
                        title="Nome"
                        placeholder=""
                        value={nameSearch}
                        setter={setNameSearch}
                        className="mr-2 flex-1"
                    />
                    <DateRangeInput
                        title="Data"
                        value={dateRangeSearch}
                        setter={setDateRangeSearch}
                        formValidation={formValidation}
                        className="mr-2 flex-2"
                    />
                    <Select
                        title="Status"
                        className="flex-1"
                        setter={setStatusSearch}
                        value={statusSearch}
                    >
                        <option value="1">Ativo</option>
                        <option value="0">Inativo</option>
                    </Select>
                </div>
                <div className="mt-4">
                    <Button
                        onClick={() => setShowAddEvent(true)}
                        className="float-left"
                        outline
                    >
                        <FontAwesomeIcon icon={faCalendarPlus} size="lg" />
                        <span className="ml-2">Adicionar Evento</span>
                    </Button>
                    <Button onClick={searchHandler} className="float-right">
                        Pesquisar
                    </Button>
                </div>
            </Card>
            <EventsTable
                data={table.data}
                onDeleteEvent={onDeleteEventHandler}
                onEditEvent={setEditEventObj}
            />
            <div className="mt-4 flex justify-center">
                {table.hasMore && (
                    <Button onClick={() => table.loadMore()}>
                        Carregar mais
                    </Button>
                )}
            </div>
            <AddEventModal
                shown={showAddEvent}
                onClose={() => setShowAddEvent(false)}
                onEventCreated={reload}
                setIsLoading={setIsLoading}
            />
            <EditEventModal
                shown={!_.isEmpty(editEventObj)}
                event={editEventObj}
                onClose={() => setEditEventObj("")}
                onEdited={() => reload()}
                setIsLoading={setIsLoading}
            />
            <DeleteEventModal
                shown={!_.isEmpty(deleteEventObj)}
                event={deleteEventObj}
                onClose={() => setDeleteEventObj("")}
                onConfirm={() => reload()}
                setIsLoading={setIsLoading}
            />
        </>
    );
}

export default EventsConfig;
