function TableHeaderCell({ isLeftBorder, isRightBorder, className, children }) {
    const border = isLeftBorder
        ? " rounded-tl-15"
        : isRightBorder
        ? " rounded-tr-15"
        : "";

    return (
        <th
            className={`p-3 2xl:p-4 text-primary bg-gray-bluish ${className}${border}`}
        >
            {children}
        </th>
    );
}

export default TableHeaderCell;
