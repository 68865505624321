import QueueModal from "./QueueModal";
import _ from "lodash";

function CreateQueueModal({ onCreate, ...props }) {
    const onSave = async (queue) => {
        const data = {
            tempId: _.uniqueId("temp"),
            name: queue.name,
            option: queue.option,
            strategy: queue.strategy,
            participants: queue.participants.map((v) => {
                const el = queue.extensions.find((a) => v.value === a.value);
                return {
                    id: v.value,
                    name: el.name,
                    extensionNumber: el.extensionNumber,
                };
            }),
        };
        onCreate && onCreate(data);
    };

    return <QueueModal onSave={onSave} {...props} />;
}

export default CreateQueueModal;
