import { twMerge } from "tailwind-merge";
import Modal from "../layout/Modal";
import {
  SetupWizardConfig,
  WizardExtension,
} from "../../model/setupWizard.model";
import { Setter } from "../../utils/types";
import { useEffect, useMemo, useState } from "react";
import TextInput from "../layout/TextInput";
import useFormValidation from "../../hooks/formValidation";
import EmailInput from "../layout/EmailInput";
import ExtensionInput from "../layout/ExtensionInput";
import CreatePasswordInput from "../layout/CreatePasswordInput";
import InputGroup from "../layout/InputGroup";
import Switch from "../layout/Switch";
import Button from "../layout/Button";
import { uniqueId } from "lodash";
import { validateExtension } from "../../utils/validation";

export default function SetupWizardExtensionModal(
  props: SetupWizardExtensionModalProps
) {
  const defaultClasses = "min-w-[300px] 2xl:min-w-[400px]";
  const classes = twMerge(defaultClasses, props.className);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [extensionNumber, setExtensionNumber] = useState("");
  const [password, setPassword] = useState("");
  const [recording, setRecording] = useState(false);
  const [error, setError] = useState("");

  const formValidation = useFormValidation();

  const isEdit = props.extensionIndex !== null;

  const recordingNumber = useMemo(() => {
    const extensions = props.value.extensions || [];

    const currentRecordings = extensions.filter(
      (v) => v.recording === true
    ).length;
    return Number(props.recordingLimit || 0) - currentRecordings;
  }, [props.value]);

  const saveHandler = () => {
    if (formValidation.validateForm()) {
      const newExt: WizardExtension = {
        name,
        email,
        extensionNumber,
        recording,
        password,
        id: null,
        tempId: uniqueId("temp-"),
        downloadRecording: false,
      };
      if (!isEdit) {
        props.setter((v) => ({
          ...v,
          extensions: [...(v.extensions || []), newExt],
        }));
      } else {
        props.setter((v) => {
          const newExts = v?.extensions || [];
          newExts[props.extensionIndex || 0] = newExt;
          return { ...v, extensions: newExts };
        });
      }
      props.onSave && props.onSave();
    }
  };

  const fill = (extension: WizardExtension | null) => {
    setName(extension?.name || "");
    setEmail(extension?.email || "");
    setExtensionNumber(extension?.extensionNumber.toString() || "");
    setPassword(extension?.password || "");
    setRecording(extension?.recording || false);
    setError("");
  };

  const extensionValidation = (extension: string) => {
    const result = validateExtension(extension);
    if (result && result.error) return result;

    if (props.value?.extensions?.length) {
      const { extensions } = props.value;
      const extensionAlreadyUsed = extensions.find(
        (e) => e.extensionNumber === extension
      );

      if (extensionAlreadyUsed) return { error: true, message: "Já utilizado" };

      return { error: false };
    }
  };

  const emailValidation = (email: string) => {
    if (props.value?.extensions?.length) {
      const { extensions } = props.value;
      const emailAlreadyUsed = extensions.find((e) => e.email === email);

      if (emailAlreadyUsed) return { error: true, message: "Já utilizado" };

      return { error: false };
    }
  };

  useEffect(() => {
    if (props.extensionIndex !== null) {
      const extension = props.value.extensions![props.extensionIndex];
      fill(extension);
    } else {
      fill(null);
    }
  }, [props.shown]);

  return (
    <Modal
      shown={props.shown}
      onClose={props.onClose}
      // tooltipMessageId="extension-config"
      title="Editar Ramal"
      className={classes}
    >
      <div className="grid grid-cols-1 gap-4 mb-6">
        <TextInput
          value={name}
          setter={setName}
          title="Nome do atendente"
          formValidation={formValidation}
          required
        />
        <EmailInput
          value={email}
          setter={setEmail}
          title="E-mail"
          formValidation={formValidation}
          validation={emailValidation}
          required
        />
        <ExtensionInput
          value={extensionNumber}
          setter={setExtensionNumber}
          title="Número do ramal"
          formValidation={formValidation}
          validation={extensionValidation}
          required
        />
        <CreatePasswordInput
          value={password}
          setter={setPassword}
          title="Senha"
          formValidation={formValidation}
          required={!isEdit}
        />
        <div className="flex justify-between">
          <InputGroup title="Gravação de ligação">
            <Switch
              titleActive="Ativo"
              titleInactive="Inativo"
              className="col-span-1"
              value={recording}
              disabled={false}
              onChange={setRecording}
            />
          </InputGroup>
          <div>
            <span className="block text-gray-dark text-sm font-bold">
              Disponíveis
            </span>
            <span className="block text-gray-dark font-bold text-right leading-5">
              {recording ? recordingNumber - 1 : recordingNumber}/
              {props.recordingLimit}
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div>{error && <span className="text-red  text-sm">{error}</span>}</div>
        <Button color="purple" onClick={saveHandler}>
          Salvar
        </Button>
      </div>
    </Modal>
  );
}

export type SetupWizardExtensionModalProps = {
  shown: boolean;
  onClose: () => void;
  value: SetupWizardConfig;
  setter: Setter<SetupWizardConfig>;
  extensionIndex: number | null;
  recordingLimit: number;
  onSave?: () => void;
  className?: string;
};
