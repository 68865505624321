import { useEffect } from "react";
import { useHistory, Route as ReactRoute } from "react-router-dom";
import { useSoftphone } from "../../services/softphone/softphoneContext";

export const Route = (props) => {
    const softphone = useSoftphone();
    const history = useHistory();

    useEffect(() => {
        if (props.softphone) {
            softphone.setVisibility(true);
        } else {
            softphone.setVisibility(false);
        }
    }, [history.location]);

    return <ReactRoute render={props.render} />;
};
