import Modal from "../layout/Modal";
import Button from "../layout/Button";

function ConfirmModal({ onConfirm, ...props }) {
    return (
        <Modal title="Confirmação" {...props}>
            <div>
                <p>Você realizou alterações em seu atendimento automático.</p>
                <p>
                    Você tem certeza que deseja sair sem salvar suas alterações?
                </p>
            </div>
            <Button className="float-left mt-8" outline onClick={props.onClose}>
                Voltar
            </Button>
            <Button
                className="float-right mt-8"
                color="red"
                onClick={onConfirm}
            >
                Descartar
            </Button>
        </Modal>
    );
}

export default ConfirmModal;
