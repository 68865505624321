import SoftphoneLayout from "./SoftphoneLayout";
import SoftphoneDock from "./SoftphoneDock";
import SoftphoneContainer from "./SoftphoneContainer";
import { useState } from "react";
import { useSoftphone } from "../../services/softphone/softphoneContext";

function Softphone() {
    const [keypadWidth, setKeypadWidth] = useState(500);
    const softphone = useSoftphone();

    return (
        <>
            {softphone.showSoftphone && (
                <SoftphoneLayout width={keypadWidth}>
                    <SoftphoneDock />
                    <SoftphoneContainer setKeypadWidth={setKeypadWidth} />
                    <audio id="remote-audio" />
                </SoftphoneLayout>
            )}
        </>
    );
}

export default Softphone;
