import { functions } from "./firebase/firebaseInit";
import { firestore } from "./firebase/firebaseInit";
import {
    collection,
    getDocs,
    query,
    where,
    limit,
    startAfter,
    orderBy,
    deleteDoc,
    doc,
} from "@firebase/firestore";
import { httpsCallable } from "@firebase/functions";

export const createUserContact = async (auth, contactData) => {
    const userId = auth.user.uid;
    contactData.userId = userId;
    const createContact = httpsCallable(functions, "createUserContact");
    return await createContact(contactData);
};

export const updateUserContact = async (auth, contactData) => {
    const userId = auth.user.uid;
    contactData.userId = userId;
    const updateUserContact = httpsCallable(functions, "updateUserContact");
    return await updateUserContact(contactData);
};

export const getUserContacts = async (auth, lastDoc, quantity, filters) => {
    const userId = auth.user.uid;
    let q = query(
        collection(firestore, `users/${userId}/contacts`),
        orderBy("name"),
        startAfter(lastDoc),
        limit(quantity)
    );

    const snap = await getDocs(q);

    const data = snap.docs.reduce((prev, curr) => {
        return [...prev, { id: curr.id, ...curr.data(), doc: curr }];
    }, []);

    return data;
};

export const getAllUsersContacts = async (auth) => {
    const userId = auth.user.uid;

    let q = query(
        collection(firestore, `users/${userId}/contacts`),
        orderBy("name")
    );

    const snap = await getDocs(q);

    const data = snap.docs.reduce((prev, curr) => {
        return [...prev, { id: curr.id, ...curr.data(), doc: curr }];
    }, []);

    return data;
};

export const deleteUserContacts = async (auth, contactId) => {
    const userId = auth.user.uid;
    const deleteUserContact = httpsCallable(functions, "deleteUserContact");
    return await deleteUserContact({ userId, id: contactId });
};
