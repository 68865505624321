import Modal from "../layout/Modal";
import Button from "../layout/Button";

function ConfirmDeleteModal({ onConfirm, ...props }) {
    return (
        <Modal title="Confirmação" {...props}>
            <div className="text-sm 2xl:text-base">
                <p>Você tem certeza que deseja deletar este atendente?</p>
            </div>
            <Button className="float-left mt-8" color="red" onClick={onConfirm}>
                Deletar
            </Button>
            <Button
                className="float-right mt-8"
                outline
                onClick={props.onClose}
            >
                Voltar
            </Button>
        </Modal>
    );
}

export default ConfirmDeleteModal;
