import TextInput from "./TextInput";
import { dateMask } from "../../utils/masks";
import { useEffect, useState } from "react";

function DateInput({ value, setter, ...props }) {
    const [val, setVal] = useState("");

    const set = (val) => {
        setVal(val);
        if (val.length === 8) {
            const day = val.substr(0, 2);
            const month = val.substr(2, 2);
            const year = val.substr(4, 4);
            setter(`${year}-${month}-${day}`);
        } else {
            setter("");
        }
    };

    useEffect(() => {
        if (value) {
            const values = value.split("-");
            setVal(values[2] + values[1] + values[0]);
        }
    }, [value]);

    return <TextInput mask={dateMask} value={val} setter={set} {...props} />;
}

export default DateInput;
