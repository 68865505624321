import IvrCard from "./IvrCard";
import Button from "../layout/Button";
import IvrParticipant from "./IvrParticipant";
import MoreParticipants from "./MoreParticipants";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function QueueCard({ queue, onEdit, className }) {
    const footer = (
        <Button className="w-full" onClick={() => onEdit(queue.id)}>
            Editar
        </Button>
    );

    return (
        <IvrCard footer={footer} className={className || ""}>
            <div className="h-full">
                <div className="mb-6 2xl:mb-8 mt-4">
                    <div className="text-gray-dark text-center font-medium text-base 2xl:text-lg">
                        Digite{" "}
                        <span className="text-base 2xl:text-xl">
                            {queue.option}
                        </span>{" "}
                        para
                    </div>
                    <div className="text-primary font-bold text-base 2xl:text-lg text-center">
                        {queue.name}
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-2 mb-4">
                    {queue.participants &&
                        queue.participants
                            .slice(0, 3)
                            .map((v, i) => (
                                <IvrParticipant key={i} participant={v} />
                            ))}
                    {queue.participants && queue.participants.length > 3 && (
                        <MoreParticipants />
                    )}
                    {(!queue.participants ||
                        queue.participants.length === 0) && (
                        <div className="border-red border-2 py-1 px-2 rounded-10 flex items-center justify-between">
                            <FontAwesomeIcon
                                icon={faExclamationCircle}
                                className="text-red text-[35px]"
                            />
                            <div className="text-red text-sm font-bold block ml-4">
                                Fila sem participantes
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </IvrCard>
    );
}

export default QueueCard;
