import { useEffect, useState } from "react";
import Modal from "../layout/Modal";
import TextInput from "../layout/TextInput";
import Button from "../layout/Button";
import Switch from "../layout/Switch";
import InputGroup from "../layout/InputGroup";
import { updateExtension } from "../../services/extensions";
import { useAuth } from "../../services/firebase/auth";
import EmailInput from "../layout/EmailInput";
import ExtensionInput from "../layout/ExtensionInput";
import useFormValidation from "../../hooks/formValidation";
import CallToAction from "../layout/CallToAction";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { validateExtension } from "../../utils/validation";

function ExtensionConfigModal({
  shown = false,
  extension,
  onClose,
  onSave,
  onQRCode,
  onResetPassword,
  recordingLimit,
  recordingNumber,
  setIsLoading,
  extensions,
}) {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [extensionNumber, setExtensionNumber] = useState("");

  const [wasRecording, setWasRecording] = useState(false);
  const [recording, setRecording] = useState(false);
  const [downloadRecording, setDownloadRecording] = useState(false);
  const [currRecordingNum, setCurrRecordingNum] = useState(0);

  const [numb, setNumb] = useState(0);

  const [error, setError] = useState("");

  const auth = useAuth();
  const formValidation = useFormValidation();

  useEffect(() => {
    setNumb(recordingNumber);
  }, [recordingNumber]);

  useEffect(() => {
    if (extension) {
      setId(extension.id);
      setName(extension.name);
      setEmail(extension.email);
      setExtensionNumber(extension.extensionNumber);
      setRecording(extension.recording);
      setWasRecording(extension.recording);
      setDownloadRecording(extension.downloadRecording);
    }
  }, [extension]);

  const saveHandler = async () => {
    if (formValidation.validateForm()) {
      const userData = {
        id,
        name,
        email,
        extensionNumber: Number(extensionNumber),
        recording,
        downloadRecording
      };
      setIsLoading && setIsLoading(true);
      const { data: result } = await updateExtension(auth, userData);
      if (result && result.error) {
        setIsLoading && setIsLoading(false);
        if (result.data.includes("email")) {
          setError("E-mail já cadastrado.");
        } else {
          setError("Ocorreu um erro. Tente novamente.");
        }
      } else {
        onSave && onSave(userData);
        setIsLoading && setIsLoading(false);
      }
    }
  };

  const onCloseHandler = () => {
    clear();
    onClose && onClose();
  };

  const onQRCodeHandler = () => {
    clear();
    onQRCode && onQRCode();
  };

  const clear = () => {
    setId("");
    setName("");
    setEmail("");
    setExtensionNumber("");
    setError("");
    setRecording(false);
    setWasRecording(false);
  };

  const isDisabled = () => {
    if (recording) {
      return false;
    } else {
      if (currRecordingNum === 0) {
        return true;
      } else {
        return false;
      }
    }
  };

  const extensionValidation = (v) => {
    const result = validateExtension(v);
    if (result && result.error) return result;

    const extensionAlreadyUsed = extensions.find(
      (e) => e.extensionNumber === v && e.id !== extension.id
    );
    if (extensionAlreadyUsed) return { error: true, message: "Já utilizado" };
    return { error: false };
  };

  useEffect(() => {
    if (!recording && wasRecording) {
      setCurrRecordingNum(recordingNumber + 1);
    } else if (recording && !wasRecording) {
      setCurrRecordingNum(recordingNumber - 1);
    } else {
      setCurrRecordingNum(recordingNumber);
    }
  }, [recording, wasRecording, recordingNumber]);

  return (
    <Modal
      shown={shown}
      onClose={onCloseHandler}
      tooltipMessageId="extension-config"
      title="Configuração de Ramal"
      className="min-w-[300px] 2xl:min-w-[400px]"
    >
      <div className="grid grid-cols-1 gap-4 mb-6">
        <TextInput
          value={name}
          setter={setName}
          title="Nome do atendente"
          formValidation={formValidation}
          required
        />
        <EmailInput
          value={email}
          setter={setEmail}
          title="E-mail"
          formValidation={formValidation}
        />
        <ExtensionInput
          value={extensionNumber}
          setter={setExtensionNumber}
          formValidation={formValidation}
          validation={extensionValidation}
          title="Número do ramal"
        />
        <div className="flex justify-between">
          <InputGroup title="Gravação de ligação">
            <Switch
              titleActive="Ativo"
              titleInactive="Inativo"
              className="col-span-1"
              formValidation={formValidation}
              value={recording}
              disabled={isDisabled()}
              onChange={setRecording}
            />
          </InputGroup>
          <div>
            <span className="block text-gray-dark text-sm font-bold">
              Disponíveis
            </span>
            <span className="block text-gray-dark font-bold text-right leading-5">
              {currRecordingNum}/{recordingLimit}
            </span>
          </div>
        </div>
        <InputGroup title="Download Gravação">
          <Switch
            titleActive="Ativo"
            titleInactive="Inativo"
            className="col-span-1"
            formValidation={formValidation}
            value={downloadRecording}
            disabled={isDisabled()}
            onChange={setDownloadRecording}
          />
        </InputGroup>
      </div>
      <div className="grid grid-cols-1 gap-4 mb-4">
        {/* <CallToAction
                    icon={faQrcode}
                    title="Conectar dispositivo"
                    className="text-purple border-purple"
                    onClick={onQRCodeHandler}
                /> */}
        {/* <CallToAction
                    icon={faPhoneAlt}
                    title="Adicionar Telefone IP"
                    className="text-purple border-purple"
                /> */}
      </div>
      {error && <div className="text-red text-sm mb-4">{error}</div>}
      <div className="flex justify-between">
        <Button onClick={onResetPassword} color="primary" outline>
          Redefinir senha
        </Button>
        <Button color="purple" onClick={saveHandler}>
          Salvar
        </Button>
      </div>
    </Modal>
  );
}

export default ExtensionConfigModal;
