import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Avatar from "../layout/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../services/firebase/auth";
import { useSoftphone } from "../../services/softphone/softphoneContext";

function SidebarProfile({ avatar, username, extension, className }) {
  const currentPage = useLocation().pathname;
  const selected = currentPage === "/profile" || null;

  const textColor = selected ? "text-green-bright" : "text-white-dark";
  const bgColor = selected ? "bg-primary-bright" : "";

  const auth = useAuth();
  const softphone = useSoftphone();

  const logOff = async () => {
    if (softphone) {
      await softphone.disconnect();
    }

    await auth.logoutUser();

    window.location.replace("https://app.voki.net.br/login");
  };

  return (
    <div
      className={`relative flex justify-between py-4 pl-2 2xl:pl-2 rounded-l-10 ${bgColor} ${className}`}
    >
      {selected && (
        <div className="absolute top-0 right-0 h-[16px] w-[16px] transform -translate-y-full bg-primary-bright before:w-[16px] before:h-[16px] before:absolute before:top-0 before:bg-primary before:inline-block before:rounded-br-20"></div>
      )}
      <Link to="/profile" className="max-w-[90%] w-full">
        <div className="flex align-middle">
          <Avatar
            avatar={avatar}
            className="mr-4 bg-gray-bluish w-[35px] h-[35px]"
          />
          <div>
            <div className={`font-bold text-md 2xl:text-lg ${textColor}`}>
              {username}
            </div>
            {extension && (
              <div className="text-gray font-medium text-xs 2xl:text-sm">
                Ramal {extension}
              </div>
            )}
          </div>
        </div>
      </Link>
      <div>
        <div onClick={logOff} className="cursor-pointer">
          <FontAwesomeIcon
            icon={faPowerOff}
            className={`mr-4 text-sm 2xl:text-base 2xl:mr-4 ${textColor || ""}`}
          />
        </div>
      </div>
      {selected && (
        <div className="absolute bottom-0 right-0 h-[16px] w-[16px] translate-y-full bg-primary-bright before:w-[16px] before:h-[16px] before:absolute before:top-0 before:bg-primary before:inline-block before:rounded-tr-20"></div>
      )}
    </div>
  );
}

export default SidebarProfile;
