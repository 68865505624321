function KeypadIcon({ className }) {
    return (
        <div className={className}>
            <svg
                width="27"
                height="39"
                viewBox="0 0 27 39"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-full"
            >
                <ellipse
                    cx="4"
                    cy="4.35884"
                    rx="4"
                    ry="4.16438"
                    fill="#1E1D20"
                />
                <ellipse
                    cx="23"
                    cy="4.35884"
                    rx="4"
                    ry="4.16438"
                    fill="#1E1D20"
                />
                <ellipse
                    cx="13.5"
                    cy="4.35884"
                    rx="4"
                    ry="4.16438"
                    fill="#1E1D20"
                />
                <ellipse
                    cx="4"
                    cy="14.2492"
                    rx="4"
                    ry="4.16438"
                    fill="#1E1D20"
                />
                <ellipse
                    cx="23"
                    cy="14.2492"
                    rx="4"
                    ry="4.16438"
                    fill="#1E1D20"
                />
                <ellipse
                    cx="13.5"
                    cy="14.2492"
                    rx="4"
                    ry="4.16438"
                    fill="#1E1D20"
                />
                <ellipse
                    cx="4"
                    cy="24.1397"
                    rx="4"
                    ry="4.16438"
                    fill="#1E1D20"
                />
                <ellipse
                    cx="23"
                    cy="24.1397"
                    rx="4"
                    ry="4.16438"
                    fill="#1E1D20"
                />
                <ellipse
                    cx="13.5"
                    cy="24.1397"
                    rx="4"
                    ry="4.16438"
                    fill="#1E1D20"
                />
                <ellipse
                    cx="13.5"
                    cy="34.03"
                    rx="4"
                    ry="4.16438"
                    fill="#1E1D20"
                />
            </svg>
        </div>
    );
}

export default KeypadIcon;
