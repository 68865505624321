export const requestMissingPermissions = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({
            video: false,
            audio: true,
        });

        /* NOTE: Required by Firefox due to it keeping the stream tracks active
         *  for a couple of seconds when unused, instead of closing it immediatly,
         *  resulting in a floating `WebRTC Indicator` showing microphone in use.
         */
        stream.getAudioTracks().forEach((v) => v.stop());
        return { error: false };
    } catch (e) {
        console.log(e);
        if (e.toString().includes("NotFoundError")) {
            return {
                error: "missing-device",
                message: "Microfone não encontrado",
            };
        } else {
            return {
                error: "permission",
                message: "A permissão de microfone não foi concedida",
            };
        }
    }
};
