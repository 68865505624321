import { useState, useEffect, useCallback, useRef } from "react";
import Select from "react-select";
import InputGroup from "./InputGroup";
import _ from "lodash";

function MultiSelect({
  value,
  setter,
  title,
  options,
  onChange = () => {},
  required = false,
  validation = null,
  formValidation,
}) {
  const [id] = useState(_.uniqueId("select-"));
  const [errorStatus, setErrorStatus] = useState(false);
  const [rawVal, setRawVal] = useState("");

  const ref = useRef();

  const validate = useCallback(
    (v) => {
      const val = v || ref.current.getValue();
      if (required && (!val || val.length === 0)) {
        setErrorStatus("Obrigatório");
        return false;
      }

      if (validation) {
        const validResponse = validation(val);
        if (validResponse.error) {
          setErrorStatus(validResponse.message || true);
          return false;
        } else {
          setErrorStatus(false);
          return true;
        }
      }
      setErrorStatus(false);
      return true;
    },
    [validation, required]
  );

  const onChangeHandler = (v) => {
    setRawVal(v);
    validate(v) && setter(v);
    onChange && onChange();
  };

  useEffect(() => {
    if (formValidation) {
      formValidation.registerValidationCallback({ id, validate });
      return () => formValidation.unregisterValidationCallback(id);
    }
  }, [id, validate, formValidation]);

  useEffect(() => {
    setRawVal(value);
  }, [options, value]);

  const styles = {
    control: (prev) => ({
      ...prev,
      borderRadius: "10px",
      borderWidth: "2px",
      borderColor: errorStatus ? "rgba(243, 12, 54, 0.6)" : "#e6e9ec",
    }),
    valueContainer: (prev) => ({
      ...prev,
      padding: "5px 15px",
    }),
  };

  return (
    <InputGroup title={title || ""} error={errorStatus}>
      <Select
        options={options}
        isMulti
        placeholder="Selecione..."
        noOptionsMessage={() => "Nenhuma Opção"}
        styles={styles}
        onChange={onChangeHandler}
        value={rawVal}
        ref={ref}
      />
    </InputGroup>
  );
}

export default MultiSelect;
