import moment from "moment";

export const formatISODate = (isoDate) => {
    let [date, time] = isoDate.split(" ");
    date = date.split("-").reverse().join("/");
    if (time) {
        return date + " " + time;
    }
    return date;
};

export const formatISODateTime = (isoDate) => {
    const d = new Date(isoDate);
    const m = moment(d);
    return m.format("DD/MM/YYYY HH:mm:ss");
};

export const formatPhoneOrExt = (number) => {
    if (number.length > 4) {
        let r = number.replace(/^0/, "");
        if (r.length > 11) {
            r = r.replace(/^(\d\d)(\d\d)(\d{5})(\d{4}).*/, "+$1 ($2) $3-$4");
        } else if (r.length >= 10) {
            r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (r.length > 5) {
            r = r.replace(/^(\d{5})(\d{0,4}).*/, " $1-$2");
        }
        return r;
    } else {
        return number;
    }
};
