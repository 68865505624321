import { httpsCallable } from "firebase/functions";
import { firestore, functions } from "../services/firebase/firebaseInit";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { Account } from "./account.model";
import { orderBy } from "lodash";

export async function getAdminExtension(companyId: string): Promise<Extension> {
  const docRef = query(
    collection(firestore, `companies/${companyId}/users`),
    where("admin", "==", true),
    limit(1)
  );

  const docSnap = await getDocs(docRef);
  if (!docSnap.docs) throw new Error("Admin account not found");

  const data = docSnap.docs.map((doc) => {
    return { id: doc.id, ...doc.data() };
  }) as Extension[];

  return data[0];
}

export type Extension = {
  id?: string;
  name: string;
  email: string;
  extensionNumber: string;
  password?: string;
  recording: boolean;
  downloadRecording: boolean;
};
