import {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
} from "react";
import { useAuth } from "./firebase/auth";
import { getAllUsersContacts, createUserContact } from "./userContacts";

const userContactsContext = createContext();

export const UserContactsProvider = ({ children }) => {
    const [contacts, setContacts] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);

    const auth = useAuth();

    const init = async () => {
        if (auth.user) {
            let contacts = await getAllUsersContacts(auth);
            contacts = reorder(contacts);
            setContacts(contacts);
            setHasLoaded(true);
        }
    };

    const findByPhone = (phone) => {
        return contacts.find((v) => v.phone === phone);
    };

    const getAll = () => {
        return [...contacts];
    };

    const reload = async () => {
        setHasLoaded(false);
        await init();
    };

    const reorder = (contactsArray) => {
        if (contactsArray.length > 0) {
            return contactsArray.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
        }
        return contactsArray;
    };

    useEffect(() => {
        init();
    }, [auth.user]);

    const contextValues = {
        getAll,
        reload,
        hasLoaded,
        findByPhone,
        contacts,
    };

    return (
        <userContactsContext.Provider value={contextValues}>
            {children}
        </userContactsContext.Provider>
    );
};

export const useContacts = () => {
    return useContext(userContactsContext);
};
