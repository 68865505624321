import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { forwardRef } from "react";
import SoftphoneDockContainer from "./SoftphoneDockContainer";
import { useSoftphone } from "../../services/softphone/softphoneContext";

const SoftphoneRingingDock = forwardRef(({ shown }, ref) => {
    const softphone = useSoftphone();

    return (
        <SoftphoneDockContainer shown={shown} ref={ref}>
            <div className="z-50">
                <div
                    className="mb-24 2xl:mb-40 cursor-pointer"
                    onClick={() => softphone.pickup()}
                >
                    <FontAwesomeIcon
                        icon={faPhone}
                        className="text-base 2xl:text-xl"
                    />
                </div>
                <div
                    className="cursor-pointer"
                    onClick={softphone.toggleKeyboard}
                >
                    <FontAwesomeIcon
                        icon={faAngleDoubleLeft}
                        className="text-lg 2xl:text-2xl"
                    />
                </div>
            </div>
            <div
                className="absolute inset-0 animate-blink bg-yellow z-0 rounded-l-20"
                style={{
                    width: "53px",
                    height: "175px",
                    top: "-15px",
                    left: "-18px",
                }}
            ></div>
        </SoftphoneDockContainer>
    );
});

export default SoftphoneRingingDock;
