import { twMerge } from "tailwind-merge";
import Divider from "./Divider";

function CardTitle({ children, className = "" }) {
  const defaultClasses = "text-primary font-bold text-lg 2xl:text-2xl mb-4";
  const classes = twMerge(defaultClasses, className);

  return (
    <div className="card-title mb-4">
      <h2 className={classes}>
        {children}
      </h2>
      {/* <Divider /> */}
    </div>
  );
}

export default CardTitle;
