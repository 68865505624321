import { useAuth } from "../services/firebase/auth";
import Logo from "../components/layout/Logo";
import Card from "../components/layout/Card";
import Button from "../components/layout/Button";
import EmailInput from "../components/layout/EmailInput";
import PasswordInput from "../components/layout/PasswordInput";
import TextInput from "../components/layout/TextInput";
import ResetPasswordByEmailModal from "../components/resetPasswordByEmail/ResetPasswordByEmailModal";
import useFormValidation from "../hooks/formValidation";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSoftphone } from "../services/softphone/softphoneContext";
import { toast } from "react-toastify";
import { getTokenForSupportLogin } from "../services/user";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showResetPasswordByEmailModal, setShowResetPasswordByEmailModal] =
    useState(false);

  const auth = useAuth();
  const softphone = useSoftphone();
  const formValidation = useFormValidation();
  const history = useHistory();
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  const onLogin = async () => {
    if (formValidation.validateForm()) {
      const result = await auth.loginUser(email, password);
      if (result.error) {
        setError(result.message);
        setPassword("");
      } else {
        history.push("/");
      }
    }
  };

  const tryTokenLogin = async () => {
    const token = searchParams.get("t");
    const user = searchParams.get("u");
    const company = searchParams.get("c");
    if (token && user && company) {
      setIsLoading(true);
      try {
        const result = await getTokenForSupportLogin(token, user, company);
        if (result && !result.error) {
          await auth.tokenLogin(result.data.token);
          history.push("/");
        } else {
          toast("Login inválido");
        }
      } catch (e) {
        toast("Login falhou");
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    tryTokenLogin();
  }, [tryTokenLogin]);

  return (
    <>
      <div className="bg-white-dark min-h-screen flex flex-col justify-center items-center">
        {isLoading && <div className="text-2xl">Realizando Login...</div>}
        {!isLoading && (
          <div>
            <Logo type="dark" className="mb-8" />
            <Card className="w-[400px]">
              <div className="grid gap-4">
                <EmailInput
                  title="E-mail"
                  value={email}
                  setter={setEmail}
                  formValidation={formValidation}
                  autocomplete
                  required
                />
                <PasswordInput
                  title="Senha"
                  value={password}
                  setter={setPassword}
                  formValidation={formValidation}
                  autocomplete
                  required
                />
              </div>
            </Card>
            <div className="mt-4 w-full flex justify-between">
              <div className="text-red font-bold flex items-center">
                {error && <span>{error}</span>}
              </div>
              <Button onClick={onLogin}>Entrar</Button>
            </div>
            <div className="mt-4 w-full flex justify-center">
              <a
                className="cursor-pointer"
                onClick={() => setShowResetPasswordByEmailModal(true)}
              >
                Esqueceu a sua senha?
              </a>
            </div>
            <ResetPasswordByEmailModal
              shown={showResetPasswordByEmailModal}
              onClose={() => setShowResetPasswordByEmailModal(false)}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default LoginPage;
