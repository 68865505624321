import TextInput from "./TextInput";
import { validateExtension } from "../../utils/validation";
import { numericMask } from "../../utils/masks";

function ExtensionInput({...props}) {
    return (
        <TextInput validation={props.validation || validateExtension} mask={numericMask} {...props} />
    );
}

export default ExtensionInput;