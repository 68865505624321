import TextInput from "./TextInput";
import { cpfCnpjMask } from "../../utils/masks";
import { validateCpfCnpj } from "../../utils/validation";

function CpfCnpjInput({...props}) {
    return (
        <TextInput validation={validateCpfCnpj} mask={cpfCnpjMask} {...props} />
    );
}

export default CpfCnpjInput;