import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faStop, faCog } from "@fortawesome/free-solid-svg-icons";
import FadeInOut from "../layout/FadeInOut";

function PlayButton({ playState, onPlay, onStop, className = "" }) {
  return (
    <div
      className={`rounded-10 border-2 border-gray/50 w-8 h-8 2xl:h-9 2xl:w-9 inline-flex justify-center items-center relative ${className}`}
    >
      <FadeInOut
        selector={() => {
          switch (playState) {
            case "inactive":
              return 0;
            case "loading":
              return 1;
            case "playing":
              return 2;
            default:
              return 0;
          }
        }}
        className="w-full h-full"
        options={[
          <div
            className="w-full h-full flex justify-center items-center"
            onClick={onPlay}
          >
            <FontAwesomeIcon
              icon={faPlay}
              className="text-green text-xs 2xl:text-sm"
            />
          </div>,
          <div className="w-full h-full flex justify-center items-center">
            <FontAwesomeIcon
              icon={faCog}
              className="text-gray-dark text-sm 2xl:text-base"
              spin
            />
          </div>,
          <div
            className="w-full h-full flex justify-center items-center"
            onClick={onStop}
          >
            <FontAwesomeIcon
              icon={faStop}
              className="text-red text-xs 2xl:text-sm"
            />
          </div>,
        ]}
      />
    </div>
  );
}

export default PlayButton;
