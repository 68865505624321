export const useOscillators = () => {
    let oscillator1;
    let oscillator2;

    const tones = {
        0: [941.0, 1336.0],
        1: [697.0, 1209.0],
        2: [697.0, 1336.0],
        3: [697.0, 1477.0],
        4: [770.0, 1209.0],
        5: [770.0, 1336.0],
        6: [770.0, 1477.0],
        7: [852.0, 1209.0],
        8: [852.0, 1336.0],
        9: [852.0, 1477.0],
        "*": [941.0, 1209.0],
        "#": [941.0, 1477.0],
    };

    const playKeySound = (key) => {
        const [freq1, freq2] = tones[key];

        if (oscillator1 && oscillator2) {
            oscillator1.disconnect();
            oscillator2.disconnect();
        }

        const contextClass =
            window.AudioContext ||
            window.webkitAudioContext ||
            window.mozAudioContext ||
            window.oAudioContext ||
            window.msAudioContext;

        const audioCtx = new contextClass();

        oscillator1 = audioCtx.createOscillator();
        oscillator1.frequency.value = freq1;

        let gainNode = audioCtx.createGain
            ? audioCtx.createGain()
            : audioCtx.createGainNode();

        oscillator1.connect(gainNode, 0, 0);
        gainNode.connect(audioCtx.destination);

        gainNode.gain.value = 1 / 10;
        oscillator1.start ? oscillator1.start(0) : oscillator1.noteOn(0);
        oscillator1.stop(0.2);

        oscillator2 = audioCtx.createOscillator();
        oscillator2.frequency.value = freq2;

        gainNode = audioCtx.createGain
            ? audioCtx.createGain()
            : audioCtx.createGainNode();

        oscillator2.connect(gainNode);
        gainNode.connect(audioCtx.destination);

        gainNode.gain.value = 1 / 10;
        oscillator2.start ? oscillator2.start(0) : oscillator2.noteOn(0);
        oscillator2.stop(0.2);
    };

    return {
        playKeySound,
    };
};
