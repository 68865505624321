import { functions } from "./firebase/firebaseInit";
import { httpsCallable } from "@firebase/functions";
import {
    collection,
    getDocs,
    query,
    where,
    limit,
    startAfter,
    orderBy,
} from "@firebase/firestore";
import { firestore } from "./firebase/firebaseInit";

export const createEvent = async (auth, data) => {
    data.companyId = auth.user.token.claims.company;
    const createEvent = httpsCallable(functions, "createEvent");
    return await createEvent(data);
};

export const getEvents = async (auth, lastDoc, quantity, filters) => {
    const companyId = auth.user.token.claims.company;

    let q = query(
        collection(firestore, `companies/${companyId}/events`),
        limit(quantity)
    );

    let data = [];

    if (filters.name) {
        q = query(
            q,
            where("name_insensitive", ">=", filters.name.toLowerCase()),
            where(
                "name_insensitive",
                "<=",
                filters.name.toLowerCase() + "\uf8ff"
            ),
            orderBy("name_insensitive"),
            startAfter(lastDoc)
        );

        const snap = await getDocs(q);

        data = snap.docs.reduce((prev, curr) => {
            return [...prev, { id: curr.id, ...curr.data(), doc: curr }];
        }, []);

        if (filters.dateRange && filters.dateRange.from) {
            data = data.filter((v) => {
                return (
                    v.date >= filters.dateRange.from &&
                    v.date <= filters.dateRange.to
                );
            });
        }

        if (filters.status) {
            data = data.filter((v) => {
                return v.status === filters.status;
            });
        }
    } else if (filters.dateRange && filters.dateRange.from) {
        q = query(
            q,
            where("date", ">=", filters.dateRange.from),
            where("date", "<=", filters.dateRange.to),
            orderBy("date"),
            startAfter(lastDoc)
        );

        const snap = await getDocs(q);

        data = snap.docs.reduce((prev, curr) => {
            return [...prev, { id: curr.id, ...curr.data(), doc: curr }];
        }, []);

        if (filters.status) {
            data = data.filter((v) => {
                return v.status === filters.status;
            });
        }
    } else if (filters.status) {
        q = query(
            q,
            where("status", "==", filters.status === 1 ? true : false),
            orderBy("date"),
            startAfter(lastDoc)
        );

        const snap = await getDocs(q);

        data = snap.docs.reduce((prev, curr) => {
            return [...prev, { id: curr.id, ...curr.data(), doc: curr }];
        }, []);
    } else {
        q = query(q, orderBy("date"), startAfter(lastDoc));

        const snap = await getDocs(q);

        data = snap.docs.reduce((prev, curr) => {
            return [...prev, { id: curr.id, ...curr.data(), doc: curr }];
        }, []);
    }
    return data;
};

export const editEvent = async (auth, id, data) => {
    data.companyId = auth.user.token.claims.company;
    data.eventId = id;
    const editEvent = httpsCallable(functions, "editEvent");
    return await editEvent(data);
};

export const deleteEvent = async (auth, id, date) => {
    const data = {
        companyId: auth.user.token.claims.company,
        eventId: id,
        date: date,
    };
    const deleteEvent = httpsCallable(functions, "deleteEvent");
    return await deleteEvent(data);
};
