import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleDoubleLeft,
    faAngleLeft,
    faAngleRight,
    faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";

const generalStyle =
    "w-8 h-8 2xl:h-10 2xl:w-10 leading-8 2xl:leading-10 mr-2 text-center font-bold text-sm 2xl:text-xl drop-shadow rounded-10 cursor-pointer";
const textColor = "text-primary";
const bgColor = "bg-white";

function TablePaginator({ page, numPages, goToPage }) {
    return (
        <>
            {numPages > 1 && (
                <div className="flex justify-center mt-4">
                    <div className="flex">
                        {page > 3 && <Start onClick={() => goToPage(1)} />}
                        {page > 1 && (
                            <Previous onClick={() => goToPage(page - 1)} />
                        )}

                        {page > 2 && (
                            <Page
                                number={page - 2}
                                onClick={() => goToPage(page - 2)}
                            />
                        )}
                        {page > 1 && (
                            <Page
                                number={page - 1}
                                onClick={() => goToPage(page - 1)}
                            />
                        )}
                        {page && <Page number={page} isCurrent />}
                        {page < numPages && (
                            <Page
                                number={page + 1}
                                onClick={() => goToPage(page + 1)}
                            />
                        )}
                        {page < numPages - 1 && (
                            <Page
                                number={page + 2}
                                onClick={() => goToPage(page + 2)}
                            />
                        )}

                        {page < numPages && (
                            <Next onClick={() => goToPage(page + 1)} />
                        )}
                        {page < numPages - 2 && (
                            <End onClick={() => goToPage(numPages)} />
                        )}
                    </div>
                </div>
            )}
        </>
    );
}

function Page({ number, isCurrent, ...props }) {
    const current = isCurrent
        ? "bg-primary text-white"
        : `${textColor} ${bgColor}`;
    return (
        <div className={`${generalStyle} ${current}`} {...props}>
            {number}
        </div>
    );
}

function Previous(props) {
    return (
        <div className={`${generalStyle} ${textColor} ${bgColor}`} {...props}>
            <FontAwesomeIcon icon={faAngleLeft} />
        </div>
    );
}

function Start(props) {
    return (
        <div className={`${generalStyle} ${textColor} ${bgColor}`} {...props}>
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
        </div>
    );
}

function Next(props) {
    return (
        <div className={`${generalStyle} ${textColor} ${bgColor}`} {...props}>
            <FontAwesomeIcon icon={faAngleRight} />
        </div>
    );
}

function End(props) {
    return (
        <div className={`${generalStyle} ${textColor} ${bgColor}`} {...props}>
            <FontAwesomeIcon icon={faAngleDoubleRight} />
        </div>
    );
}

export default TablePaginator;
