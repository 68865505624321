import { useEffect, useCallback, useState, useMemo } from "react";

export const useQueryPagination = (searchFunc, quantity) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [numPages, setNumPages] = useState(0);
    const [limit, setLimit] = useState(quantity);
    const [activeFilters, setActiveFilters] = useState({});
    const [hasSearched, setHasSearched] = useState(false);
    const func = useMemo(() => searchFunc, [searchFunc]);

    const search = useCallback(
        async (filters) => {
            const result = await func(filters, limit, 0);

            setPage(1);

            setData(result.data);
            setNumPages(Math.ceil(result.count / limit));
            setActiveFilters(filters);
            setHasSearched(true);
        },
        [activeFilters, func, limit]
    );

    const goToPage = async (page) => {
        const offset = page * limit - limit;
        const result = await func(activeFilters, limit, offset);

        setPage(page);
        setData(result.data);
    };

    useEffect(() => {
        setLimit(quantity);
    }, [quantity, setLimit]);

    return {
        search,
        goToPage,
        data,
        page,
        numPages,
        hasSearched,
    };
};
