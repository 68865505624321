import SoftphoneKey from "./SoftphoneKey";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBackspace,
    faMicrophone,
    faMicrophoneAltSlash,
    faPhone,
    faPhoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useSoftphone } from "../../services/softphone/softphoneContext";
import { useEffect, useState } from "react";
import FadeInOut from "../layout/FadeInOut";

function SoftphoneKeypad() {
    const [phoneButtonState, setPhoneButtonState] = useState("call");
    const softphone = useSoftphone();

    const callOrPickup = () => {
        softphone.callState === "receiving"
            ? softphone.pickup()
            : softphone.initiateCall();
    };

    useEffect(() => {
        setPhoneButtonState(
            softphone.callState === "inactive" ||
                softphone.callState === "receiving"
                ? "call"
                : "hangup"
        );
    }, [softphone.callState]);

    return (
        <div className="grid grid-cols-3 gap-2 2xl:gap-4 place-items-center">
            <SoftphoneKey onClick={() => softphone.dial("1")}>1</SoftphoneKey>
            <SoftphoneKey onClick={() => softphone.dial("2")}>2</SoftphoneKey>
            <SoftphoneKey onClick={() => softphone.dial("3")}>3</SoftphoneKey>
            <SoftphoneKey onClick={() => softphone.dial("4")}>4</SoftphoneKey>
            <SoftphoneKey onClick={() => softphone.dial("5")}>5</SoftphoneKey>
            <SoftphoneKey onClick={() => softphone.dial("6")}>6</SoftphoneKey>
            <SoftphoneKey onClick={() => softphone.dial("7")}>7</SoftphoneKey>
            <SoftphoneKey onClick={() => softphone.dial("8")}>8</SoftphoneKey>
            <SoftphoneKey onClick={() => softphone.dial("9")}>9</SoftphoneKey>
            <SoftphoneKey onClick={() => softphone.dial("*")}>*</SoftphoneKey>
            <SoftphoneKey onClick={() => softphone.dial("0")}>0</SoftphoneKey>
            <SoftphoneKey onClick={() => softphone.dial("#")}>#</SoftphoneKey>
            <div className="flex justify-center items-center mt-4">
                <FadeInOut
                    selector={() => (softphone.muted ? 1 : 0)}
                    options={[
                        <FontAwesomeIcon
                            icon={faMicrophone}
                            className={`text-2xl 2xl:text-3xl cursor-pointer ${
                                softphone.callState === "on-call"
                                    ? "text-gray-dark"
                                    : "text-gray/50"
                            }`}
                            onClick={() =>
                                softphone.callState === "on-call" &&
                                softphone.toggleMute()
                            }
                        />,
                        <FontAwesomeIcon
                            icon={faMicrophoneAltSlash}
                            className={`text-2xl 2xl:text-3xl cursor-pointer ${
                                softphone.callState === "on-call"
                                    ? "text-gray-dark"
                                    : "text-gray/50"
                            }`}
                            onClick={() =>
                                softphone.callState === "on-call" &&
                                softphone.toggleMute()
                            }
                        />,
                    ]}
                />
            </div>
            <div className="flex justify-center items-center mt-4">
                <FadeInOut
                    selector={() => {
                        switch (softphone.callState) {
                            case "receiving":
                                return 1;
                            default:
                                return 0;
                        }
                    }}
                    options={[
                        <FontAwesomeIcon
                            icon={faBackspace}
                            className="text-2xl 2xl:text-3xl text-gray-dark cursor-pointer"
                            onClick={softphone.backspace}
                        />,
                        <FontAwesomeIcon
                            icon={faPhoneSlash}
                            className="text-2xl 2xl:text-3xl cursor-pointer text-red"
                            onClick={() => softphone.hangup()}
                        />,
                    ]}
                />
            </div>
            <div className="flex justify-center items-center">
                <FadeInOut
                    className="mt-4"
                    selector={() => {
                        switch (phoneButtonState) {
                            case "call":
                            case "received":
                                return 0;
                            case "hangup":
                                return 1;
                            default:
                                return 0;
                        }
                    }}
                    options={[
                        <FontAwesomeIcon
                            icon={faPhone}
                            className={`text-2xl 2xl:text-3xl cursor-pointer ${
                                softphone.maskedDialedNumber
                                    ? "text-green"
                                    : "text-gray/50"
                            }`}
                            onClick={() => callOrPickup()}
                        />,

                        <FontAwesomeIcon
                            icon={faPhoneSlash}
                            className="text-2xl 2xl:text-3xl cursor-pointer text-red"
                            onClick={() => softphone.hangup()}
                        />,
                    ]}
                />
            </div>
        </div>
    );
}

export default SoftphoneKeypad;
