import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { phoneMask } from "../../utils/masks";
import { getExtension } from "../extensions";
import { useAuth } from "../firebase/auth";
import SipService from "./SipService";
import incomingRingtoneSrc from "../../sounds/incoming.mp3";
import { useContacts } from "../UserContactsContext";
import { useOscillators } from "../../hooks/oscillators";
import { requestMissingPermissions } from "../../utils/permissions";
import { toast } from "react-toastify";

const softphoneContext = createContext();

export const SoftphoneProvider = ({ children }) => {
  const [callState, setCallState] = useState("inactive");
  const [statusMessage, setStatusMessage] = useState("Disponível");
  const [rawDialedNumber, setRawDialedNumber] = useState("");
  const [maskedDialedNumber, setMaskedDialedNumber] = useState("");

  const [showSoftphone, setShowSoftphone] = useState(false);
  const [transferShown, setTransferShown] = useState(false);
  const [keypadIsOpen, setKeypadIsOpen] = useState(false);
  const [displayIsFocused, setDisplayIsFocused] = useState(false);
  const [dockState, setDockState] = useState("inactive");
  const [loading, setLoading] = useState({ state: "loading", error: "" });

  const [muted, setMuted] = useState(false);
  const [onHold, setOnHold] = useState(false);

  const [incomingCallRingtone] = useState(new Audio(incomingRingtoneSrc));

  const { current: sip } = useRef(new SipService());
  const isRegistering = useRef(false);

  const auth = useAuth();
  const contacts = useContacts();
  const oscillators = useOscillators();

  const changeCallState = (state) => {
    window.onbeforeunload = null;
    switch (state) {
      case "inactive":
        incomingCallRingtone.pause();
        setStatusMessage("Disponível");
        setTransferShown(false);
        setMuted(false);
        setOnHold(false);
        break;
      case "on-call":
        incomingCallRingtone.pause();
        setStatusMessage("Em atendimento");
        setMuted(false);
        setOnHold(false);
        window.onbeforeunload = (e) => {
          e.preventDefault();
          e.returnValue =
            "Existe uma ligação ativa. Se sair da página ela será desconectada.";
          return e.returnValue;
        };
        break;
      case "ringing":
        incomingCallRingtone.pause();
        setStatusMessage("Discando...");
        break;
      case "receiving":
        incomingCallRingtone.currentTime = 0;
        incomingCallRingtone.play();
        setStatusMessage("Recebendo...");
        break;
    }
    setCallState(state);
  };

  useEffect(() => {
    if (!keypadIsOpen) {
      setDockState(callState);
    }
  }, [callState, keypadIsOpen]);

  const disconnect = () => {
    sip.disconnect();
  };

  const toggleKeyboard = useCallback(() => {
    if (!keypadIsOpen) {
      if (loading.state === "loaded") {
        setTimeout(() => {
          document.getElementById("phone-display").focus();
        }, 300);
      }
      setDockState("inactive");
    } else {
      if (loading.state === "loaded") {
        document.getElementById("phone-display").blur();
      }
      callState === "inactive" && setDialedNumber("");
      setDockState(callState);
    }
    setKeypadIsOpen((v) => !v);
  }, [keypadIsOpen, callState, setDockState, setKeypadIsOpen]);

  const call = (number) => {
    let newNumber = "";

    if (number && typeof number === "number") number = number.toString();

    if (number || rawDialedNumber) {
      changeCallState("ringing");

      if (number) newNumber = number.replace(/[\s()\-]/g, "");
      if (rawDialedNumber) newNumber = rawDialedNumber.replace(/[\s()\-]/g, "");

      sip.call(newNumber);
    }
  };

  const pickup = () => {
    sip.pickup();
  };

  const hangup = () => {
    sip.hangup();
  };

  const initiateCall = (number, name) => {
    setKeypadIsOpen(true);

    if (!number) {
      number = rawDialedNumber;
    }

    if (!name) {
      name = contacts.findByPhone(number)?.name || null;
    }

    if (name) {
      setDialedNumber(name + " - " + number);
    } else {
      setDialedNumber(number);
    }

    setTimeout(() => {
      call(number);
    }, 300);
  };

  const dial = (value) => {
    oscillators.playKeySound(value);

    if (callState === "on-call") {
      sip.sendDTMF(value);
    } else if (callState === "inactive") {
      setRawDialedNumber((v) => {
        setMaskedDialedNumber(v + value);
        return v + value;
      });
    }
  };

  const setDialedNumber = (value) => {
    setRawDialedNumber(value);
    setMaskedDialedNumber(value);
  };

  const backspace = () => {
    const { filtering, masking } = phoneMask();
    setRawDialedNumber((v) => {
      const newVal = filtering(v.substring(0, v.length - 1));
      setMaskedDialedNumber(masking(newVal));
      return newVal;
    });
  };

  const onCallingHandler = () => {
    changeCallState("ringing");
  };

  const onHangupHandler = () => {
    setDialedNumber("");
    changeCallState("inactive");
  };

  const onRejectedHandler = () => {
    setDialedNumber("");
    changeCallState("inactive");
  };

  const onAcceptedHandler = () => {
    changeCallState("on-call");
  };

  const onCallReceivedHandler = (e) => {
    const username = e.remote_identity.uri.user;
    const name = e.remote_identity.display_name;
    const extensionNumber = auth.user.token.claims.extensionNumber;

    setDialedNumber(name ? `${name} - ${username}` : `${username}`);
    changeCallState("receiving");

    if (username === extensionNumber) {
      setTimeout(() => {
        pickup();
        setDialedNumber(name ? `${name} - ${username}` : `${username}`);
        changeCallState("on-call");
      }, 1000);
    }
  };

  const onPickedUpHandler = () => {
    changeCallState("on-call");
  };

  const onHangedUpHandler = () => {
    setDialedNumber("");
    changeCallState("inactive");
  };

  const onReceivedFailedHandler = async () => {
    setDialedNumber("");
    const result = await requestMissingPermissions();
    if (result) {
      switch (result.error) {
        case "permission":
          toast.error("Ligação falhou: permissão de microfone não concedida.");
          break;
        case "missing-device":
          toast.error("Ligação falhou: microfone não detectado.");
          break;
      }
    }
    changeCallState("inactive");
  };

  const onRegistrationFailed = () => {
    setLoading({ state: "error", error: "O registro do ramal falhou" });
    isRegistering.current = false;
  };

  const onRegistrationSuccessful = async () => {
    const result = await requestMissingPermissions();
    if (result && result.error) {
      toast.error(result.message);
    }
    setLoading({ state: "loaded", error: false });
  };

  const onWSDisconnected = () => {
    setLoading({ state: "error", error: "Conexão perdida" });
  };

  const getSelectedDevices = () => {
    return {
      input: localStorage.getItem("inputDeviceId") || null,
      output: localStorage.getItem("outputDeviceId") || null,
      ringing: localStorage.getItem("ringingOutputDeviceId") || null,
    };
  };

  const changeDevices = (inputDeviceId, ringingDeviceId, outputDeviceId) => {
    if (!inputDeviceId || !outputDeviceId) return false;

    localStorage.setItem("inputDeviceId", inputDeviceId);
    localStorage.setItem("ringingOutputDeviceId", ringingDeviceId);
    localStorage.setItem("outputDeviceId", outputDeviceId);

    incomingCallRingtone.setSinkId(
      ringingDeviceId || inputDeviceId || "default"
    );

    if (callState === "on-call") {
      sip.renegotiate();
    }
  };

  const setVisibility = (val) => {
    setShowSoftphone(val);
  };

  const toggleMute = () => {
    setMuted((v) => !v);
    sip.toggleMute();
  };

  const toggleHold = () => {
    setOnHold((v) => !v);
    sip.toggleHold();
  };

  const initiateTransfer = () => {
    sip.sendDTMF("*");
    sip.sendDTMF("*");
  };

  const transferTo = (extensionNumber) => {
    const senders = sip.session.connection.getSenders();
    senders[0].dtmf.insertDTMF(`**${extensionNumber}`, 100, 300);
  };

  const onUnregistrationHandler = () => {
    isRegistering.current = false;
  };

  const onMonitorStateChangeHandler = (state) => {
    switch (state) {
      case "online":
        changeCallState("inactive");
        break;
      case "oncall":
        changeCallState("on-call");
        break;
      case "receiving":
        changeCallState("receiving");
        break;
      case "calling":
        changeCallState("ringing");
    }
  };

  const load = async () => {
    if (auth.user) {
      const companyId = auth.user.token.claims.company;
      const extension = await getExtension(auth.user.uid);

      if (isRegistering.current === false) {
        isRegistering.current = true;
        sip.connect(companyId, extension.extensionNumber);

        sip.registerHandlers({
          onRegistrationSuccessful,
          onRegistrationFailed,
          onUnregistration: onUnregistrationHandler,
          onWSDisconnected,
          onCalling: onCallingHandler,
          onHangup: onHangupHandler,
          onRejected: onRejectedHandler,
          onAccepted: onAcceptedHandler,
          onCallReceived: onCallReceivedHandler,
          onPickedUp: onPickedUpHandler,
          onHangedUp: onHangedUpHandler,
          onReceivedFailed: onReceivedFailedHandler,
          onCallEnded: onHangedUpHandler,
          onMonitorStateChange: onMonitorStateChangeHandler,
        });
      }

      incomingCallRingtone.volume = 0.1;
      incomingCallRingtone.loop = true;

      const devices = getSelectedDevices();
      incomingCallRingtone.setSinkId(
        devices.ringing || devices.output || "default"
      );
    }
  };

  const focusDisplay = () => {
    if (document.getElementById("phone-display") !== document.activeElement) {
      setDisplayIsFocused(true);
      document.getElementById("phone-display").focus();
    }
  };

  const pickupFromGroup = () => {
    setDialedNumber("Capturando ligação...");

    setTimeout(() => {
      call("*2");
    }, 300);
  };

  const toggleTransferShown = () => {
    setTransferShown((v) => !v);
  };

  useEffect(() => {
    load();
  }, [auth]);

  const contextValues = {
    callState,
    dockState,
    toggleKeyboard,
    keypadIsOpen,
    disconnect,
    hangup,
    pickup,
    hangup,
    maskedDialedNumber,
    dial,
    setDialedNumber,
    setDisplayIsFocused,
    backspace,
    statusMessage,
    initiateCall,
    getSelectedDevices,
    changeDevices,
    toggleMute,
    muted,
    toggleHold,
    onHold,
    initiateTransfer,
    transferShown,
    transferTo,
    setTransferShown,
    toggleTransferShown,
    focusDisplay,
    setVisibility,
    showSoftphone,
    pickupFromGroup,
    isLoading: loading.state === "loading",
    isError: loading.state === "error" ? loading.error : false,
    isLoaded: loading.state === "loaded",
  };

  return (
    <softphoneContext.Provider value={contextValues}>
      {children}
    </softphoneContext.Provider>
  );
};

export const useSoftphone = () => {
  return useContext(softphoneContext);
};
