import {
  SetupWizardConfig,
  saveSetupWizardConfig,
} from "../model/setupWizard.model";
import { saveIvrAudio } from "./queue.service";

export async function saveSetupWizard(
  companyId: string,
  config: SetupWizardConfig
) {

  config.ivrData = {
    singleQueueId: "",
    singleQueueTempId: "",
    type: "multi-queue",
  }

  const result = await saveSetupWizardConfig(config, companyId);
  // const result = await saveSetupWizardConfig(config);

  const promises = [
    saveIvrAudio(companyId, config.messages?.greeting, false),
    saveIvrAudio(companyId, config.messages?.error, false),
  ];

  await Promise.all(promises);
  return result as any;
}
