import { firestore } from "./firebase/firebaseInit";
import { collection, doc, getDoc, getDocs } from "@firebase/firestore";
import { functions } from "./firebase/firebaseInit";
import { httpsCallable } from "@firebase/functions";

export const getExtensions = async (auth) => {
    const companyId = auth.user.token.claims.company;
    const snap = await getDocs(
        collection(firestore, `companies/${companyId}/users`)
    );

    let data = snap.docs.reduce((prev, curr) => {
        return [...prev, { ...curr.data(), id: curr.id }];
    }, []);

    data = data.sort((a, b) => {
        if (a.extensionNumber === b.extensionNumber) return 0;
        if (a.admin) return -1;
        if (b.admin) return 1;

        return parseInt(a.extensionNumber - b.extensionNumber);
    });

    return data;
};

export const getExtension = async (id) => {
    const docRef = doc(firestore, `users/${id}`);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
};

export const updateExtension = async (auth, data) => {
    data.companyId = auth.user.token.claims.company;
    const updateExtension = httpsCallable(functions, "updateExtension");
    return await updateExtension(data);
};

export const createExtension = async (auth, extensionData) => {
    const companyId = auth.user.token.claims.company;
    extensionData.companyId = companyId;
    const updateExtension = httpsCallable(functions, "createExtension");
    return await updateExtension(extensionData);
};

export const deleteExtension = async (auth, id) => {
    const companyId = auth.user.token.claims.company;
    const deleteExtension = httpsCallable(functions, "deleteExtension");
    return await deleteExtension({ companyId, id });
};

export const changeExtensionPassword = async (auth, data) => {
    data.companyId = auth.user.token.claims.company;
    const changeExtensionPassword = httpsCallable(
        functions,
        "changeExtensionPassword"
    );
    return await changeExtensionPassword(data);
};

export const getDashboardPeers = async (auth) => {
    const companyId = auth.user.token.claims.company;
    const snap = await getDocs(
        collection(firestore, `companies/${companyId}/users`)
    );

    const data = snap.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
    });

    return data;
};
