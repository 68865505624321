import Tippy from "@tippyjs/react";
import tooltips from "../lang/tooltips";
import "./tooltip.css";
import "tippy.js/animations/shift-away.css";

function Tooltip({ children, messageId, className = "", placement = "" }) {
  return (
    <div className={`inline-block ${className}`}>
      <Tippy
        interactive={true}
        placement={placement || "right-start"}
        delay={[300, 150]}
        content={tooltips[messageId] || ""}
        animation="shift-away"
        duration={[300, 50]}
      >
        <div className="inline-block">{children}</div>
      </Tippy>
    </div>
  );
}

export default Tooltip;
