import validator from "validator";
import { cpf, cnpj } from "cpf-cnpj-validator";

export const validateEmail = (val) => {
    const diacriticRegex = /([à-ü]|[À-Ü])/g
    if (val && !validator.isEmail(val))
        return { error: true, message: "inválido" };
    if (val && val.match(diacriticRegex)) 
      return { error: true, message: "inválido" };
    return { error: false };
};

export const validateCpfCnpj = (val) => {
    if (!cpf.isValid(val) && !cnpj.isValid(val))
        return { error: true, message: "inválido" };
    return { error: false };
};

export const validateExtension = (val) => {
    if (!validator.isInt(val.toString()))
        return { error: true, message: "Inválido" };
    if(parseInt(val) >= 9000)
        return {error: true, message: "Inválido"}
    return { error: false };
};

export const validateInteger = (val) => {
    if (!validator.isInt(val.toString()))
        return { error: true, message: "Inválido" };
    return { error: false };
};

export const validateDateRange = (range) => {
    if (range !== false && (!Array.isArray(range) || range.length !== 2))
        return { error: true, message: "Inválido" };

    for (let i = 0; i < range.length; i++) {
        validateDate(range[i]);
    }

    if (range[0] > range[1]) return { error: true, message: "Inválido" };

    return { error: false };
};

export const validateDate = (date) => {
    if (!/^([0-1][0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(date))
        return { error: true, message: "Inválido" };
    return { error: false };
};

export const validateHour = (hour) => {
    if (hour.length > 0 && !/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/.test(hour))
        return { error: true, message: "Inválido" };
    return { error: false };
};
