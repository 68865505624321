import Card from "../../components/layout/Card";
import CardTitle from "../../components/layout/CardTitle";
import { SetupWizardConfig } from "../../model/setupWizard.model";
import { Setter } from "../../utils/types";
import TextInput from "../../components/layout/TextInput";
import useFormValidation from "../../hooks/formValidation";
import { SetupWizardControls } from "../../components/setupWizard/SetupWizardControls";
import { useEffect, useState } from "react";
import { buildPredefinedMessages } from "../../components/ivrs/PredefinedMessages";
import { PredefinedAudioMessage } from "../../model/queue.model";
import { access } from "fs";

export function SetupIvrPage(props: SetupIvrPageProps) {
  const [allowNext, setAllowNext] = useState(false);

  const setName = (val: string) => {
    if (validate()) {
      props.setter((v) => ({
        ...v,
        account: {
          name: v.account?.name || val,
          fantasyName: val,
        },
      }));
    }
  };

  const formValidation = useFormValidation();

  const validate = () => {
    if (formValidation.validateForm()) {
      setAllowNext(true);
      return true;
    } else {
      setAllowNext(false);
      return false;
    }
  };

  const prepAudioMessages = (companyName: string) => {
    const greetingMessages = buildPredefinedMessages(
      "greeting",
      companyName || ""
    );
    const errorMessages = buildPredefinedMessages("error", companyName || "");

    const greeting: PredefinedAudioMessage = {
      predefined: 0,
      text: greetingMessages[0],
      type: "predefined",
      useOptions: true,
      id: "greeting",
    };

    const error: PredefinedAudioMessage = {
      predefined: 0,
      text: errorMessages[0],
      type: "predefined",
      useOptions: false,
      id: "error",
    };

    props.setter((v) => ({
      ...v,
      messages: {
        greeting,
        error,
      },
    }));
  };

  useEffect(() => {
    if (props.value.account?.fantasyName) {
      setAllowNext(true);
    } else {
      setAllowNext(false);
    }
  }, [props.value.account]);

  useEffect(() => {
    const hasName = props.value.account?.name;
    const hasFantasyName = props.value.account?.fantasyName;
    const message = hasFantasyName ? hasFantasyName : hasName;

    prepAudioMessages(message || "");
  }, [props.value.account?.fantasyName]);

  return (
    <>
      <SetupWizardControls
        next={props.next}
        back={props.back}
        nextDisabled={!allowNext}
      />
      <Card className="w-full 2xl:p-12 p-10">
        <CardTitle>Sua Central de Atendimento</CardTitle>
        <p className="text-gray-dark">
          Que tal ter seus clientes direcionados para o atendente correto de
          forma automática? A central telefônica irá atender ligações
          automaticamente e direcionar para uma das filas cadastradas. A chamada
          será distribuida para um dos atendentes diponíveis de acordo com a
          opção que seu cliente digitar.
        </p>
        <p className="text-gray-dark mt-4">
          Primeiramente, como podemos nos referir à sua empresa?
        </p>
        <div className="mt-4 flex items-center">
          <span className="font-bold text-gray-dark mr-4 pt-4">
            Ex.: Seja bem-vindo a
          </span>
          <div className="h-[57px] flex items-end">
            <TextInput
              value={props.value.account?.fantasyName || ""}
              setter={setName}
              //@ts-ignore
              onChange={validate}
              formValidation={formValidation}
              placeholder="Sua empresa..."
              required
            />
          </div>
          <span className="font-bold text-gray-dark ml-2 pt-4">
            . Digite 1 para...
          </span>
        </div>
      </Card>
    </>
  );
}

export type SetupIvrPageProps = {
  value: SetupWizardConfig;
  setter: Setter<SetupWizardConfig>;
  next: () => void;
  back: () => void;
};
