import { useState } from "react";

const statusIndex = {
    offline: 0,
    UNAVAILABLE: 0,
    online: 1,
    receiving: 3,
    calling: 2,
    oncall: 4,
};

export const useDashboardPeers = () => {
    const [extensions, setExtensions] = useState([]);

    const initExtensions = (ext) => {
        const extensions = ext.map((v) => ({
            id: v.id,
            extension: v.extensionNumber,
            name: v.name,
            state: "offline",
        }));

        setExtensions(extensions);
    };

    const mergeStatus = (status) => {
        setExtensions((ext) => {
            const changes = status.map((v) => ({
                extension: v.peer.extensionNumber,
                destination: v.destination || "",
                state: v.state === "UNAVAILABLE" ? "offline" : v.state,
                date: v.date,
            }));

            let updatedExtensions = ext.map((e) => {
                const update = changes.filter(
                    (v) => v.extension.toString() === e.extension.toString()
                )[0];

                if (update) {
                    return {
                        ...e,
                        ...update,
                    };
                } else {
                    return e;
                }
            });

            let offlinePeers = updatedExtensions.filter(
                (v) => v.state === "offline"
            );
            let onlinePeers = updatedExtensions.filter(
                (v) => v.state !== "offline"
            );

            offlinePeers = offlinePeers.sort((a, b) => {
                if (a.extension < b.extension) return -1;
                if (a.extension > b.extension) return 1;
                return 0;
            });
            onlinePeers = onlinePeers.sort((a, b) => {
                if (a.extension < b.extension) return -1;
                if (a.extension > b.extension) return 1;
                return 0;
            });

            updatedExtensions = [...onlinePeers, ...offlinePeers];

            return updatedExtensions;
        });
    };

    return {
        initExtensions,
        mergeStatus,
        extensions,
    };
};
