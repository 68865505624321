import { IvrHook } from "../../hooks/IvrPageHook";
import { AudioSelector } from "../audioSelection/audioSelector";
import Card from "../layout/Card";
import { useEffect, useState } from "react";

function MessagesConfig(props: MessagesConfigProps) {
  const { ivrHook: ivr } = props;
  
  const onSaveHandler = async () => {
    if (ivr.formValidation.validateForm()) {
      await ivr.formValidation.saveForm();
    } else {
      console.log("um ou mais não validaram");
    }
  };

  const greetingDescription = (
    <>
      <p className="text-gray-dark text-xs 2xl:text-base">
        Mensagem de áudio de saudação que será tocada quando seu cliente ligar
        para seu número. Deve incluir as opções da fila.
      </p>
      <p className="text-gray-dark mb-4 text-xs 2xl:text-base">
        Ex.: "Seja bem-vindo! Digite 1 para..."
      </p>
    </>
  );

  const errorDescription = (
    <>
      <p className="text-gray-dark text-xs 2xl:text-base">
        Mensagem de áudio opcional para quando seu cliente digitar uma opção
        inválida no atendimento automático.
      </p>
      <p className="text-gray-dark mb-4 text-xs 2xl:text-base">
        Ex.: "Opção inválida, tente novamente."
      </p>
      <p className="text-gray-dark mb-4 text-xs 2xl:text-base">
        Também é possível encaminhar diretamente a uma fila de atendimento.
      </p>
    </>
  );

  useEffect(() => {
    if (ivr.onSaveEvent) {
      const unsubscribe = ivr.onSaveEvent.on(onSaveHandler);
      return () => unsubscribe;
    }
  }, [ivr.onSaveEvent]);

  return (
    <>
      <AudioSelector
        id="greeting"
        title="Mensagem de Saudação e Opções"
        value={ivr.greeting}
        setter={ivr.setGreeting}
        useOptions={true}
        description={greetingDescription}
        formValidation={ivr.formValidation}
        queues={ivr.queues}
        required
      />
      <AudioSelector
        id="error"
        title="Mensagem de Opção Inválida"
        value={ivr.error}
        setter={ivr.setError}
        useOptions={false}
        description={errorDescription}
        formValidation={ivr.formValidation}
        queues={ivr.queues}
        required
      />
      {/* <AudioSelector
        type="greeting"
        title="Mensagem de Saudação e Opções"
        description={greetingDescription}
        shouldAppendOptions={true}
        formValidation={formValidation}
        audioHook={ivrHook.audioMessage}
        className="mb-10"
        required
      /> */}
      {/* <AudioSelector
        type="error"
        title="Mensagem de Opção Inválida"
        description={errorDescription}
        formValidation={formValidation}
        audioHook={ivrHook.audioMessage}
        required
      /> */}
    </>
  );
}

export default MessagesConfig;

export type MessagesConfigProps = {
  ivrHook: IvrHook;
};
