import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from "react";
import { useAuth } from "./firebase/auth";
import { getAllUsersContacts, createUserContact } from "./userContacts";
import { getAccountData } from "../model/account.model";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const SetupWizardContext = createContext({});

export const SetupWizardProvider = (props: SetupWizardContext) => {
    const auth = useAuth();

    const history = useHistory();

    const load = async () => {
        const account = await getAccountData(auth.user.token.claims.company);
        if(!account) toast("Conta não encontrada", {type: "error"});

        if(!account.finalizedSetupWizard)
            history.push("/setup-wizard");
    }

    useEffect(() => {
        if(auth.user) {
            load();
        }
    }, [auth.user]);

    const contextValues = {};

    return (
        <SetupWizardContext.Provider value={contextValues}>
            {props.children}
        </SetupWizardContext.Provider>
    );
};

export type SetupWizardContext = {
    children: React.ReactNode
}
