import { useCallback, useEffect, useState } from "react";
import DateInput from "./DateInput";
import InputGroup from "./InputGroup";
import _ from "lodash";

function DateRangeInput({
    title,
    value,
    setter,
    className,
    formValidation,
    required,
}) {
    const [id] = _.uniqueId("daterange-");
    const [error, setError] = useState("");
    const [range, setRange] = useState({ from: "", to: "" });

    const validate = useCallback(() => {
        if (required) {
            if (!range || !range.from || !range.to) {
                setError("Obrigatório");
                return false;
            }
        }

        if (range.from > range.to) {
            setError("Data inicial deve ser menor que final");
            return false;
        }

        setError(false);
        return true;
    }, [required, setError, range]);

    const setFrom = useCallback(
        (val) => {
            setter({ ...value, from: val });
            setRange({ ...value, from: val });
        },
        [setter, value]
    );

    const setTo = useCallback(
        (val) => {
            setter({ ...value, to: val });
            setRange({ ...value, to: val });
        },
        [setter, value]
    );

    const onChangeHandler = () => {
        if (error) {
            setError(false);
        }
    };

    useEffect(() => {
        setRange(value);
    }, [value]);

    useEffect(() => {
        if (formValidation) {
            formValidation.registerValidationCallback({ id, validate });
            return () => formValidation.unregisterValidationCallback(id);
        }
    }, [id, validate, formValidation, range]);

    const textColor = error ? "text-red" : "text-gray-dark";

    return (
        <div className={className}>
            <InputGroup title={title} error={error}>
                <DateInput
                    value={value.from}
                    setter={setFrom}
                    onChange={onChangeHandler}
                    error={error ? true : false}
                />
                <span
                    className={`px-2 2xl:px-4 text-sm 2xl:text-base ${textColor}`}
                >
                    até
                </span>
                <DateInput
                    value={value.to}
                    setter={setTo}
                    onChange={onChangeHandler}
                    error={error ? true : false}
                />
            </InputGroup>
        </div>
    );
}

export default DateRangeInput;
