import TableCell from "./TableCell";

function TableRow({ rowData, isLast = false, className = "" }) {
    const row = rowData || [];

    return (
        <tr
            className={`text-xs 2xl:text-sm text-primary border-t border-white-dark ${className}`}
        >
            {row.map((cell, i) => (
                <TableCell
                    key={i}
                    isLast={isLast}
                    isBorderLeft={i === 0}
                    isBorderRight={i === row.length - 1}
                >
                    {cell}
                </TableCell>
            ))}
        </tr>
    );
}

export default TableRow;
