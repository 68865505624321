import Modal from "../layout/Modal";
import Button from "../layout/Button";

function ErrorModal({error = false, ...props }) {

    return (
        <Modal title="Erro" className="min-w-[400px]" shown={error} {...props}>
            <div>{error}</div>
            <div className="mt-4">
                <Button color="red" className="float-right" onClick={() => props.onClose()}>
                    Fechar
                </Button>
            </div>
        </Modal>
    );
}

export default ErrorModal;
