import TableHeaderCell from "./TableHeaderCell";
import _ from "lodash";

function TableHeaderRow({ headerData }) {
    return (
        <tr className="text-left text-xs 2xl:text-sm">
            {headerData &&
                headerData.map((cell, i) => {
                    const title =
                        _.isObject(cell) && _.has(cell, "title")
                            ? cell.title
                            : cell;
                    const className =
                        _.isObject(cell) && _.has(cell, "className")
                            ? cell.className
                            : "";
                    return (
                        <TableHeaderCell
                            key={i}
                            isLeftBorder={i === 0}
                            isRightBorder={headerData.length - 1 === i}
                            className={className}
                        >
                            {title}
                        </TableHeaderCell>
                    );
                })}
        </tr>
    );
}

export default TableHeaderRow;
