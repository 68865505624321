import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTimes,
    faCrown,
    faVoicemail,
} from "@fortawesome/free-solid-svg-icons";
import Card from "../layout/Card";
import ExtensionCardFooter from "./ExtensionCardFooter";

import Avatar from "../layout/Avatar";
import avatar from "../../images/default_avatar/5.png";

function ExtensionCard({ extension, onClick, onDelete }) {
    const redirectHandler = () => {};

    return (
        <div className="relative min-h-[250px] 2xl:min-h-[280px] cursor-pointer">
            <Card
                className="w-full h-full pb-9 hover:bg-white/70 transition delay-75"
                onClick={() => onClick(extension.id)}
            >
                <div className="flex justify-center mb-4">
                    <Avatar
                        avatar={avatar}
                        className="bg-gray/50 w-12 h-12 2xl:w-14 2xl:h-14"
                    />
                </div>
                <div className="text-primary font-bold text-sm 2xl:text-base text-center">
                    <span>{extension.name}</span>
                    {extension.admin && (
                        <span>
                            <FontAwesomeIcon
                                icon={faCrown}
                                className="text-yellow ml-2"
                                title="Administrador"
                            />
                        </span>
                    )}
                    {extension.recording && (
                        <span>
                            <FontAwesomeIcon
                                icon={faVoicemail}
                                className="text-gray-dark ml-2"
                                title="Gravação de chamada"
                            />
                        </span>
                    )}
                </div>
                <div className="text-gray-dark text-sm 2xl:text-base font-bold text-center">
                    Ramal {extension.extensionNumber}
                </div>
            </Card>
            <ExtensionCardFooter>
                {/* <FontAwesomeIcon
                    icon={faShare}
                    className="text-gray-dark text-lg"
                    onClick={redirectHandler}
                /> */}
                {!extension.admin && (
                    <FontAwesomeIcon
                        icon={faTimes}
                        className="text-gray-dark text-base 2xl:text-lg float-right"
                        onClick={() => onDelete(extension)}
                    />
                )}
            </ExtensionCardFooter>
        </div>
    );
}

export default ExtensionCard;
