import { useState } from "react";
import CreateExtensionCard from "../../components/extensions/CreateExtensionCard";
import ExtensionCard from "../../components/extensions/ExtensionCard";
import CardGrid from "../../components/extensions/ExtensionCardGrid";
import Card from "../../components/layout/Card";
import CardTitle from "../../components/layout/CardTitle";
import { SetupWizardAccountMetadata, SetupWizardConfig } from "../../model/setupWizard.model";
import { Setter } from "../../utils/types";
import SetupWizardExtensionModal from "../../components/setupWizard/setupWizardExtensionModal";
import { SetupWizardControls } from "../../components/setupWizard/SetupWizardControls";

export function SetupExtensionsPage(props: SetupExtensionsPageProps) {
  const [showExtensionModal, setShowExtensionModal] = useState(false);
  const [selectedExtensionIndex, setSelectedExtensionIndex] = useState<
    number | null
  >(null);

  const onEdit = (index: number) => {
    setShowExtensionModal(true);
    setSelectedExtensionIndex(index);
  };

  const onCreate = () => {
    setShowExtensionModal(true);
    setSelectedExtensionIndex(null);
  };

  const onRemove = (index: number) => {
    const newExts = props.value.extensions!;
    newExts.splice(index, 1);
    props.setter((v) => ({
      ...v,
      extensions: newExts,
    }));
  };

  return (
    <>
      <SetupWizardControls next={props.next} back={props.back} />
      <Card className="w-full 2xl:p-12 p-10">
        <CardTitle>Seus Atendentes</CardTitle>
        <p className="text-gray-dark">
          Você possui atendentes que necessitam ramais? Cadastre eles abaixo.
          Não se preocupe com todos os dados de cada atendente, todos receberão
          um link por e-mail para finalizar seu cadastro.
        </p>
      </Card>
      <CardGrid className="mt-4">
        {props.value.extensions &&
          props.value.extensions.map((extension, i) => (
            <ExtensionCard
              key={i}
              extension={extension}
              onClick={() => onEdit(i)}
              onDelete={() => onRemove(i)}
            />
          ))}
        <CreateExtensionCard
          onClick={onCreate}
          limitExtensions={props.account?.usersLimit || 0}
          numExtensions={props.value.extensions?.length || 0}
        />
      </CardGrid>
      <SetupWizardExtensionModal
        shown={showExtensionModal}
        onClose={() => setShowExtensionModal(false)}
        onSave={() => setShowExtensionModal(false)}
        value={props.value}
        setter={props.setter}
        recordingLimit={props.account?.recordingLimit || 0}
        extensionIndex={selectedExtensionIndex}
      />
    </>
  );
}

export type SetupExtensionsPageProps = {
  value: SetupWizardConfig;
  setter: Setter<SetupWizardConfig>;
  account: SetupWizardAccountMetadata;
  next: () => void;
  back: () => void;
};
