import { useEffect, useState, useRef } from "react";
import Card from "../../components/layout/Card";
import { SetupWizardControls } from "../../components/setupWizard/SetupWizardControls";
import { SetupWizardConfig } from "../../model/setupWizard.model";
import { Setter } from "../../utils/types";
// import { Document, pdfjs, Page } from 'react-pdf';
// import pdfFile from "../../assets/termsVoki.pdf";
import termsVokiHtml from "../../assets/termsVokiHtml";

export function SetupTermsPage (props: SetupTermsPageProps) {
  const [allowNext, setAllowNext ]= useState(false);
  const [aceppted, setAccepted] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const container = useRef(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }
  
  useEffect(() => {
    if (props.value.terms?.termsAceppted === true) {
      setAllowNext(true);
    } else {
      setAllowNext(false);
    }
  }, [props.value, aceppted]);
  
  
  useEffect(() => {
    props.setter((v) => ({
      ...v,
      terms: {
        termsAceppted: aceppted,
        termsAcepptedDate: new Date(),
      },
    }));
  }, [aceppted]);
  
  return (
    <>
      <SetupWizardControls
        next={props.next}
        back={props.back}
        nextDisabled={!allowNext}
      />
      <Card className="w-full 2xl:p-12">
        <div className="flex justify-center max-h-96">
          <div className="overflow-y-auto overflow-x-hidden max-h-full" dangerouslySetInnerHTML={{ __html: termsVokiHtml }} />
        </div>
        <div className="flex justify-center mt-2">
          <fieldset className={`inline-flex items-center `}>
          <label className="text-primary"> Li e concordo integralmente com os termos de adesão
            <input
            className="ml-2"
              type="checkbox"
              onChange={() =>
                aceppted === false ? setAccepted(true) : setAccepted(false)
              }
              checked={props.value.terms?.termsAceppted}
            />
          </label>
          </fieldset>
        </div>
      </Card>
    </>
  )
}

export type SetupTermsPageProps = {
  value: SetupWizardConfig;
  setter: Setter<SetupWizardConfig>;
  next: () => void;
  back: () => void;
};
