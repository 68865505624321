import Modal from "../layout/Modal";
import Button from "../layout/Button";

function LastOfQueueModal({ queues, onConfirm, ...props }) {
    return (
        <Modal title="Confirmação" {...props}>
            <div className="text-sm 2xl:text-base max-w-[400px]">
                {queues?.length > 1 ? (
                    <>
                        <p>
                            Este usuário é o único membro das filas{" "}
                            <strong>
                                {queues.map((v, i) => {
                                    if (queues.length === i + 2) {
                                        return v.name + " e ";
                                    } else if (queues.length === i + 1) {
                                        return v.name;
                                    } else {
                                        return v.name + ", ";
                                    }
                                })}
                            </strong>
                            .
                        </p>
                        <p className="mt-4">
                            Ao deletar este usuário, as filas mencionadas não
                            irão funcionar até que um novo atendente seja
                            vinculado.{" "}
                            <strong>
                                Você tem certeza que deseja continuar esta
                                operação?
                            </strong>
                        </p>
                    </>
                ) : (
                    <>
                        <p>
                            Este usuário é o único membro da fila{" "}
                            <strong>"{queues && queues[0].name}"</strong>.
                        </p>
                        <p className="mt-4">
                            Ao deletar este usuário, a fila mencionada não irá
                            funcionar até que um novo atendente seja vinculado.{" "}
                            <strong>
                                Você tem certeza que deseja continuar esta
                                operação?
                            </strong>
                        </p>
                    </>
                )}
            </div>
            <Button className="float-left mt-8" color="red" onClick={onConfirm}>
                Confirmar
            </Button>
            <Button
                className="float-right mt-8"
                outline
                onClick={props.onClose}
            >
                Voltar
            </Button>
        </Modal>
    );
}

export default LastOfQueueModal;
