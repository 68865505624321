import { useState, useEffect, useCallback } from "react";
import PhoneOrExtInput from "../layout/PhoneOrExtInput";
import Select from "../layout/Select";
import _ from "lodash";

function RedirectInputGroup({ destination, setDestination, formValidation }) {
    const [id] = _.uniqueId("redirect-");
    const [option, setOption] = useState("0");
    const [error, setError] = useState(false);

    const validate = useCallback(() => {
        if (option === "1" && !destination) {
            setError(true);
            return false;
        }
        setError(false);
        return true;
    }, [setError, option, destination]);

    const optionHandler = (v) => {
        setOption(v);
        if (v === "0" || v === "") {
            setError(false);
            setDestination("");
        }
    };

    useEffect(() => {
        if (option === "0" && destination) {
            setOption("1");
        }
        setDestination(destination);
    }, [destination, option]);

    useEffect(() => {
        if (formValidation) {
            formValidation.registerValidationCallback({ id, validate });
            return () => formValidation.unregisterValidationCallback(id);
        }
    }, [formValidation, validate, id]);

    return (
        <>
            <Select
                title="Ação quando fora de expediente"
                value={option}
                setter={optionHandler}
                formValidation={formValidation}
                className="2xl:w-[350px]"
                required
            >
                <option value="0">Desligar</option>
                <option value="1">Redirecionar para número</option>
            </Select>
            <div className="mt-4">
                {option === "1" && (
                    <>
                        <PhoneOrExtInput
                            title="Número do redirecionamento"
                            value={destination || ""}
                            setter={setDestination}
                            error={error}
                        />
                    </>
                )}
            </div>
        </>
    );
}

export default RedirectInputGroup;
