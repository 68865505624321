import { useEffect, useState, useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { getCompanyData } from "../services/company";
import { useAuth } from "../services/firebase/auth";

import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageTitle from "../components/layout/PageTitle";
import Card from "../components/layout/Card";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import ContentWrapper from "../components/layout/ContentWrapper";
import CardTitle from "../components/layout/CardTitle";

import Tooltip from "../tooltip/Tooltip";

function CompanyPage() {
  const [companyID, setCompanyID] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const auth = useAuth();

  const copyToClipboard = useCallback(async (text) => {
    try {
      await navigator.clipboard.writeText(
        `https://us-central1-voki-a6adb.cloudfunctions.net/piperunCall/${text}`
      );
    } catch (err) {
      console.log("Error when copyToClipboard.", err);
    }
  }, []);

  const load = useCallback(async () => {
    const data = await getCompanyData(auth);

    setIsLoading(false);
    setCompanyID(data.id);
  }, [auth]);

  useEffect(() => {
    load();
  }, [auth, load]);

  return (
    <SidebarLayout>
      <PageTitle>
        <div>Ajuda</div>
      </PageTitle>
      <PageLoadSpinner show={isLoading} />
      <ContentWrapper show={!isLoading} className="mb-6">
        <Card className="w-full">
          <CardTitle className="flex justify-between items-center">
            <div>Manual</div>
          </CardTitle>
          <>
            <p className="text-gray-dark text-sm 2xl:text-base pb-3">
              Quer entender alguma funcionalidade específica da plataforma?
              Acesse o nosso manual
              <a
                href="https://voki-manual-dev.web.app/docs/sobre-voki/"
                target="_blank"
                className="cursor-pointer text-green-bright text-sm font-bold no-underline hover:underline pl-1.5 pr-1.5"
              >
                clicando aqui
              </a>
              e confira tudo o que a Voki tem para oferecer para você! 😉
            </p>
          </>
          <div className="mb-4 grid grid-cols-4 gap-4"></div>
        </Card>
      </ContentWrapper>

      <PageTitle>
        <div>Integrações</div>
      </PageTitle>
      <PageLoadSpinner show={isLoading} />
      <ContentWrapper show={!isLoading}>
        <Card className="w-full">
          <CardTitle className="flex justify-between items-center">
            <div>PipeRun</div>
          </CardTitle>
          <>
            <p className="text-gray-dark text-sm 2xl:text-base pb-3">
              Para saber como configurar a integração da Voki com o PipeRun,
              assista o vídeo abaixo.
            </p>

            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/AKVohXOJJr4?si=2wu24w6Ivd0tL1u6"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>

            <p className="text-gray-dark text-sm 2xl:text-base pt-4">
              Sua URL PipeRun
              <a className="pl-2 font-bold">
                https://us-central1-voki-a6adb.cloudfunctions.net/piperunCall/
                {companyID}
                <Tooltip messageId={"copy-to-clipboard"} placement="right">
                  <FontAwesomeIcon
                    icon={faCopy}
                    className="text-lg ml-4 cursor-pointer"
                    onClick={() => copyToClipboard(companyID)}
                  />
                </Tooltip>
              </a>
            </p>
          </>
          <div className="mb-4 grid grid-cols-4 gap-4"></div>
        </Card>
      </ContentWrapper>
    </SidebarLayout>
  );
}

export default CompanyPage;
