import Modal from "../layout/Modal";
import Button from "../layout/Button";
import { useAuth } from "../../services/firebase/auth";
import { deleteUserContacts } from "../../services/userContacts";
import { useContacts } from "../../services/UserContactsContext";

function DeleteUserContactModal({
    shown,
    userContactId,
    onClose,
    onConfirm,
    setLoading,
}) {
    const auth = useAuth();
    const contacts = useContacts();

    const confirmHandler = async () => {
        setLoading && setLoading(true);
        onClose && onClose();
        await deleteUserContacts(auth, userContactId);
        contacts.reload();
        setLoading && setLoading(false);
        onConfirm && onConfirm();
    };

    return (
        <Modal
            shown={shown}
            onClose={onClose}
            title="Deletar Contato"
            className="min-w-[400px]"
        >
            <div className="my-6 text-primary text-sm 2xl:text-base">
                Você tem certeza que deseja deletar este contato?
            </div>
            <div className="flex justify-between">
                <Button color="primary" onClick={onClose} outline>
                    Cancelar
                </Button>
                <Button color="red" onClick={confirmHandler}>
                    Confirmar
                </Button>
            </div>
        </Modal>
    );
}

export default DeleteUserContactModal;
