function TableCell({
    isLast = false,
    isBorderLeft = false,
    isBorderRight = false,
    children,
}) {
    let border = "";
    if (isLast && isBorderLeft) {
        border = " rounded-bl-15";
    } else if (isLast && isBorderRight) {
        border = " rounded-br-15";
    }

    return <td className={`p-3 2xl:p-4 bg-white${border}`}>{children}</td>;
}

export default TableCell;
