const predefined = (companyName: string): PredefinedMessages => ({
  greeting: [`Seja bem-vindo à ${companyName}!`],
  error: [`Opção inválida, tente novamente.`],
  away: ["Estamos ausentes", "aaa", "bbb"],
});

export const buildPredefinedMessages = (
  type: string,
  companyName: string
): string[] => {
  return predefined(companyName)[type];
};

export type PredefinedMessages = {
  [id: string]: string[];
};
