import { useSoftphone } from "../../services/softphone/softphoneContext";
function SoftphoneLayout({ width, children }) {
    const softphone = useSoftphone();
    return (
        <div
            className="fixed top-1/2 right-0 transform -translate-y-1/2 flex items-center transition-movement ease-in-out duration-300"
            style={{
                right: softphone.keypadIsOpen ? "0" : `-${width}px`,
            }}
        >
            {children}
        </div>
    );
}

export default SoftphoneLayout;
