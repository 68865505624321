function IvrParticipant({ participant }) {
    return (
        <div className="border-gray border-2 py-1 px-2 rounded-10 text-xs 2xl:text-sm text-gray-dark font-medium flex justify-between">
            <span>{participant && participant.name}</span>
            <span>{participant && participant.extensionNumber}</span>
        </div>
    );
}

export default IvrParticipant;
