import { useEffect, useState, useCallback } from "react";
import io from "socket.io-client";
import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageTitle from "../components/layout/PageTitle";
import ContentWrapper from "../components/layout/ContentWrapper";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import Card from "../components/layout/Card";
import DashboardCardGrid from "../components/dashboard/DashboardCardGrid";
import { getDashboardPeers } from "../services/extensions";
import { useAuth } from "../services/firebase/auth";
import DashboardExtensionCard from "../components/dashboard/DashboardExtensionCard";
import DashboardCallCard from "../components/dashboard/DashboardCallCard";
import { getQueues } from "../model/queue.model";
import { useDashboardPeers } from "../hooks/dashboardPeers";

function DashboardPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [socket, setSocket] = useState();
  const [queues, setQueues] = useState([]);
  const [calls, setCalls] = useState([]);

  const auth = useAuth();
  const peers = useDashboardPeers();

  const load = useCallback(async () => {
    const ext = await getDashboardPeers(auth);
    peers.initExtensions(ext);

    const queues = await getQueues(auth);
    setQueues(queues);

    setIsLoading(false);
  }, [auth]);

  useEffect(() => {
    if (peers.extensions && peers.extensions.length > 0 && !socket) {
      setSocket(startSocket());
    }
  }, [peers.extensions]);

  const startSocket = () => {
    const socket = io(process.env.REACT_APP_WS, {
      path: "/ws",
      query: {
        companyId: auth.user.token.claims.company,
      },
      secure: false,
    });

    socket.on("connect", (socket) => {
      console.log("dashboard socket connected");
    });

    socket.on("initPeerStatus", (evt) => {
      peers.mergeStatus(evt);
    });

    socket.on("peerchange", (evt) => {
      peers.mergeStatus([evt]);
    });

    socket.on("addqueue", (evt) => {
      callAddedToQueue(evt);
    });

    socket.on("removequeue", (evt) => {
      callRemovedFromQueue(evt);
    });

    socket.on("initQueues", (evt) => {
      if (evt) {
        const vals = evt.map((v) => ({ ...v, date: parseInt(v.date) }));
        setCalls(vals);
      }
    });

    return socket;
  };

  const callAddedToQueue = (call) => {
    setCalls((q) => [...q, call]);
  };

  const callRemovedFromQueue = (id) => {
    setCalls((q) => q.filter((v) => v.id !== id));
  };

  useEffect(() => {
    load();
  }, [auth, load]);

  return (
    <SidebarLayout>
      <PageLoadSpinner show={isLoading}></PageLoadSpinner>
      <ContentWrapper show={!isLoading}>
        <div className="flex w-full">
          <div className="flex-grow mr-4">
            <PageTitle>Ramais</PageTitle>
            <DashboardCardGrid>
              {peers.extensions &&
                peers.extensions.map((extension) => (
                  <DashboardExtensionCard
                    key={extension.id}
                    extension={extension}
                  />
                ))}
            </DashboardCardGrid>
          </div>
          <div className="w-1/4">
            <PageTitle>Filas</PageTitle>
            <div className="grid gap-4">
              {calls &&
                calls.map((c) => (
                  <DashboardCallCard key={c.id} call={c} queues={queues} />
                ))}

              {(!calls || calls.length === 0) && (
                <Card className="flex justify-center py-12">
                  <span className="font-bold text-sm text-gray-dark 2xl:text-lg">
                    Nenhuma ligação na fila
                  </span>
                </Card>
              )}
            </div>
          </div>
        </div>
      </ContentWrapper>
    </SidebarLayout>
  );
}

export default DashboardPage;
