import { useState } from "react";
import { useAuth } from "../services/firebase/auth";
import _ from "lodash";

function useTablePagination(getFunc, numLines = 3) {
    const [data, setData] = useState([]);
    const [filters, setFilters] = useState({});
    const [hasMore, setHasMore] = useState(true);
    const [hasLoaded, setHasLoaded] = useState(false);

    const auth = useAuth();

    const search = async (filters = {}) => {
        setHasLoaded(true);
        setFilters(filters);
        const result = await getFunc(auth, 0, numLines, filters);
        result && setData(result);
        if (result.length < numLines) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    };

    const loadMore = async () => {
        const lastDoc = !_.isEmpty(data) ? _.last(data).doc : 0;
        const result = await getFunc(auth, lastDoc, numLines, filters);
        result && setData((curr) => [...curr, ...result]);
        if (result.length < numLines) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    };

    return {
        data,
        search,
        loadMore,
        hasMore,
        hasLoaded,
    };
}

export default useTablePagination;
