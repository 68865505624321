import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { twMerge } from "tailwind-merge";

export default function SoftphoneTransferOption({
  icon,
  color,
  onClick,
  className,
}) {
  const baseClasses = `bg-white rounded-full  text-xs w-[27px] h-[27px] flex justify-center items-center cursor-pointer hover:border-2 hover:border-gray-bluish transition-all drop-shadow-keys`;
  let colorClasses = "";

  switch (color) {
    case "green":
      colorClasses = `text-green hover:bg-green hover:text-white`;
      break;
    case "red":
      colorClasses = `text-red hover:bg-red hover:text-white`;
  }

  const classes = twMerge(baseClasses, colorClasses, className);

  return (
    <div className={classes} onClick={onClick}>
      <FontAwesomeIcon icon={icon} size="sm" />
    </div>
  );
}
