import { Redirect } from "react-router-dom";
import { Route } from "./Route";
import { useAuth } from "../../services/firebase/auth";

function AdminRoute(props) {
    const auth = useAuth();

    return (
        <Route
            render={() => {
                if (!auth.user) {
                    <Redirect to="/login" />;
                }

                return auth.user?.token.claims.admin ? (
                    props.children
                ) : (
                    <Redirect to="/" />
                );
            }}
            softphone={props.softphone}
        />
    );
}

export default AdminRoute;
