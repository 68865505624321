import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageTitle from "../components/layout/PageTitle";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import ContentWrapper from "../components/layout/ContentWrapper";
import { useEffect, useState, useRef } from "react";
import Card from "../components/layout/Card";
import TextInput from "../components/layout/TextInput";
import Button from "../components/layout/Button";
import CallHistoryTable from "../components/callHistory/CallHistoryTable";
import { getCallHistory } from "../services/callHistory";
import CreateUserContactModal from "../components/userContacts/CreateUserContactModal";
import { useQueryPagination } from "../hooks/queryPagination";
import TablePaginator from "../components/TableLayout/TablePaginator";
import DateRangeInput from "../components/layout/DateRangeInput";
import PhoneOrExtInput from "../components/layout/PhoneOrExtInput";
import { useSoftphone } from "../services/softphone/softphoneContext";
import { useAuth } from "../services/firebase/auth";
import { createUserContact } from "../services/userContacts";
import { useContacts } from "../services/UserContactsContext";
import DownloadToast from "../components/toast/DownloadToast";
import { toast } from "react-toastify";
import { getExtension } from "../services/extensions";

function HistoryPage() {
    const [isLoading, setIsLoading] = useState(true);

    const [nameSearch, setNameSearch] = useState("");
    const [phoneSearch, setPhoneSearch] = useState("");
    const [dateRangeSearch, setDateRangeSearch] = useState("");
    const [extenData, setExtenData ] = useState("");

    const [showCreateContact, setShowCreateContact] = useState(false);
    const [contactData, setContactData] = useState({});

    const toastId = useRef(null);

    const queryPagination = useQueryPagination(getCallHistory, 15);
    const softphone = useSoftphone();
    const auth = useAuth();
    const contacts = useContacts();

    const search = async () => {
        const filters = {
            name: nameSearch,
            phone: phoneSearch,
            date: dateRangeSearch,
        };

        await queryPagination.search(filters);

    };

    const changePage = async (page) => {
        setIsLoading(true);
        await queryPagination.goToPage(page);
        setIsLoading(false);
    };

    const reload = async () => {
        const getExtenData = await getExtension(auth.user.uid);
        setExtenData(getExtenData);
        setIsLoading(true);
        await search();
        setShowCreateContact(false);
        setIsLoading(false);
    };

    const searchHandler = async () => {
        setIsLoading(true);
        await search();
        setIsLoading(false);
    };

    const addContactButtonHandler = (item) => {
        setContactData(item);
        setShowCreateContact(true);
    };

    const downloadHandler = (callId, date) => {
        const dismiss = () => {
            toast.dismiss(toastId.current);
        };
        toastId.current = toast(
            <DownloadToast callId={callId} date={date} dismiss={dismiss} />,
            {
                autoClose: 0,
            }
        );
    };

    const initiateCall = (number, name) => {
        softphone.initiateCall(number, name);
    };

    const saveContactHandler = async (contact) => {
        setIsLoading(true);
        await createUserContact(auth, contact);
        contacts.reload();
        reload();
    };

    useEffect(() => {
        reload();
    }, []);

    return (
        <SidebarLayout>
            <PageTitle>Histórico de Chamadas</PageTitle>
            <PageLoadSpinner show={isLoading} />
            <ContentWrapper show={!isLoading}>
                <Card className="w-full">
                    <div className="flex items-end">
                        <TextInput
                            title="Nome"
                            value={nameSearch}
                            setter={setNameSearch}
                            className="mr-2 flex-1"
                        />
                        <PhoneOrExtInput
                            title="Telefone"
                            value={phoneSearch}
                            setter={setPhoneSearch}
                            className="mr-2 flex-1"
                        />
                        <DateRangeInput
                            title="Data"
                            value={dateRangeSearch}
                            setter={setDateRangeSearch}
                            className="mr-2"
                        />
                        <Button onClick={searchHandler}>Pesquisar</Button>
                    </div>
                </Card>
                <CallHistoryTable
                    data={queryPagination.data}
                    addContact={addContactButtonHandler}
                    initiateCall={initiateCall}
                    downloadRecording={downloadHandler}
                    extenData={extenData}
                    contacts={contacts}
                />
                <TablePaginator
                    page={queryPagination.page}
                    numPages={queryPagination.numPages}
                    goToPage={changePage}
                />
                <CreateUserContactModal
                    shown={showCreateContact}
                    initValues={contactData}
                    onClose={() => setShowCreateContact(false)}
                    onSave={saveContactHandler}
                />
            </ContentWrapper>
        </SidebarLayout>
    );
}

export default HistoryPage;
