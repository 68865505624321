import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageTitle from "../components/layout/PageTitle";
import ExtensionCardGrid from "../components/extensions/ExtensionCardGrid";
import ExtensionCard from "../components/extensions/ExtensionCard";
import CreateExtensionCard from "../components/extensions/CreateExtensionCard";
import { useAuth } from "../services/firebase/auth";
import { useEffect, useState, useCallback } from "react";
import {
  getExtension,
  getExtensions,
  deleteExtension,
} from "../services/extensions";
import { getQueues } from "../model/queue.model";
import UpdateExtensionModal from "../components/extensions/UpdateExtensionModal";
import CreateExtensionModal from "../components/extensions/CreateExtensionModal";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import ContentWrapper from "../components/layout/ContentWrapper";
import ConfirmDeleteModal from "../components/extensions/ConfirmDeleteModal";
import ResetPasswordModal from "../components/extensions/ResetPasswordModal";
import QRCodeModal from "../components/extensions/QRCodeModal";
import LastOfQueueModal from "../components/extensions/LastOfQueueModal";
import { getCompanyData } from "../services/company";

function ExtensionsPage() {
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(true);

  const [extensions, setExtensions] = useState([]);
  const [queues, setQueues] = useState([]);
  const [selectedExtension, setSelectedExtension] = useState("");
  const [company, setCompany] = useState(null);

  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showUpdateUserModal, setShowUpdateUserModal] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [lastOfQueues, setLastOfQueues] = useState(null);

  const [extensionToDelete, setExtensionToDelete] = useState();

  const load = useCallback(async () => {
    const extensionsPromise = getExtensions(auth);
    const queuesPromise = getQueues(auth);

    const results = await Promise.all([extensionsPromise, queuesPromise]);

    const data = results.map((v) =>
      Object.keys(v).map((key) => {
        return { id: key, ...v[key] };
      })
    );

    const company = await getCompanyData(auth);

    setCompany(company);
    setExtensions(data[0]);
    setQueues(data[1]);
    setIsLoading(false);
  }, [auth]);

  const editHandler = async (id) => {
    const user = await getExtension(id);
    setSelectedExtension({ ...user, id });
    setShowUpdateUserModal(true);
  };

  const updateModalTreeClosed = () => {
    setSelectedExtension(null);
    setShowUpdateUserModal(false);
    setShowResetPasswordModal(false);
    setLastOfQueues(null);
    setShowQRCode(false);
  };

  const onResetPasswordClicked = () => {
    setShowUpdateUserModal(false);
    setShowResetPasswordModal(true);
  };

  const onConfirmDelete = async () => {
    setIsLoading(true);

    const lastUserOfQueue = queues
      .filter((v) => v.participants.find((p) => p.id === extensionToDelete.id))
      .filter((v) => v.participants?.length === 1);

    if (lastUserOfQueue.length > 0) {
      setIsLoading(false);
      setLastOfQueues(lastUserOfQueue);
    } else {
      await deleteExtension(auth, extensionToDelete.id);
      reload();
    }
  };

  const onLastOfQueueConfirm = async () => {
    setIsLoading(true);
    await deleteExtension(auth, extensionToDelete.id);
    reload();
  };

  const reload = () => {
    setExtensions([]);
    setQueues([]);
    setLastOfQueues(null);
    setSelectedExtension(false);
    setShowCreateUserModal(false);
    setShowUpdateUserModal(false);
    setShowResetPasswordModal(false);
    setExtensionToDelete(false);
    load().then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    load();
  }, [auth, load]);

  return (
    <SidebarLayout>
      <PageTitle tooltipMessageId="extensions">Ramais</PageTitle>
      <PageLoadSpinner show={isLoading} />
      <ContentWrapper show={!isLoading}>
        <ExtensionCardGrid>
          {extensions.map((extension) => (
            <ExtensionCard
              extension={extension}
              key={extension.extensionNumber}
              onClick={editHandler}
              onDelete={setExtensionToDelete}
            ></ExtensionCard>
          ))}
          <CreateExtensionCard
            onClick={() => setShowCreateUserModal(true)}
            limitExtensions={company?.usersLimit}
            numExtensions={extensions.length}
          />
        </ExtensionCardGrid>
        <CreateExtensionModal
          shown={showCreateUserModal}
          onClose={() => setShowCreateUserModal(false)}
          onSave={reload}
          setIsLoading={setIsLoading}
          recordingLimit={company?.recordingLimit || 0}
          recordingNumber={
            Number(company?.recordingLimit || 0) -
            extensions.filter((v) => v.recording === true).length
          }
          extensions={extensions}
        />
        <UpdateExtensionModal
          shown={showUpdateUserModal}
          extension={selectedExtension}
          onClose={updateModalTreeClosed}
          onSave={reload}
          onResetPassword={onResetPasswordClicked}
          setIsLoading={setIsLoading}
          recordingLimit={company?.recordingLimit || 0}
          recordingNumber={
            Number(company?.recordingLimit || 0) -
            extensions.filter((v) => v.recording === true).length
          }
          onQRCode={() => setShowQRCode(true)}
          extensions={extensions}
        />
        <ResetPasswordModal
          shown={showResetPasswordModal}
          extension={selectedExtension}
          onClose={updateModalTreeClosed}
          onSave={reload}
          setLoading={setIsLoading}
        />
        <ConfirmDeleteModal
          shown={extensionToDelete}
          onClose={() => setExtensionToDelete(false)}
          onConfirm={onConfirmDelete}
        />
        <LastOfQueueModal
          shown={lastOfQueues !== null}
          queues={lastOfQueues}
          onClose={() => setLastOfQueues(null)}
          onConfirm={onLastOfQueueConfirm}
        />
        {showQRCode && (
          <QRCodeModal
            shown
            selectedExtension={selectedExtension}
            onClose={() => setShowQRCode(false)}
          />
        )}
      </ContentWrapper>
    </SidebarLayout>
  );
}

export default ExtensionsPage;
