import { useCallback, useEffect, useState } from "react";
import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageTitle from "../components/layout/PageTitle";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import ContentWrapper from "../components/layout/ContentWrapper";
import {
    createUserContact,
    getAllUsersContacts,
    updateUserContact,
} from "../services/userContacts";
import Card from "../components/layout/Card";
import TextInput from "../components/layout/TextInput";
import Button from "../components/layout/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import CreateUserContactModal from "../components/userContacts/CreateUserContactModal";
import UserContactsTable from "../components/userContacts/UserContactsTable";
import DeleteUserContactModal from "../components/userContacts/DeleteUserContactModal";
import { useAuth } from "../services/firebase/auth";
import { useSoftphone } from "../services/softphone/softphoneContext";
import PhoneOrExtInput from "../components/layout/PhoneOrExtInput";
import useLocalPagination from "../hooks/localPaginantion";
import TablePaginator from "../components/TableLayout/TablePaginator";
import { useContacts } from "../services/UserContactsContext";

function UserContactsPage() {
    const [isLoading, setIsLoading] = useState(true);
    const [searchName, setSearchName] = useState("");
    const [searchPhone, setSearchPhone] = useState("");
    const [searchEmail, setSearchEmail] = useState("");
    const auth = useAuth();

    const [showCreateContact, setShowCreateContact] = useState(false);
    const [showDeleteContact, setShowDeleteContact] = useState(false);
    const [showEditContact, setShowEditContact] = useState(false);
    const [contactToDelete, setContactToDelete] = useState("");
    const [contactToEdit, setContactToEdit] = useState("");

    const table = useLocalPagination(getAllUsersContacts, 15);
    const softphone = useSoftphone();
    const contacts = useContacts();

    const init = useCallback(async () => {
        await table.search();
        setIsLoading(false);
    }, [table]);

    const searchHandler = async () => {
        setIsLoading(true);
        await table.search({
            name: searchName,
            phone: searchPhone,
            email: searchEmail,
        });
        setIsLoading(false);
    };

    const loadMoreHandler = async () => {
        table.loadMore();
    };

    useEffect(() => {
        if (!table.hasLoaded) {
            init();
        }
    }, [init, table]);

    const addContactHandler = () => {
        setShowCreateContact(true);
    };

    const reload = async () => {
        setIsLoading(true);
        setShowCreateContact(false);
        setShowDeleteContact(false);
        setContactToDelete("");
        await table.reload();
        setIsLoading(false);
    };

    const onDeleteContact = (id) => {
        setContactToDelete(id);
        setShowDeleteContact(true);
    };

    const onEditContact = (vals) => {
        setContactToEdit(vals);
        setShowEditContact(true);
    };

    const onCreate = async (v) => {
        setShowCreateContact(false);
        setIsLoading(true);
        await createUserContact(auth, v);
        contacts.reload();
        reload();
    };

    const onUpdate = async (v) => {
        setShowEditContact(false);
        setIsLoading(true);
        await updateUserContact(auth, v);
        contacts.reload();
        reload();
    };

    const onInitiateCall = (number, name) => {
        softphone.initiateCall(number, name);
    };

    return (
        <SidebarLayout>
            <PageTitle>Agenda do Ramal</PageTitle>
            <PageLoadSpinner show={isLoading} />
            <ContentWrapper show={!isLoading}>
                <Card className="w-full">
                    <div className="flex">
                        <div
                            className="mr-4 text-primary flex justify-center items-center cursor-pointer"
                            onClick={addContactHandler}
                        >
                            <FontAwesomeIcon
                                icon={faUserPlus}
                                className="text-lg 2xl:text-2xl"
                            />
                        </div>
                        <TextInput
                            placeholder="nome"
                            value={searchName}
                            setter={setSearchName}
                            className="mr-2 flex-1"
                        />
                        <PhoneOrExtInput
                            placeholder="Telefone"
                            value={searchPhone}
                            setter={setSearchPhone}
                            className="mr-2 flex-1"
                        />
                        <TextInput
                            placeholder="E-mail"
                            value={searchEmail}
                            setter={setSearchEmail}
                            className="mr-2 flex-1"
                        />
                        <Button onClick={searchHandler}>Pesquisar</Button>
                    </div>
                </Card>
                <UserContactsTable
                    data={table.data}
                    onDeleteContact={onDeleteContact}
                    onCall={onInitiateCall}
                    onEditEvent={onEditContact}
                />
                <TablePaginator
                    page={table.page}
                    numPages={table.numPages}
                    goToPage={table.goToPage}
                />
            </ContentWrapper>
            <CreateUserContactModal
                shown={showCreateContact}
                onClose={() => setShowCreateContact(false)}
                onSave={(contact) => {
                    delete contact.id;
                    onCreate(contact);
                }}
            />
            <CreateUserContactModal
                shown={showEditContact}
                onClose={() => {
                    setShowEditContact(false);
                    setContactToEdit("");
                }}
                onSave={onUpdate}
                initValues={contactToEdit}
            />
            <DeleteUserContactModal
                shown={showDeleteContact}
                userContactId={contactToDelete}
                setLoading={setIsLoading}
                onClose={() => setShowDeleteContact(false)}
                onConfirm={reload}
            />
        </SidebarLayout>
    );
}

export default UserContactsPage;
