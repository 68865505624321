import { forwardRef } from "react";
import { useSoftphone } from "../../services/softphone/softphoneContext";
import KeypadIcon from "./KeypadIcon";
import SoftphoneDockContainer from "./SoftphoneDockContainer";

const SoftphoneInactiveDock = forwardRef(({ shown }, ref) => {
    const softphone = useSoftphone();

    return (
        <SoftphoneDockContainer shown={shown} ref={ref}>
            <div className="cursor-pointer" onClick={softphone.toggleKeyboard}>
                <KeypadIcon className="w-[20px] 2xl:w-[35px]" />
            </div>
        </SoftphoneDockContainer>
    );
});

export default SoftphoneInactiveDock;
