import React from "react";
import { twMerge } from "tailwind-merge";

const Card = (props: CardProps) => {
  const defaultClasses =
    "bg-white p-5 inline-block rounded-15 drop-shadow 2xl:p-8";
  const classes = twMerge(defaultClasses, props.className);
  return (
    <div {...props} className={classes}>
      {props.children}
    </div>
  );
};

export default Card;
export type CardProps = {
  children: React.ReactNode;
  className?: string;
};
