import { uniqueId } from "lodash";
import { useState, useEffect, useRef, useCallback } from "react";
import InputGroup from "./InputGroup";

const TextInput = ({
  name = "",
  placeholder = "",
  title = "",
  value,
  setter,
  className = "",
  required = false,
  validation = null,
  disabled = false,
  validateOnChange = false,
  mask = null,
  error = false,
  id = "",
  type = "text",
  autocomplete = false,
  onChange = null,
  formValidation,
}) => {
  const [inputId] = useState(id || uniqueId("input-"));
  const [errorState, setErrorState] = useState(false);
  const [maskedVal, setMaskedVal] = useState("");
  const [rawVal, setRawVal] = useState("");

  const ref = useRef();

  const validate = useCallback(() => {
    const val = ref.current.value;
    if (required && !val) {
      setErrorState("Obrigatório");
      return false;
    }

    if (validation) {
      const validResponse = validation(val);
      if (validResponse.error) {
        setErrorState(validResponse.message || true);
        return false;
      } else {
        setErrorState(false);
        return true;
      }
    }
    return true;
  }, [required, validation]);

  const onBlur = () => {
    validate() && setter(rawVal);
  };

  const onChangeHandler = (e) => {
    if (validateOnChange) {
      validate();
    } else if (errorState) {
      setErrorState(false);
    }

    if (mask) {
      const { filtering, masking } = mask();
      if (filtering && masking) {
        const val = filtering(e.target.value, rawVal);
        setRawVal(val);
        setMaskedVal(masking(val));
      } else {
        console.error("Mask is missing 'filtering' or 'masking' function.");
      }
    } else {
      setRawVal(e.target.value);
      setMaskedVal(e.target.value);
    }
    onChange && onChange(e.target.value);
    if (autocomplete) {
      setter(e.target.value);
    }
  };

  useEffect(() => {
    if (mask) {
      const { filtering, masking } = mask();
      if (filtering && masking) {
        setRawVal(value);
        const v = masking(filtering(value, ""));
        setMaskedVal(v);
      } else {
        console.error("Mask is missing 'filtering' or 'masking' function.");
      }
    } else {
      setRawVal(value);
      setMaskedVal(value);
    }
  }, [value, mask]);

  useEffect(() => {
    if (formValidation) {
      formValidation.registerValidationCallback({ id: inputId, validate });
      return () => formValidation.unregisterValidationCallback(inputId);
    }
  }, [inputId, validate, formValidation]);

  useEffect(() => {
    setErrorState(error);
  }, [error]);

  const borderColor = errorState ? "border-red/60" : "border-gray/50";
  const focusBorderColor = errorState
    ? "focus:border-red/80"
    : "focus:border-gray";

  return (
    <InputGroup
      title={title}
      className={className}
      inputId={inputId}
      error={errorState}
    >
      <input
        className={`block bg-white w-full px-3 2xl:px-4 py-1.5 2xl:py-2 border-2 text-xs 2xl:text-sm text-primary rounded-10 outline-none disabled:bg-gray/50 ${borderColor} ${focusBorderColor}`}
        id={inputId}
        type={type || "text"}
        name={name}
        value={maskedVal}
        onChange={onChangeHandler}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        ref={ref}
      />
    </InputGroup>
  );
};

export default TextInput;
