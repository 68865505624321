import { useEffect, useState } from "react";
import Modal from "../layout/Modal";
import Button from "../layout/Button";
import DateInput from "../layout/DateInput";
import TextInput from "../layout/TextInput";
import HourRangeInput from "../layout/HourRangeInput";
import { useAuth } from "../../services/firebase/auth";
import useFormValidation from "../../hooks/formValidation";
import Switch from "../layout/Switch";
import { editEvent } from "../../services/events";
import _ from "lodash";

function EditEventModal({ event, onClose, onEdited, setIsLoading, ...props }) {
    const [name, setName] = useState("");
    const [date, setDate] = useState("");
    const [hourRange, setHourRange] = useState({ from: "", to: "" });
    const [status, setStatus] = useState(false);
    const [allDay, setAllDay] = useState(false);

    const auth = useAuth();

    const formValidation = useFormValidation();

    const createEventHandler = async () => {
        if (formValidation.validateForm()) {
            setIsLoading(true);
            await editEvent(auth, event.id, {
                name,
                date,
                hourRange: allDay ? "*" : hourRange,
                status,
            });

            onEdited();
        }
    };

    useEffect(() => {
        if (!_.isEmpty(event)) {
            setName(event.name);
            setDate(event.date);

            setHourRange(
                event.hourRange !== "*"
                    ? { from: event.hourRange[0], to: event.hourRange[1] }
                    : { from: "", to: "" }
            );

            setStatus(event.status);
            setAllDay(event.hourRange !== "*" ? false : true);
        }
    }, [event]);

    return (
        <Modal
            title="Editar Evento"
            {...props}
            onClose={onClose}
            className="min-w-[500px]"
        >
            <div className="grid gap-4">
                <TextInput
                    title="Descrição"
                    value={name}
                    setter={setName}
                    formValidation={formValidation}
                    required
                />
                <DateInput
                    title="Data"
                    value={date}
                    setter={setDate}
                    formValidation={formValidation}
                    required
                />
                <div className="flex">
                    <Switch
                        title="Dia todo"
                        value={allDay}
                        onChange={setAllDay}
                        className="mr-8"
                    />
                    <HourRangeInput
                        title="Horário"
                        value={hourRange}
                        setter={setHourRange}
                        formValidation={formValidation}
                        disabled={allDay}
                        className="w-full"
                        required
                    />
                </div>
                <Switch
                    title="Status"
                    titleActive="Ativo"
                    titleInactive="Inativo"
                    value={status}
                    onChange={setStatus}
                />
            </div>
            <Button className="float-right mt-4" onClick={createEventHandler}>
                Salvar
            </Button>
        </Modal>
    );
}

export default EditEventModal;
