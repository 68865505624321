import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import IvrCard from "./IvrCard";

function AddQueueCard({ onCreate }) {
    return (
        <IvrCard onClick={onCreate} className="cursor-pointer">
            <FontAwesomeIcon
                icon={faPlusCircle}
                className="text-gray-dark block mx-auto mb-4 text-5xl"
            />
            <div className="text-gray-dark font-bold text-center">
                Criar Fila
            </div>
        </IvrCard>
    );
}

export default AddQueueCard;
