import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "../../tooltip/Tooltip";

function QuestionMarkTooltip(props) {
    return (
        <Tooltip {...props}>
            <FontAwesomeIcon
                icon={faQuestionCircle}
                className="text-gray-dark text-md ml-2"
            />
        </Tooltip>
    );
}

export default QuestionMarkTooltip;
