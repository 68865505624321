function DashboardCardGrid({ className, children }) {
    return (
        <div
            className={`grid gap-4 grid-cols-3 2xl:grid-cols-dashboard ${
                className || ""
            }`}
        >
            {children}
        </div>
    );
}

export default DashboardCardGrid;
