import { firestore } from "./firebase/firebaseInit";
import {
    collection,
    getDocs,
    query,
    where,
    limit,
    startAfter,
    orderBy,
} from "@firebase/firestore";
import { functions } from "./firebase/firebaseInit";
import { httpsCallable } from "@firebase/functions";

export const getCompanyNumbers = async (auth, lastDoc, quantity, filters) => {
    const companyId = auth.user.token.claims.company;

    let q = query(
        collection(firestore, `companies/${companyId}/numbers`),
        orderBy("fullPhone"),
        startAfter(lastDoc),
        limit(quantity)
    );

    if (filters && filters.fullPhone) {
        q = query(
            q,
            where("fullPhone", ">=", filters.fullPhone),
            where("fullPhone", "<=", filters.fullPhone + "\uf8ff")
        );
    }

    const snap = await getDocs(q);

    const data = snap.docs.reduce((prev, curr) => {
        return [...prev, { id: curr.id, ...curr.data(), doc: curr }];
    }, []);

    return data;
};

export const getAvailableServiceArea = async () => {
    const getAvailableServiceArea = httpsCallable(
        functions,
        "getAvailableServiceArea"
    );
    const result = await getAvailableServiceArea();
    if (result) return result.data || {};
};

export const getNumbersByArea = async (areaCode) => {
    const getAvailableNumbersByArea = httpsCallable(
        functions,
        "getAvailableNumbersByArea"
    );
    const result = await getAvailableNumbersByArea({ areaCode });
    if (result) return result.data || {};
};
