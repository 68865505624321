import { useEffect, useState, useCallback } from "react";

import useFormValidation from "../hooks/formValidation";

import { getBalance } from "../services/balance";
import { getCompanyData, setCompanyData } from "../services/company";
import { useAuth } from "../services/firebase/auth";
import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageTitle from "../components/layout/PageTitle";
import Card from "../components/layout/Card";
import TextInput from "../components/layout/TextInput";
import Button from "../components/layout/Button";
import PageActions from "../components/layout/PageActions";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import ContentWrapper from "../components/layout/ContentWrapper";
import EmailInput from "../components/layout/EmailInput";
import CpfCnpjInput from "../components/layout/CpfCnpjInput";
import PhoneInput from "../components/layout/PhoneInput";
import NumberInput from "../components/layout/IntegerInput";
import CepInput from "../components/layout/CepInput";
import StateSelect from "../components/layout/StateSelect";
import CardTitle from "../components/layout/CardTitle";
import termsVoki from "../assets/termsVoki.pdf"

function CompanyPage() {
  const [name, setName] = useState("");
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [sbc0800Id, setSbc0800Id] = useState("");
  const [sbcIdentifierId, setSbcIdentifierId] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [address, setAddress] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [addressReference, setAddressReference] = useState("");
  const [fantasyName, setFantasyName] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [balance0800, setBalance0800] = useState("");
  const [balanceIdentifier, setBalanceIdentifier] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const formValidation = useFormValidation();

  const auth = useAuth();

  const load = useCallback(async () => {
    const data = await getCompanyData(auth);

    setName(data.name);
    setCpfCnpj(data.cpfCnpj || "");
    setEmail(data.email || "");
    setPhone(data.phone || "");
    setZipcode(data.zipcode || "");
    setState(data.state || "");
    setCity(data.city || "");
    setSbc0800Id(data.sbc0800Id ||"");
    setSbcIdentifierId(data.sbcIdentifierId || "");
    setNeighborhood(data.neighborhood || "");
    setAddress(data.address || "");
    setAddressNumber(data.addressNumber || "");
    setAddressReference(data.addressReference || "");
    setIsLoading(false);
    setFantasyName(data.fantasyName);
    setCompanyID(data.id);

    if (data.sbc0800Id  || data.sbcIdentifierId) {
      const result = await getBalance(data.sbc0800Id, data.sbcIdentifierId);
      
      if (result.balance0800) setBalance0800(result.balance0800.balance);
      if (result.balanceIdentifier) setBalanceIdentifier(result.balanceIdentifier.balance);
    }

  }, [auth]);

  const reload = () => {
    setIsLoading(true);
    load();
  };

  const saveHandler = () => {
    if (formValidation.validateForm()) {
      setCompanyData(auth, {
        name,
        cpfCnpj,
        email,
        phone,
        zipcode,
        state,
        city,
        sbc0800Id,
        sbcIdentifierId,
        neighborhood,
        address,
        addressNumber,
        addressReference,
        fantasyName,
      });
      reload();
    }
  };

  useEffect(() => {
    load();
  }, [auth, load]);

  return (
    <SidebarLayout>
      <PageTitle>
        <div>Minha Empresa</div>
        {balance0800 || balanceIdentifier ? 
          <div className="text-end text-xs align-self-baseline">Saldo: { balance0800 ? `| 0800 R$: ${balance0800} ` : ''} { balanceIdentifier ? `| Bina R$: ${balanceIdentifier} |` : '|'}</div> 
        : <></>}
      </PageTitle>
      <PageLoadSpinner show={isLoading} />
      <ContentWrapper show={!isLoading}>
        <Card className="w-full">
          <CardTitle className="flex justify-between items-center">
            <div>Dados Cadastrais</div>
            <div className="font-bold text-xs 2xl:text-sm text-gray-dark">
              Meu Voki ID: {companyID}
            </div>
          </CardTitle>
          <div className="mb-4 grid grid-cols-4 gap-4">
            <TextInput
              value={fantasyName}
              setter={setFantasyName}
              title="Nome fantasia"
              required
            />
            <TextInput
              value={name}
              setter={setName}
              title="Razão social"
              required
              disabled={true}
            />
            <CpfCnpjInput
              value={cpfCnpj}
              setter={setCpfCnpj}
              title="CPF / CNPJ"
              formValidation={formValidation}
              required
              disabled={true}
            />
            <EmailInput
              value={email}
              setter={setEmail}
              title="E-mail comercial"
              formValidation={formValidation}
              required
              disabled={true}
            />
            <PhoneInput
              value={phone}
              setter={setPhone}
              title="Telefone comercial"
              formValidation={formValidation}
              disabled={true}
            />
          </div>
          <CardTitle>Endereço</CardTitle>
          <div className="grid grid-cols-9 gap-4">
            <CepInput
              value={zipcode}
              setter={setZipcode}
              className="col-span-2"
              title="CEP"
              formValidation={formValidation}
              required
              disabled={true}
            />
            <StateSelect
              value={state}
              setter={setState}
              title="Estado"
              required
              disabled={true}
            ></StateSelect>
            <TextInput
              value={city}
              setter={setCity}
              className="col-span-3"
              title="Cidade"
              formValidation={formValidation}
              required
              disabled={true}
            />
            <TextInput
              value={neighborhood}
              setter={setNeighborhood}
              className="col-span-3"
              title="Bairro"
              formValidation={formValidation}
              required
              disabled={true}
            />
            <TextInput
              value={address}
              setter={setAddress}
              className="col-span-3"
              title="Endereço"
              formValidation={formValidation}
              required
              disabled={true}
            />
            <NumberInput
              value={addressNumber}
              setter={setAddressNumber}
              className="col-span-3"
              title="Número"
              formValidation={formValidation}
              required
              disabled={true}
            />
            <TextInput
              value={addressReference}
              setter={setAddressReference}
              className="col-span-3"
              formValidation={formValidation}
              title="Complemento"
              disabled={true}
            />
          </div>
          <Button color="gray" outline className="mt-6"><a href={termsVoki} target='_blank'>Termo de Adesão</a></Button>
        </Card>
        <PageActions className="flex-row-reverse">
          <Button onClick={saveHandler}>Salvar</Button>
        </PageActions>
      </ContentWrapper>
    </SidebarLayout>
  );
}

export default CompanyPage;
