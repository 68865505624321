import { storage } from "../services/firebase/firebaseInit";
import { ref, getDownloadURL } from "firebase/storage";

export const downloadRecording = async (auth, callId, date) => {
  try {
    const fileName = callId.replace(".", "");
    const companyId = auth.user.token.claims.company;
    const dateObject = new Date(date);
    const year = dateObject.getUTCFullYear();
    let month = dateObject.getUTCMonth() + 1;
    let day = dateObject.getUTCDate();

    if (month < 10) month = `0${month}`;
    if (day < 10) day = `0${day}`;

    const itemRef = ref(
      storage,
      `${companyId}/${year}/${month}/${day}/${callId}.mp3`
    );
    const url = await getDownloadURL(itemRef);

    // Create a link element
    const a = document.createElement("a");
    a.href = url;
    a.target = `_blank`;

    // Append the link to the document
    document.body.appendChild(a);

    // Trigger a click event to start the download
    a.click();

    // Remove the link from the document
    document.body.removeChild(a);

    return true;
  } catch (e) {
    return { error: "Erro ao buscar arquivo." };
  }
};
