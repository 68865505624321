import { useState } from "react";
import Modal from "../layout/Modal";
import TextInput from "../layout/TextInput";
import Button from "../layout/Button";
import useFormValidation from "../../hooks/formValidation";
import DateInput from "../layout/DateInput";
import { createEvent } from "../../services/events";
import { useAuth } from "../../services/firebase/auth";
import HourRangeInput from "../layout/HourRangeInput";
import Switch from "../layout/Switch";

function AddEventModal({ onClose, onEventCreated, setIsLoading, ...props }) {
    const [name, setName] = useState("");
    const [date, setDate] = useState("");
    const [hourRange, setHourRange] = useState({ from: "", to: "" });
    const [allDay, setAllDay] = useState(true);

    const auth = useAuth();

    const formValidation = useFormValidation();

    const createEventHandler = async () => {
        if (formValidation.validateForm()) {
            setIsLoading(true);
            await createEvent(auth, {
                name,
                date,
                hourRange: allDay ? "*" : hourRange,
            });

            clearInputs();
            onEventCreated();
        }
    };

    const clearInputs = () => {
        setName("");
        setDate("");
        setHourRange({ from: "", to: "" });
    };

    return (
        <Modal
            title="Criar Evento"
            className="min-w-[500px]"
            onClose={onClose}
            {...props}
        >
            <div className="grid gap-4">
                <TextInput
                    title="Descrição"
                    value={name}
                    setter={setName}
                    formValidation={formValidation}
                    required
                />
                <DateInput
                    title="Data"
                    value={date}
                    setter={setDate}
                    formValidation={formValidation}
                    required
                />
                <div className="flex">
                    <Switch
                        title="Dia todo"
                        value={allDay}
                        onChange={setAllDay}
                        className="mr-8"
                    />
                    <HourRangeInput
                        title="Horário"
                        value={hourRange}
                        setter={setHourRange}
                        formValidation={formValidation}
                        className="w-full"
                        disabled={allDay}
                        required
                    />
                </div>
            </div>
            <Button className="float-right mt-4" onClick={createEventHandler}>
                Criar
            </Button>
        </Modal>
    );
}

export default AddEventModal;
