import { useEffect, useState } from "react";
import Modal from "../layout/Modal";
import Button from "../layout/Button";
import EmailInput from "../layout/EmailInput";
import useFormValidation from "../../hooks/formValidation";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

// Create Reset Password By E-mail Modal

function ResetPasswordByEmailModal({ shown = false, onClose, onSave }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const auth = getAuth();
  const formValidation = useFormValidation();

  const sendResetEmail = async () => {
    try {
      if (formValidation.validateForm()) {
        formValidation.saveForm();

        await sendPasswordResetEmail(auth, email);

        setError("");
        setSuccess("E-mail enviado com sucesso!");
      }
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setError("E-mail não encontrado! Tente novamente.");
        return;
      }

      setError("Ocorreu um erro... Tente novamente!");
    }
  };

  const onCloseHandler = () => {
    if (onClose) onClose();
    clear();
  };

  const clear = () => {
    setEmail("");
    setError("");
    setSuccess("");
  };

  return (
    <Modal
      shown={shown}
      onClose={onCloseHandler}
      title="Redefinir Senha"
      className="min-w-[300px] 2xl:min-w-[400px]"
    >
      <div className="grid grid-cols-1 gap-4 pb-3">
        <EmailInput
          value={email}
          setter={setEmail}
          title="E-mail"
          formValidation={formValidation}
          required
        />
      </div>
      <div className="flex justify-center pb-2">
        <div>{error && <span className="text-red  text-sm">{error}</span>}</div>
        <div>
          {success && <span className="text-green  text-sm">{success}</span>}
        </div>
      </div>
      <div className="flex justify-center pt-3">
        <Button color="purple" onClick={sendResetEmail}>
          Enviar
        </Button>
      </div>
    </Modal>
  );
}

export default ResetPasswordByEmailModal;
