import { firestore } from "./firebase/firebaseInit";
import { doc, getDoc, updateDoc } from "@firebase/firestore";

export const getCompanyData = async (auth) => {
    const companyId = auth.user.token.claims.company;
    const docRef = doc(firestore, `companies/${companyId}`);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
};

export const setCompanyData = async (auth, companyData) => {
    const companyId = auth.user.token.claims.company;
    const docRef = doc(firestore, `companies/${companyId}`);
    await updateDoc(docRef, companyData);
    return true;
};
