import { useEffect, useState } from "react";
import CreateExtensionCard from "../../components/extensions/CreateExtensionCard";
import ExtensionCard from "../../components/extensions/ExtensionCard";
import CardGrid from "../../components/extensions/ExtensionCardGrid";
import Card from "../../components/layout/Card";
import CardTitle from "../../components/layout/CardTitle";
import { SetupWizardConfig } from "../../model/setupWizard.model";
import { Setter } from "../../utils/types";
import CreateExtensionModal from "../../components/extensions/CreateExtensionModal";
import SetupWizardExtensionModal from "../../components/setupWizard/setupWizardExtensionModal";
import QueueCard from "../../components/ivrs/QueueCard";
import AddQueueCard from "../../components/ivrs/AddQueueCard";
import SetupWizardQueueModal from "../../components/setupWizard/setupWizardQueueModal";
import { SetupWizardControls } from "../../components/setupWizard/SetupWizardControls";

export function SetupQueuesPage(props: SetupQueuesPageProps) {
  const [allowNext, setAllowNext] = useState(false);
  const [showQueueModal, setShowQueueModal] = useState(false);
  const [selectedQueueIndex, setSelectedQueueIndex] = useState<number | null>(
    null
  );

  const onEdit = (index: number) => {
    setShowQueueModal(true);
    setSelectedQueueIndex(index);
  };

  const onCreate = () => {
    setShowQueueModal(true);
    setSelectedQueueIndex(null);
  };

  const onRemove = (index: number) => {
    const queues = props.value.queues || [];
    queues.splice(index, 1);
    props.setter((v) => ({
      ...v,
      queues: queues,
    }));
    setShowQueueModal(false);
  };

  useEffect(() => {
    if(props.value.queues && props.value.queues.length > 0) {
      setAllowNext(true);
    } else {
      setAllowNext(false);
    }
  }, [props.value]);

  return (
    <>
      <SetupWizardControls
        next={props.next}
        back={props.back}
        nextDisabled={!allowNext}
      />
      <Card className="w-full 2xl:p-12 p-10">
        <CardTitle>Digite 1 para...</CardTitle>
        <p className="text-gray-dark">
          Cada fila que você cadastrar representa uma opção que o seu cliente
          pode digitar e ser direcionado para os respectivos atendentes.
        </p>
        <p className="text-gray-dark mt-2">
          Cadastre suas filas abaixo e atribua atendentes à elas. Caso faltar
          atendentes, você pode cadastrar novos no passo anterior.
        </p>
        <p className="text-gray-dark mt-2">
          Exemplo de filas: suporte técnico, vendas, departamento financeiro.
        </p>
      </Card>
      <div className="grid gap-4 grid-cols-wizardQueueCard mt-4">
        {props.value.queues &&
          props.value.queues.map((v, i) => (
            <QueueCard
              queue={v}
              onEdit={() => onEdit(i)}
              key={i}
              className="w-full h-full hover:ring-expand hover:drop-shadow-far transition-filter ring-white duration-200"
            />
          ))}
        <AddQueueCard onCreate={onCreate} />
      </div>
      <SetupWizardQueueModal
        value={props.value}
        setter={props.setter}
        shown={showQueueModal}
        queueIndex={selectedQueueIndex}
        onClose={() => setShowQueueModal(false)}
        onSave={() => setShowQueueModal(false)}
        onDelete={(i) => onRemove(i!)}
      />
    </>
  );
}

export type SetupQueuesPageProps = {
  value: SetupWizardConfig;
  setter: Setter<SetupWizardConfig>;
  next: () => void;
  back: () => void;
};
