import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../services/firebase/auth";
import { useState } from "react";

function SidebarGroup({ icon, title, adminOnly, children }) {
    const auth = useAuth();

    const [isOpen, setIsOpen] = useState(true);

    return (
        <>
            {(adminOnly && auth.user.token.claims.admin) || !adminOnly ? (
                <div
                    className="pl-2 mt-4 rounded-l-10 relative text-white-dark cursor-pointer 2xl:pl-4 2xl:mt-6"
                    onClick={() => setIsOpen((v) => !v)}
                >
                    <div className="pr-4 text-xs flex justify-between 2xl:text-sm">
                        <div>
                            <div className="inline-block pr-4">
                                <FontAwesomeIcon icon={icon} size="lg" />
                            </div>
                            {title}
                        </div>
                        {isOpen ? (
                            <FontAwesomeIcon icon={faCaretUp} size="lg" />
                        ) : (
                            <FontAwesomeIcon icon={faCaretDown} size="lg" />
                        )}
                    </div>
                    <div className="bg-green-bright w-[3px] h-[calc(100%-50px)] left-[14px] 2xl:left-[23px] top-[40px] absolute"></div>
                    <div className="pl-4 pt-4 2xl:pt-4 2xl:pl-6">
                        {isOpen && children}
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default SidebarGroup;
