import { useCallback } from "react";
import { twMerge } from "tailwind-merge";
import styles from "./Stepper.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export function Stepper(props: StepperProps) {
  const calculateStatus = useCallback(
    (index: number) => {
      if (index < props.current) return "past";
      else if (index === props.current) return "current";
      else return "future";
    },
    [props.current]
  );

  return (
    <div className={props.className}>
      <div className={`w-full flex ${styles.stepper}`}>
        {props.steps.map((step, i) => (
          <StepElement
            key={i}
            step={step}
            index={i}
            status={calculateStatus(i)}
          />
        ))}
      </div>
    </div>
  );
}

function StepElement(props: StepElementProps) {
  const bgColor = props.status !== "future" ? "bg-green" : "bg-gray-dark";
  const textColor = props.status !== "future" ? "text-green" : "text-gray-dark";

  return (
    <div
      className={`flex-1 flex justify-center relative ${styles.stepper__item} ${
        props.status === "past" ? styles.stepper__active : ""
      }`}
    >
      <div className="flex flex-col items-center">
        <div
          className={`rounded-full text-white flex justify-center items-center font-bold ${bgColor} ${styles.stepper_circle}`}
        >
          {/* {props.index + 1} */}
          {props.status === "past" && <FontAwesomeIcon icon={faCheck} />}
        </div>
        <span className={`${textColor} ${styles.stepper__title}`}>
          {props.step.name}
        </span>
      </div>
    </div>
  );
}

type StepElementProps = {
  step: Step;
  status: "current" | "future" | "past";
  index: number;
};

export type StepperProps = {
  steps: Step[];
  current: number;
  className?: string;
};

export type Step = {
  name: string;
};
