import { useCallback, useEffect, useState } from "react";
import Card from "../layout/Card";
import moment from "moment";

function DashboardCallCard({ call, queues }) {
    const [timer, setTimer] = useState("");
    const [elapsed, setElapsed] = useState();
    const [queue, setQueue] = useState("");

    useEffect(() => {
        setQueue(queues.find((v) => v.id === call.queue)?.name || "");
        tickClock();
    }, [call]);

    const tickClock = () => {
        const now = moment();
        const from = moment(new Date(call.date));
        const diff = now.subtract(from);
        setElapsed(diff.valueOf());
        setTimer(diff.format("mm:ss"));
        setTimeout(() => {
            tickClock();
        }, 1000);
    };

    let border = "border-gray/50";
    let text = "text-gray-dark";

    if (elapsed > 10000) {
        border = "border-red";
        text = "text-red";
    } else if (elapsed > 3000) {
        border = "border-yellow";
        text = "text-yellow";
    }

    return (
        <Card
            className={`flex transition-colors duration-500 border-4 ${border}`}
        >
            <span
                className={`flex flex-col justify-center content-center font-bold mr-8 transition-colors duration-500  2xl:text-3xl ${text}`}
            >
                {timer}
            </span>
            <div>
                <div className="text-primary font-bold text-lg 2xl:text-xl">
                    {call.number}
                </div>
                <div className="text-gray-dark text-sm 2xl:text-base">
                    {queue && queue}
                </div>
            </div>
        </Card>
    );
}

export default DashboardCallCard;
