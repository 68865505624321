import TextInput from "./TextInput";
import { validateInteger } from "../../utils/validation";
import { numericMask } from "../../utils/masks";

function IntegerInput({...props}) {
    return (
        <TextInput validation={props.validation || validateInteger} mask={numericMask} {...props} />
    )
}

export default IntegerInput;