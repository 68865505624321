import Modal from "../layout/Modal";
import {
    getAvailableServiceArea,
    getNumbersByArea,
} from "../../services/numbers";
import { useEffect, useState } from "react";
import Select from "../layout/Select";
import _ from "lodash";
import PageLoadSpinner from "../layout/PageLoadSpinner";
import ContentWrapper from "../layout/ContentWrapper";
import NumberRantingTable from "./NumberRentingTable";
import Button from "../layout/Button";

function NumberRentingModal({ shown, onClose }) {
    const [isLoading, setIsLoading] = useState(true);
    const [result, setResult] = useState("");

    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState("");

    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState("");

    const [numbers, setNumbers] = useState([]);
    const [selectedNumber, setSelectedNumber] = useState("");

    const init = async () => {
        setIsLoading(true);
        setSelectedState("");
        setSelectedCity("");
        setCities([]);
        setStates([]);
        setNumbers([]);
        setSelectedCity("");

        const result = await getAvailableServiceArea();
        setResult(result);
        setStates(_.orderBy(_.keys(result)), [(v) => v], ["desc"]);
        setIsLoading(false);
    };

    const selectState = async (state) => {
        setSelectedCity("");
        setCities(result[state]);
        setSelectedState(state);
    };

    const selectCity = async (city) => {
        setSelectedCity(city);
        const code = result[selectedState].filter((v) => v.name === city)[0]
            ?.id;
        setIsLoading(true);
        const numbers = await getNumbersByArea(code);
        setNumbers(numbers);
        setIsLoading(false);
    };

    const selectNumber = (id) => {
        setSelectedNumber(id);
    };

    const save = () => {
        if (!selectedNumber) return;

        const number = numbers.find((v) => v.id === selectedNumber);
        onClose && onClose();
    };

    useEffect(() => {
        if (shown) {
            init();
        }
    }, [shown]);

    return (
        <Modal
            shown={shown}
            onClose={onClose}
            title="Contratar Número"
            className="min-w-[500px]"
        >
            <PageLoadSpinner show={isLoading} className="h-[200px]" />
            <ContentWrapper show={!isLoading}>
                <div className="my-6  text-gray-dark">
                    Para contratar um novo número, por favor selecione uma
                    cidade.
                </div>
                <div className="flex">
                    <Select
                        value={selectedState}
                        setter={selectState}
                        title="Estado"
                        className="mr-2"
                    >
                        {states &&
                            states.map((v) => (
                                <option key={v} value={v}>
                                    {v}
                                </option>
                            ))}
                    </Select>

                    {cities && (
                        <Select
                            value={selectedCity}
                            setter={selectCity}
                            title="Cidade"
                            className="flex-1"
                        >
                            {cities.map((v) => (
                                <option key={v.name} value={v.name}>
                                    {v.name}
                                </option>
                            ))}
                        </Select>
                    )}
                </div>
                {!_.isEmpty(numbers) && (
                    <NumberRantingTable
                        numbersArray={numbers}
                        selectNumber={selectNumber}
                    />
                )}
                {!_.isEmpty(numbers) && (
                    <div className="mt-4">
                        <Button
                            className="float-right"
                            color={selectedNumber ? "primary" : "gray"}
                            onClick={save}
                        >
                            Selecionar
                        </Button>
                    </div>
                )}
            </ContentWrapper>
        </Modal>
    );
}

export default NumberRentingModal;
