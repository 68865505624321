import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageTitle from "../components/layout/PageTitle";
import Card from "../components/layout/Card";
import CardTitle from "../components/layout/CardTitle";
import TextInput from "../components/layout/TextInput";
import Button from "../components/layout/Button";
import PageActions from "../components/layout/PageActions";
import { useEffect, useState } from "react";
import {
  activateSupportLogin,
  deactivateSupportLogin,
  getUserData,
  updateCurrentUser,
} from "../services/user";
import ContentWrapper from "../components/layout/ContentWrapper";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import EmailInput from "../components/layout/EmailInput";
import useFormValidation from "../hooks/formValidation";
import ResetPasswordModal from "../components/extensions/ResetPasswordModal";

import { toast } from "react-toastify";
import { format } from "date-fns";

function ProfilePage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [extensionId, setExtensionId] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [supportLogin, setSupportLogin] = useState(false);
  const [supportLoginLimit, setSupportLoginLimit] = useState(null);

  const formValidation = useFormValidation();

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const result = await getUserData();
    setName(result.name || "");
    setEmail(result.email || "");
    setExtensionId(result.id);
    setSupportLogin(result.supportLogin);
    if (result.supportLoginLimit) {
      setSupportLoginLimit(result.supportLoginLimit.toDate());
    }
    setIsLoading(false);
  };

  const reload = () => {
    setIsLoading(true);
    setShowResetPasswordModal(false);
    load();
  };

  const saveHandler = async () => {
    if (formValidation.validateForm()) {
      const data = {
        name,
        email,
      };

      await updateCurrentUser(data);
      reload();
    }
  };

  const onActivateSupport = async () => {
    setIsLoading(true);
    const result = await activateSupportLogin();
    if (!result) {
      toast("Não foi possível habilitar o usuário de suporte", {
        type: "error",
      });
      return;
    }
    const limit = new Date(result.data);
    toast("Usuário de suporte ativo", { type: "success" });
    setSupportLogin(true);
    setSupportLoginLimit(limit);
    setIsLoading(false);
  };

  const onDeactivateSupport = async () => {
    const result = await deactivateSupportLogin();
    if (!result) {
      toast("Não foi possível desabilitar o usuário de suporte", {
        type: "error",
      });
      return;
    }
    toast("Usuário de suporte desativado", { type: "success" });
    setSupportLogin(false);
    setSupportLoginLimit(null);
  };

  return (
    <SidebarLayout>
      <PageTitle>Minha Conta</PageTitle>
      <PageLoadSpinner show={isLoading} />
      <ContentWrapper show={!isLoading}>
        <Card className="w-full">
          <CardTitle>Dados Cadastrais</CardTitle>
          <div className="mb-4 grid grid-cols-4 gap-4">
            <TextInput
              value={name}
              setter={setName}
              title="Nome"
              formValidation={formValidation}
              required
            />
            <EmailInput
              value={email}
              setter={setEmail}
              title="E-mail"
              formValidation={formValidation}
            />
          </div>
        </Card>
        <Card className="w-full mt-6">
          <CardTitle>Acesso de Suporte</CardTitle>
          <div className="flex">
            {supportLogin && (
              <Button
                color="red"
                className="w-[150px]"
                onClick={onDeactivateSupport}
              >
                Desativar
              </Button>
            )}
            {!supportLogin && (
              <Button
                color="green"
                className="w-[150px]"
                onClick={onActivateSupport}
              >
                Ativar
              </Button>
            )}
            <div className="flex-1 text-gray-dark ml-8">
              <p>
                O acesso de suporte é uma permissão temporária para nossos
                agentes de suporte acessarem sua conta para avaliar questões
                levantadas em atendimentos. O tempo de duração é de{" "}
                <strong>2 dias</strong>, após será revogada a permissão.
              </p>
              {supportLoginLimit && (
                <p className="text-red font-bold">
                  Ativo até: {format(supportLoginLimit, "dd/MM/yy kk:mm:ss")}
                </p>
              )}
            </div>
          </div>
        </Card>
        <PageActions className="justify-between">
          <Button
            onClick={() => setShowResetPasswordModal(true)}
            color="primary"
            outline
          >
            Redefinir senha
          </Button>
          <Button onClick={saveHandler}>Salvar</Button>
        </PageActions>
        <ResetPasswordModal
          shown={showResetPasswordModal}
          extension={{ id: extensionId }}
          onClose={() => setShowResetPasswordModal(false)}
          onSave={reload}
          setLoading={setIsLoading}
        />
      </ContentWrapper>
    </SidebarLayout>
  );
}

export default ProfilePage;
