import {
  useState,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from "react";
import { Event, EventHook, useEvent } from "../utils/eventEmitter";
import useFormValidation from "./formValidation";
import { useAuth } from "../services/firebase/auth";
import {
  getIvrData,
  saveMultiQueueIvr,
  saveQueues,
  saveSingleQueueIvr,
} from "../services/queue.service";
import {
  AudioMessage,
  IvrType,
  Queue,
  getMessagesMetadata,
  getQueues,
} from "../model/queue.model";
import { Setter } from "../utils/types";

export default function useIvrPageHook(): IvrHook {
  const [isLoading, setIsLoading] = useState(true);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [queues, setQueues] = useState<Queue[]>([]);

  const [ivrType, setIvrType] = useState<IvrType>("");

  const [selectedSingleQueueName, setSelectedSingleQueueName] = useState("");

  const [greeting, setGreeting] = useState<AudioMessage>();
  const [error, setError] = useState<AudioMessage>();

  const auth = useAuth();
  const formValidation = useFormValidation();
  const onSaveEvent = useEvent();

  const onSave = async () => {
    if (formValidation.validateForm()) {
      setIsLoading(true);
      const companyId = auth.user.token.claims.company;
      const saved = await saveQueues(auth, queues);
      switch (ivrType) {
        case "single-queue":
          const q = saved.find((v) => v.name === selectedSingleQueueName);
          if (!q) return;
          saveSingleQueueIvr(companyId, q.id);
          break;
        case "multi-queue":
          if (!greeting) {
            throw new Error("A mensagen de áudio é obrigatória");
          }

          saveMultiQueueIvr(companyId, greeting, error, queues);
          break;
      }
      setHasUnsavedChanges(false);
      setIsLoading(false);
    }
  };

  const load = async () => {
    const companyId = auth.user.token.claims.company;
    const data = await getIvrData(companyId);

    if (data) {
      const queues = await getQueues(auth);
      setQueues(queues);
      setIvrType(data.type);
      const selectedSingleQueue = queues.find(
        (v) => v.id === data.singleQueueId
      );
      if (selectedSingleQueue) {
        setSelectedSingleQueueName(selectedSingleQueue.name);
      }
      if (data.type === "multi-queue") {
        const messages = await getMessagesMetadata(companyId);
        setGreeting(messages.greeting);
        setError(messages.error);
      }
    }
  };

  return {
    formValidation,
    hasUnsavedChanges,
    setHasUnsavedChanges,
    isLoading,
    setIsLoading,
    load,
    ivrType,
    setIvrType,
    onSave,
    queues,
    setQueues,
    selectedSingleQueueName,
    setSelectedSingleQueueName,
    onSaveEvent,
    greeting,
    setGreeting,
    error,
    setError,
    // audioMessage,
  };
}

export type IvrHook = {
  formValidation: any;
  load: () => Promise<void>;
  onSave: () => Promise<void>;

  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: Setter<boolean>;

  ivrType: IvrType;
  setIvrType: Setter<IvrType>;

  isLoading: boolean;
  setIsLoading: Setter<boolean>;

  queues: Queue[];
  setQueues: Setter<Queue[]>;

  selectedSingleQueueName: string;
  setSelectedSingleQueueName: Setter<string>;

  onSaveEvent: EventHook;

  greeting: AudioMessage;
  setGreeting: Setter<AudioMessage>;

  error: AudioMessage;
  setError: Setter<AudioMessage>;
  // audioMessage;
};
