import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMicrophoneAlt,
    faPause,
    faPlay,
    faAngleDoubleLeft,
    faMicrophoneAltSlash,
} from "@fortawesome/free-solid-svg-icons";
import { forwardRef } from "react";
import SoftphoneDockContainer from "./SoftphoneDockContainer";
import { useSoftphone } from "../../services/softphone/softphoneContext";
import FadeInOut from "../layout/FadeInOut";

const SoftphoneOnCallDock = forwardRef(({ shown }, ref) => {
    const softphone = useSoftphone();

    return (
        <SoftphoneDockContainer shown={shown} ref={ref}>
            <div className="mb-16 grid gap-4 2xl:gap-6 justify-items-center">
                <div className="flex justify-center items-center h-7">
                    <FadeInOut
                        selector={() => (softphone.muted ? 1 : 0)}
                        options={[
                            <FontAwesomeIcon
                                icon={faMicrophoneAlt}
                                className="text-xl 2xl:text-2xl cursor-pointer"
                                onClick={() =>
                                    softphone.callState === "on-call" &&
                                    softphone.toggleMute()
                                }
                            />,
                            <FontAwesomeIcon
                                icon={faMicrophoneAltSlash}
                                className="text-lg 2xl:text-2xl cursor-pointer"
                                onClick={() =>
                                    softphone.callState === "on-call" &&
                                    softphone.toggleMute()
                                }
                            />,
                        ]}
                    />
                </div>
                <div className="flex justify-center items-center h-7">
                    <FadeInOut
                        selector={() => (softphone.onHold ? 1 : 0)}
                        options={[
                            <FontAwesomeIcon
                                icon={faPause}
                                className="text-lg 2xl:text-2xl cursor-pointer"
                                onClick={softphone.toggleHold}
                            />,
                            <FontAwesomeIcon
                                icon={faPlay}
                                className="text-sm 2xl:text-2xl cursor-pointer"
                                onClick={softphone.toggleHold}
                            />,
                        ]}
                    />
                </div>
            </div>
            <div className="cursor-pointer" onClick={softphone.toggleKeyboard}>
                <FontAwesomeIcon
                    icon={faAngleDoubleLeft}
                    className="text-lg 2xl:text-2xl"
                />
            </div>
        </SoftphoneDockContainer>
    );
});

export default SoftphoneOnCallDock;
