import SidebarLayout from "../components/sidebar/SidebarLayout";
import PageTitle from "../components/layout/PageTitle";
import Card from "../components/layout/Card";
import CardTitle from "../components/layout/CardTitle";
import HoursInputGroup from "../components/working-hours/HoursInputGroup";
import Button from "../components/layout/Button";
import PageActions from "../components/layout/PageActions";
import { useAuth } from "../services/firebase/auth";
import {
  getWorkingHours,
  saveAwayAudio,
  setWorkingHours,
} from "../services/workingHours";
import { useEffect, useState } from "react";
import PageLoadSpinner from "../components/layout/PageLoadSpinner";
import ContentWrapper from "../components/layout/ContentWrapper";
import useFormValidation from "../hooks/formValidation";
import ErrorModal from "../components/working-hours/ErrorModal";
import Divider from "../components/layout/Divider";
import EventsConfig from "../components/working-hours/EventsConfig";
import RedirectInputGroup from "../components/working-hours/RedirectInputGroup";
import { AudioSelector } from "../components/audioSelection/audioSelector";
import { useAudioMessageHook } from "../hooks/AudioMessageSelection";
import { getMessagesMetadata } from "../model/queue.model";

function WorkingHoursPage() {
  const auth = useAuth();

  const defaultTime = { from: "", to: "" };
  const [monday, setMonday] = useState(defaultTime);
  const [tuesday, setTuesday] = useState(defaultTime);
  const [wednesday, setWednesday] = useState(defaultTime);
  const [thursday, setThursday] = useState(defaultTime);
  const [friday, setFriday] = useState(defaultTime);
  const [saturday, setSaturday] = useState(defaultTime);
  const [sunday, setSunday] = useState(defaultTime);
  const [lunchBreak, setLunchBreak] = useState(defaultTime);

  const [redirectNumber, setRedirectNumber] = useState("");
  const [playMessage, setPlayMessage] = useState(false);

  const [audioMessage, setAudioMessage] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const formValidation = useFormValidation();

  useEffect(() => {
    const fetch = async () => {
      const workingHours = await getWorkingHours(auth);

      if (workingHours) {
        setMonday(workingHours.monday);
        setTuesday(workingHours.tuesday);
        setWednesday(workingHours.wednesday);
        setThursday(workingHours.thursday);
        setFriday(workingHours.friday);
        setSaturday(workingHours.saturday);
        setSunday(workingHours.sunday);

        if (workingHours.config) {
          setRedirectNumber(workingHours.config.redirectNumber);
          setPlayMessage(workingHours.config.playMessage);
        }

        if (workingHours.lunch) {
          setLunchBreak(workingHours.lunch);
        }
      }

      const companyId = auth.user.token.claims.company;
      const messages = await getMessagesMetadata(companyId);
      const message = messages["away"];

      onLoadedAudioMessage(message);

      if (message?.type) setPlayMessage(true);

      setIsLoading(false);
    };
    fetch();
  }, [auth]);

  const saveHandler = async () => {
    if (formValidation.validateForm()) {
      setIsLoading(true);
      await formValidation.saveForm();

      saveAwayAudio(auth.user.token.claims.company, audioMessage, false);

      await setWorkingHours(
        auth,
        {
          monday,
          tuesday,
          wednesday,
          thursday,
          friday,
          saturday,
          sunday,
        },
        {
          playMessage: audioMessage?.type != "none" ? true : false,
          redirectNumber: redirectNumber || false,
          lunchBreak,
        }
      );
      setIsLoading(false);
    }
  };

  const onLoadedAudioMessage = (ivrAudioMessage) => {
    if (!ivrAudioMessage) return;

    switch (ivrAudioMessage.type) {
      case "text":
        setAudioMessage({
          type: "text",
          id: "away",
          text: ivrAudioMessage.text,
        });
        break;
      case "predefined":
        setAudioMessage({
          type: "predefined",
          id: "away",
          text: ivrAudioMessage.text,
          predefinedId: ivrAudioMessage.predefined,
        });
        break;
      case "file":
        setAudioMessage({
          type: "file",
          id: "away",
          filename: ivrAudioMessage.filename,
          hasChanged: false,
        });
        break;
      default:
        setAudioMessage({
          type: "none",
        });
    }
  };

  const audioDescription = (
    <>
      <p className="text-gray-dark text-xs 2xl:text-base mb-4">
        A mensagem de áudio de fora de experiente será tocada quando seu cliente
        ligar para seu número fora dos horários de atendimento cadastrados.
      </p>
    </>
  );

  return (
    <SidebarLayout>
      <PageLoadSpinner show={isLoading} />
      <ContentWrapper show={!isLoading}>
        <PageTitle tooltipMessageId="working-hours">
          Horários de Atendimento
        </PageTitle>
        <Card className="w-full grid grid-cols-5 gap-10">
          <div className="col-span-3">
            <CardTitle>Horários de Atendimento</CardTitle>
            <HoursInputGroup
              value={monday}
              setValue={setMonday}
              formValidation={formValidation}
            >
              Segunda-feira
            </HoursInputGroup>
            <HoursInputGroup
              value={tuesday}
              setValue={setTuesday}
              formValidation={formValidation}
            >
              Terça-feira
            </HoursInputGroup>
            <HoursInputGroup
              value={wednesday}
              setValue={setWednesday}
              formValidation={formValidation}
            >
              Quarta-feira
            </HoursInputGroup>
            <HoursInputGroup
              value={thursday}
              setValue={setThursday}
              formValidation={formValidation}
            >
              Quinta-feira
            </HoursInputGroup>
            <HoursInputGroup
              value={friday}
              setValue={setFriday}
              formValidation={formValidation}
            >
              Sexta-feira
            </HoursInputGroup>
            <HoursInputGroup
              value={saturday}
              setValue={setSaturday}
              formValidation={formValidation}
            >
              Sábado
            </HoursInputGroup>
            <HoursInputGroup
              value={sunday}
              setValue={setSunday}
              formValidation={formValidation}
            >
              Domingo
            </HoursInputGroup>
            <Divider className="mb-4" />
            <HoursInputGroup
              value={lunchBreak}
              setValue={setLunchBreak}
              formValidation={formValidation}
            >
              Pausa de Almoço
            </HoursInputGroup>
          </div>
          <div className="col-span-2">
            <AudioSelector
              id="away"
              title="Mensagem de fora de expediente"
              value={audioMessage}
              setter={setAudioMessage}
              description={audioDescription}
              formValidation={formValidation}
              // setNoAudio={(v) => setPlayMessage(!v)}
              className="mt-4"
            />
            <CardTitle>Configurações</CardTitle>
            <p className="text-gray-dark text-xs 2xl:text-base mb-4">
              A ação que a central tomará quando receber a ligação enquanto fora
              de expediente. "Desligar" irá finalizar a ligação, enquanto
              redirecionar irá transferir para o número digitado.
            </p>
            <RedirectInputGroup
              destination={redirectNumber}
              setDestination={setRedirectNumber}
              formValidation={formValidation}
            />
          </div>
        </Card>
        <PageActions className="flex-row-reverse">
          <Button onClick={saveHandler}>Salvar</Button>
        </PageActions>
        <EventsConfig setIsLoading={setIsLoading} />
      </ContentWrapper>
      <ErrorModal error={error} onClose={() => setError("")} />
    </SidebarLayout>
  );
}

export default WorkingHoursPage;
