import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck, faCircle } from "@fortawesome/free-solid-svg-icons";
import TextInput from "./TextInput";

function CreatePasswordInput({ ...props }) {
  const [interacted, setInteracted] = useState(false);
  const [validLength, setValidLength] = useState(false);
  const [validNumbers, setValidNumbers] = useState(false);
  const [validSpecialChar, setValidSpecialChar] = useState(false);

  const validation = (pass) => {
    setInteracted(true);
    let error = false;

    if (!props.required && !pass) {
      return { error: false };
    }

    if (props.required && !pass) {
      return { error: true, message: "obrigatório" };
    }

    if (pass.length < 12) {
      setValidLength(false);
      error = true;
    } else {
      setValidLength(true);
    }

    if (!pass.match(/[0-9]/)) {
      setValidNumbers(false);
      error = true;
    } else {
      setValidNumbers(true);
    }

    if (!pass.match(/[!@#$%&*]/)) {
      setValidSpecialChar(false);
      error = true;
    } else {
      setValidSpecialChar(true);
    }

    return error ? { error: true, message: "" } : { error: false };
  };

  const onSubmit = () => {
    props.setter("");
    setInteracted(false);
  };

  useEffect(() => {
    if (props.formValidation) {
      props.formValidation.on("submit", onSubmit);
    }
  }, []);

  return (
    <div className="w-full">
      <TextInput
        type="password"
        className="w-full"
        validation={validation}
        validateOnChange
        {...props}
      />
      {interacted && (
        <div className="mt-2">
          <PasswordCondition
            text="Ao menos 12 caracteres"
            interacted={interacted}
            isCorrect={validLength}
          />
          <PasswordCondition
            text="Ao menos 1 número"
            interacted={interacted}
            isCorrect={validNumbers}
          />
          <PasswordCondition
            text="Ao menos 1 caracter especial (!@#$%&*)"
            interacted={interacted}
            isCorrect={validSpecialChar}
          />
        </div>
      )}
    </div>
  );
}

function PasswordCondition({ text, interacted = false, isCorrect = false }) {
  let textColor = isCorrect ? "text-green" : "text-red";

  let icon = isCorrect ? (
    <FontAwesomeIcon
      icon={faCheck}
      className={`text-lg 2xl:text-[14px] ${textColor}`}
    />
  ) : (
    <FontAwesomeIcon
      icon={faTimes}
      className={`text-lg 2xl:text-lg ${textColor}`}
    />
  );

  return (
    <div className="flex items-center">
      <div className="w-4 flex items-center">{icon}</div>
      <span className={`ml-2 text-xs 2xl:text-sm ${textColor}`}>{text}</span>
    </div>
  );
}

export default CreatePasswordInput;
